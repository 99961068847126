import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import React, { useState } from 'react';
import '../css/styles.css'
import '../css/error.css'
import fdmlogo from '../assets/images/fdmlogo.jpeg'
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import validator from 'validator';
import ScrollToTop from "react-scroll-to-top";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Nav from 'react-bootstrap/Nav'
export default function LoanLanding() {
	
	const [validated, setValidated] = useState(false);
	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('');
	const [email_address, setEmailAddress] = useState('');
	const [phone_number, setPhoneNumber] = useState('');
	const [terms, setTerms] = useState(false);
	const [email_registration_error, setEmailRegistrationError] = useState(false);	
	const [successMessage, setSuccessMessage] = useState(false);

	const handleFirstName = (e) => {
          setFirstName(e.target.value);
        };

	const handleLastName = (e) => {
          setLastName(e.target.value);
        };

	const handleEmailAddress = (e) => {
          setEmailAddress(e.target.value);
        };

	const handlePhoneNumber = (e) => {
          setPhoneNumber(e.target.value);
        };
	const handleTerms = (e) => {
          setTerms(!terms);
        };

	// Handling the form submission
	const handleSubmit = (event) => {
	const form = event.currentTarget;	
	      event.preventDefault();
		event.stopPropagation();
	if (form.checkValidity() === false) {
	setValidated(true)
	} else {
		console.log("submitted")
	    axios.post("/api/user/add_mortgage_lead/", { 
		    first_name : first_name,
		    last_name : last_name,
		    email_address : email_address,
		    phone_number : phone_number,
		    agree_terms : terms.toString(),
		})
      		.then((response) => {
          		if (response.status == 200) {
				if ("redirect" in response.data) {
					window.scrollTo(0, 0)

				window.location = response.data["redirect"]
				}
				else if (response.data.code == 3) {
					setEmailRegistrationError(true)
					window.scrollTo(0, 0)
				}
				setSuccessMessage(true)
			} else {
				console.log("Error")
          		
			}
			})
      		}
	    }

	

	// Showing success message
	const showSuccessMessage = () => {
		if (successMessage) {
		  return (
			<>
			<Alert variant="success" onClose={() => setSuccessMessage(false)} dismissible>
				<a> Thank you! Someone will be in contact shortly.</a>
			</Alert>
			<br/>
			</>

		  );
		}
	};

	// Showing error message if error is true
	const emailRegistrationErrorMessage = () => {
		if (email_registration_error) {
		return (
			<>
			<Alert variant="danger" onClose={() => setEmailRegistrationError(false)} dismissible >
			      <a>An Error Has Occured, Please Reload The Page.</a>
			</Alert>
			<br/>
			</>
	  );
		}
	};
	const head_fmt = {
                  "list-style-type": "none",
                  "margin": "0",
                  "padding": "0",
                }
	const tab_fmt = {
                  "list-style-type": "none",
                  "margin": "0",
                  "padding": "0",
		  "paddingLeft": '20px',
                }

	return (
		<>
		{showSuccessMessage()}
		{emailRegistrationErrorMessage()}
		<div className="card-wrapper">
		  <div className="card">
		<h1>Get Financing</h1>
		<br/>
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
		      <Row>
			<Form.Group  controlId="first_name">
			  <Form.Label>First Name</Form.Label>
			  <InputGroup hasValidation>
			  <Form.Control type="text"
					required
			    		onChange={handleFirstName}
			  />
			<Form.Control.Feedback type="invalid">
				Please enter your first name.
			</Form.Control.Feedback>
			</InputGroup>
			</Form.Group>
		      </Row>
			<br/>
		      <Row>
			<Form.Group controlId="last_name">
			  <Form.Label>Last Name</Form.Label>
			  <InputGroup hasValidation>
			  <Form.Control
			    onChange={handleLastName}
			    required
			    type="text"
			  />
			  <Form.Control.Feedback type="invalid">
				Please enter your last name.
			</Form.Control.Feedback>
			</InputGroup>
			</Form.Group>
		      </Row>
			<br/>
		      <Row>

			<Form.Group controlId="email_address">
			  <Form.Label>Email Address</Form.Label>
			  <InputGroup hasValidation>
			    <Form.Control
			      onChange={handleEmailAddress}
			      type="email"
			     
			      required
			    />
			    <Form.Control.Feedback type="invalid">
			      Please enter your email address.
			    </Form.Control.Feedback>
			  </InputGroup>
			</Form.Group>
		      </Row>
			<br/>
		      <Row>
			<Form.Group controlId="phone_number">
			  <Form.Label>Phone Number</Form.Label><br/>
			  <Form.Label>Format: 000-000-0000</Form.Label>
			  <Form.Control type="tel" 
			
				pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
			      onChange={handlePhoneNumber}
			 required />
			  <Form.Control.Feedback type="invalid">
			    Please use the format 000-000-0000
			  </Form.Control.Feedback>
			</Form.Group>
		      </Row>
			<br/>
		      <Row>
		      <Form.Group>
			<Form.Check
			  required
			  onChange={handleTerms}
			  label="I agree to be be contacted by a loan servicer."
			  feedback="You must agree before submitting."
			  feedbackType="invalid"
			/>
		      </Form.Group>
			</Row>
			NMLS #188829
			<br/>
			<br/>
		      <Button type="submit">Get In Contact</Button>
		    </Form>
		
	  </div>
	  </div>
		<ul>
		<li style={head_fmt}><h3>Some Loan Options Our Providers Offer</h3></li>
		</ul>
*Note that different mortgage loan types have their own specific eligibility requirements, interest rates, and terms.
		<br/>
		<br/>
		<br/>
		<Tabs
		      defaultActiveKey="profile"
		      id="fill-tab-example"
		      className="mb-3"
		      fill>
		      <Tab eventKey="conventional" title="Conventional">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>Conforming Loans</b></li>
			<li style={head_fmt}><b>Fixed Programs</b></li>
			<li style={head_fmt}><b>ARM Programs</b></li>
			<li style={head_fmt}><b>High Balance</b></li>
			<li style={head_fmt}><b>Home Ready</b></li>
			<li style={head_fmt}><b>Home Possible</b></li>
			<li style={head_fmt}><b>HomeOne</b></li>
			<li style={head_fmt}><b>Renovation Loans</b></li>
			<li style={head_fmt}><b>Construction Loans</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="government" title="Government">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>FHA Loans</b></li>
			<li style={tab_fmt}><b>Fixed Programs</b></li>
			<li style={tab_fmt}><b>ARM Programs</b></li>
			<li style={tab_fmt}><b>FHA Streamline</b></li>
			<li style={tab_fmt}><b>FHA $100 HUD</b></li>
			<li style={tab_fmt}><b>FHA 203(h) Disaster Victims Mortgage</b></li>
			<li style={tab_fmt}><b>FHA 203(K) Renovation</b></li>
			<li style={head_fmt}><b>VA Loans</b></li>
			<li style={tab_fmt}><b>Fixed Programs</b></li>
			<li style={tab_fmt}><b>ARM Programs</b></li>
			<li style={tab_fmt}><b>VA IRRRL</b></li>
			<li style={tab_fmt}><b>VA Renovation</b></li>
			<li style={head_fmt}><b>USDA Loans</b></li>
			<li style={tab_fmt}><b>Fixed Programs</b></li>
			<li style={tab_fmt}><b>USDA Streamline</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="dpa_grant" title="DPA and Grant">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>100% Purchase Program</b></li>
			<li style={head_fmt}><b>Within Reach FHA</b></li>
			<li style={head_fmt}><b>FHLB</b></li>
			<li style={head_fmt}><b>State and County Grants</b></li>
			<li style={head_fmt}><b>VHDA</b></li>
			<li style={head_fmt}><b>Conventional</b></li>
			<li style={head_fmt}><b>FHA</b></li>
			<li style={head_fmt}><b>VA</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="self-employed-borrower_programs" title="Self-Employed Borrower Programs">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>Profit & Loss and Asset Utilization</b></li>
			<li style={head_fmt}><b>24-Month Bank Statements</b></li>
			<li style={head_fmt}><b>3-Month Bank Statement</b></li>
			<li style={head_fmt}><b>12-Month Bank Statement</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="debt_service_coverage_ratio_programs" title="Debt Service Coverage Ratio Programs">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>Debt Service Coverage Ratio</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="commercial" title="Commercial">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>Debt Service Coverage Ratio Commercial</b></li>
			<li style={head_fmt}><b>Fix and Flip</b></li>
			<li style={head_fmt}><b>Flex I/O - Broker</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="itin" title="ITIN">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>Foreign National Loan Program</b></li>
			<li style={head_fmt}><b>ITIN</b></li>
		      	</ul>	
			</div>
			
		      </Tab>
		      <Tab eventKey="jumbo" title="Jumbo">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>Jumbo</b></li>
			<li style={head_fmt}><b>Jumbo 5/6, 7/6, & 10/6 ARMs</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="construction" title="Construction">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>One Time Close Fixed</b></li>
			<li style={head_fmt}><b>Jumbo One Time Close Fixed</b></li>
			<li style={head_fmt}><b>Jumbo One Time Close ARM</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="renovation" title="Renovation">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>FHA 203(k)</b></li>
			<li style={head_fmt}><b>Fannie Mae HomeStyle and HomeReady HomeStyle</b></li>
			<li style={head_fmt}><b>Freddie Mac ChoiceRenovation & HomePossible ChoiceRenovations</b></li>
			<li style={head_fmt}><b>VA Alterations & Repairs</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="heloc" title="HELOC">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>Piggyback HELOC</b></li>
			<li style={head_fmt}><b>Stand-Alone HELOC</b></li>
			<li style={head_fmt}><b>HELOC for Renovation</b></li>
		      	</ul>	
			</div>
		      </Tab>
		      <Tab eventKey="speciality" title="Specialty">
			<div style={{ textAlign: "left"}}>
			<ul>
			<li style={head_fmt}><b>Recent Event Credit Seasoning</b></li>
			<li style={head_fmt}><b>Unique Property Types</b></li>
			<li style={head_fmt}><b>Link</b></li>
			<li style={head_fmt}><b>Condotel Loans</b></li>
			<li style={head_fmt}><b>Interest Only</b></li>
			<li style={head_fmt}><b>Student Loan Refinance</b></li>
			<li style={head_fmt}><b>Doctor Loan</b></li>
			<li style={head_fmt}><b>Commercial Lot / Land</b></li>
			<li style={head_fmt}><b>Agricultural Lot / Land</b></li>
			<li style={head_fmt}><b>Commerial Bridge</b></li>
			<li style={head_fmt}><b>Commercial Purchase or Refinance</b></li>
			<li style={head_fmt}><b>Commercial Construction Loan</b></li>
			<li style={head_fmt}><b>Land Development</b></li>
			<li style={head_fmt}><b>Self Build / Owner Build Loans</b></li>
			<li style={head_fmt}><b>Hiring a Builder Loan</b></li>
			<li style={head_fmt}><b>Multi-Family Loan</b></li>
			<li style={head_fmt}><b>Builder-Model / Spec Loan</b></li>
			<li style={head_fmt}><b>Builder Sold Loan</b></li>
			<li style={head_fmt}><b>Renovation Loan</b></li>
			<li style={head_fmt}><b>Residential Bridge Loan</b></li>
			<li style={head_fmt}><b>Residential Lot / Land Loans</b></li>
			<li style={head_fmt}><b>Alternative Residential Loans</b></li>
		      	</ul>	
			</div>
		      </Tab>
		    </Tabs>
		</>
	);
}
