import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { Table, Button, Container, Card, Row , Modal} from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import User_vars from '../../components/user_vars'
import navbar from '../../components/navbar'

class AccountControls extends Component
{
		state = {
		sortProfitChecked: false,
		records: [],
		active_state: "",
		active_address: "",
		account: {},
		account_changes : {},
		user_delete_show : false,
		review_account_changes_show : false,
		modify_account : false,
		csrf_token : "",
		}

set_user_vars = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    this.setState({
      [nam]: val
  })
}

handle_account_changes = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    let account_changes = this.state.account_changes
    account_changes[nam] = val
    this.setState({
      account_changes: account_changes
  })
}

deactivate_account = (event) => {
	axios.post("/api/admin_deactivate_account/", {
                    account_id : this.state.account["_id"],
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(() =>{
			    this.queryAccount()

		    })
}

activate_account = (event) => {
   
	axios.post("/api/admin_activate_account/", {
                    account_id : this.state.account["_id"],
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(() =>{
			    this.queryAccount()

		    })
                }

security_lock_account = (event) => {
	axios.post("/api/admin_security_lock_account/", {
                    account_id : this.state.account["_id"],
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(() =>{
			    this.queryAccount()

		    })
}

security_unlock_account = (event) => {
	axios.post("/api/admin_security_unlock_account/", {
                    account_id : this.state.account["_id"],
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(() =>{
			    this.queryAccount()

		    })
}

administrative_lock_account = (event) => {
	axios.post("/api/admin_administrative_lock_account/", {
                    account_id : this.state.account["_id"],
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(() =>{
			    this.queryAccount()

		    })
}

administrative_unlock_account = (event) => {
	axios.post("/api/admin_administrative_unlock_account/", {
                    account_id : this.state.account["_id"],
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(() =>{
			    this.queryAccount()

		    })
}

account_force_logout = (event) => {
	axios.post("/api/admin_account_force_logout/", {
                    account_id : this.state.account["_id"],
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(() =>{
			    this.queryAccount()

		    })
}
modify_account = (event) => {
	let account_changes = {}
	const account_fields = ["email_address", "first_name", "last_name", "member_type", "address", "city", "state", "notes"]
	for (let key in this.state.account) {
		if (account_fields.includes(key)) {
			account_changes[key] = this.state.account[key]
		}
	}
	this.setState({
		modify_account : true,
		account_changes : account_changes
	})
}
discard_account_changes = (event) => {
	this.setState({
		modify_account : false
	})
}

//Show Review Account Changes Modal
show_review_account_changes = (event) => {
	console.log("Hit")
	this.setState({
		review_account_changes_show : true
	})
}

hide_review_account_changes = (event) => {
	this.setState({
		review_account_changes_show : false
	})
}

confirm_account_changes = (event) => {
	axios.post("/api/admin_modify_account/", {
                    account_id : this.state.account["_id"],
		    account_changes : this.state.account_changes,
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(
			    this.hide_review_account_changes()
		    )
                }

//Show User Delete Modal
show_user_delete = (event) => {
	this.setState({
		user_delete_show : true
	})
}

hide_user_delete = (event) => {
	this.setState({
		user_delete_show : false
	})
}

confirm_user_delete = (event) => {
	axios.post("/api/admin_delete_account/", {
                    account_id : this.state.account["_id"],
                    csrf_token : this.state.csrf_token
                })
                .then((response) => {
                        if (response.status == 200) {
                                if ("redirect" in response.data) {
                                window.location = response.data["redirect"]
                                }
                        } else {
			
                        console.log("Issue", response.data)
			}
                    }).then(
			    this.hide_user_delete()
		    )
                }

componentDidUpdate(prevProps, prevState) {
	if (prevState.sortProfitChecked !== this.state.sortProfitChecked) {
		if (this.state.sortProfitChecked) {
		const records = this.state.records
		records.sort(function(a, b) {
  		return b[0] - a[0];
	});
		this.setState({
			"records" : records
		})
	}
	
	else {
		this.queryAccount()
	}
	}
}
	
queryAccount = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const account = queryParams.get("account")
	let param_dict = {
		"account" : account,
	}

  axios.get("/api/admin_query_account/", { 
	  params : param_dict
  })
      .then((response) => {
          this.setState({
              account : response.data.user,
	      csrf_token : response.data.csrf_token
          })
      })
}


componentDidMount() {
	
	this.queryAccount()

}

usdFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
})


render() {

	const queryParams = new URLSearchParams(window.location.search);
	const address = queryParams.get("address")
	const Checkbox = ({ label, value, onChange }) => {
  		return (
    		<label>
      		<input type="checkbox" checked={value} onChange={onChange} />
      		&nbsp;{label}
    		</label>
  		);
	};

	//This state allows for modification of account info
	const account_modification_inject = () => {
		
		if (this.state.modify_account) {
			return(
				<>
				<Button className='my-2' variant="secondary" onClick={this.discard_account_changes}>Cancel</Button>&nbsp;&nbsp;
				<Button className='my-2' variant="success" onClick={this.show_review_account_changes}>Save Changes</Button>
				</>
			)
		} else {
			return(
				<Button className='my-2' variant="primary" onClick={this.modify_account}>Modify Account</Button>
			)
		}
	}

	//Review and Confirm Changes to Account
	const review_account_changes_inject = () => {
		let account_changes = []
		const account_fields = ["email_address", "first_name", "last_name", "member_type", "address", "city", "state", "notes"]
		for (let key in this.state.account) {
			if (account_fields.includes(key)) {
				if (this.state.account[key] != this.state.account_changes[key]) {
					account_changes.push(<li>{this.state.account[key]}  -->  {this.state.account_changes[key]}</li>)
				}
			}
		}
		return(
			<Modal show={this.state.review_account_changes_show} onHide={this.hide_review_account_changes}>
				<Modal.Header closeButton>
					<Modal.Title>Review Account Changes</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul>
					{account_changes}
					</ul>
				</Modal.Body>
				<Modal.Footer>
						
					<Button className='my-2' variant="secondary" onClick={this.hide_review_account_changes}>Cancel</Button>
					<Button className='my-2' variant="success" onClick={this.confirm_account_changes}>Confirm Changes</Button>
				</Modal.Footer>
			</Modal>
		)
	}
	
	const account_activation_inject = () => {
		if (!this.state.modify_account) {
			if (this.state.account.member_active) {
				return(
					<Button className='my-2' variant="warning" onClick={this.deactivate_account}>Deactivate Account</Button>
				)
			} else {
				return(
					<Button className='my-2' variant="success" onClick={this.activate_account}>Activate Account</Button>
				)
			}
		}
	}
	
	const account_security_lock_inject = () => {
		if (!this.state.modify_account) {
			if (this.state.account.security_lock == false) {
				return(
					<Button className='my-2' variant="warning" onClick={this.security_lock_account}>Apply Security Lock</Button>
				)
			} else {
				return(
					<Button className='my-2' variant="success" onClick={this.security_unlock_account}>Remove Security Lock</Button>
				)
			}
		}
	}
	const account_administrative_lock_inject = () => {
		if (!this.state.modify_account) {
			if (this.state.account.administrative_lock == false) {
				return(
					<Button className='my-2' variant="danger" onClick={this.administrative_lock_account}>Apply Administrative Lock</Button>
				)
			} else {
				return(
					<Button className='my-2' variant="warning" onClick={this.administrative_unlock_account}>Remove Administrative Lock</Button>
				)
			}
		}
	}
	
	const account_force_logout_inject = () => {
		if (!this.state.modify_account) {
			if (this.state.account.forced_logout_timestamp >= this.state.account.last_login_timestamp) {
				return(
					<Button className='my-2' variant="info" onClick={this.account_force_logout}>Force Logout</Button>
				)
			} else {
				return(
					<Button className='my-2' variant="warning" onClick={this.account_force_logout}>Force Logout</Button>
				)
			}
		}
	}
	
	const account_deletion_inject = () => {
		if (this.state.modify_account) {
			return(
				<>
				<Button className='my-2' variant="danger" onClick={this.show_user_delete}>Delete Account</Button>
				<Modal show={this.state.user_delete_show} onHide={this.hide_user_delete}>
					<Modal.Header closeButton>
						<Modal.Title>Delete User</Modal.Title>
					</Modal.Header>
					<Modal.Body>Please Confirm User Account Deletion</Modal.Body>
					<Modal.Footer>
							
						<Button className='my-2' variant="secondary" onClick={this.hide_user_delete}>Cancel</Button>
						<Button className='my-2' variant="danger" onClick={this.confirm_user_delete}>Delete User</Button>
					</Modal.Footer>
				</Modal>
				</>
			)
		}
	}

	const account_info_inject = () => {
	console.log(this.state)	
			const x = this.state.account
			const current_email_address = x["email_address"]
			const current_first_name = x["first_name"]
			const current_last_name = x["last_name"]
			let member_status = x["member_active"]
			if (member_status) {
				member_status = "ACTIVE"
			} else {
				member_status = "INACTIVE"
			}
			const current_member_type = x["member_type"]
			const user_type = x["user_type"]
			const signup_datetime = x["signup_datetime"]
			const last_payment_date = x["last_payment_date"]
			
			const y = this.state.account_changes
			const new_email_address = y["email_address"]
			const new_first_name = y["first_name"]
			const new_last_name = y["last_name"]
			const new_member_type = y["member_type"]
		if (this.state.modify_account) {
			return(
				<div>
				<Table>
				<tbody>
				<tr>
				    <th>Email Address ID</th>
				    <th>First Name</th>
				    <th>Last Name</th>
				    <th>Member Status</th>
				</tr>    
				<tr>
					<td><input type="text" name="email_address" id="email_address" value={new_email_address} onChange={this.handle_account_changes} /></td>
					<td><input type="text" name="first_name" id="first_name" value={new_first_name} onChange={this.handle_account_changes} /></td>
					<td><input type="text" name="last_name" id="last_name" value={new_last_name} onChange={this.handle_account_changes} /></td>
					<td style={{color : member_status == "ACTIVE" ? "green" : "red" }}>{member_status}</td>
				    </tr>
				</tbody>
				</Table>
				<Table>
				<tbody>
				<tr>
					<th>Member Type</th>
					<th>User Type</th>
					<th>Sign Up DateTime</th>
					<th>Last Payment Date</th>
				</tr>    
				<tr>
					<td>{new_member_type}</td>
					<td>{user_type}</td>
					<td>{signup_datetime}</td>
					<td>{last_payment_date}</td>
				    </tr>
				</tbody>
				</Table>
				</div>
			)
		} else {
			return(
				<div>
				<Table>
				<tbody>
				<tr>
				    <th>Email Address ID</th>
				    <th>First Name</th>
				    <th>Last Name</th>
				    <th>Member Status</th>
				</tr>    
				<tr>
					<td>{current_email_address}</td>
					<td>{current_first_name}</td>
					<td>{current_last_name}</td>
					<td style={{color : member_status == "ACTIVE" ? "green" : "red" }}>{member_status}</td>
				    </tr>
				</tbody>
				</Table>
				<Table>
				<tbody>
				<tr>
					<th>Member Type</th>
					<th>User Type</th>
					<th>Sign Up DateTime</th>
					<th>Last Payment Date</th>
				</tr>    
				<tr>
					<td>{current_member_type}</td>
					<td>{user_type}</td>
					<td>{signup_datetime}</td>
					<td>{last_payment_date}</td>
				    </tr>
				</tbody>
				</Table>
				</div>
			)
		}

	}

	const account_security_inject = () => {
	console.log(this.state)	
			const x = this.state.account
			let security_lock = x["security_lock"]
			if (security_lock) {
				security_lock = "LOCKED"
			} else {
			   security_lock = "UNLOCKED"
			}
			let administrative_lock = x["administrative_lock"]
			if (administrative_lock) {
				administrative_lock = "LOCKED"
			} else {
			   administrative_lock = "UNLOCKED"
			}
			let last_login_timestamp = new Date(x["last_login_timestamp"] * 1000)
			last_login_timestamp = last_login_timestamp.toString()
			let forced_logout_timestamp = new Date(x["forced_logout_timestamp"] * 1000)
			forced_logout_timestamp = forced_logout_timestamp.toString()
			const user_type = x["user_type"]
			if (!this.state.modify_account) {
				return(
				<div>
				<Table>
				<tbody>
				<tr>
				    <th>Security Lock</th>
				    <th>Administrative Lock</th>
				    <th>Last Login Timestamp</th>
				    <th>Forced Logout Timestamp</th>
				    <th>User Type</th>
				</tr>    
				<tr>
					<td style={{color : security_lock == "UNLOCKED" ? "green" : "red" }}>{security_lock}</td>
					<td style={{color : administrative_lock == "UNLOCKED" ? "green" : "red" }}>{administrative_lock}</td>
					<td>{last_login_timestamp}</td>
					<td>{forced_logout_timestamp}</td>
					<td>{user_type}</td>
				    </tr>
				</tbody>
				</Table>
				</div>
			)
			}
		}

  return (
     	
	<div className='App'>
	  <style>{"table{ border:1px solid black; width:100%; table-layout:fixed;}"}</style>
	  <div>
	  	{account_activation_inject()}&nbsp;&nbsp;
	  	{review_account_changes_inject()}
	  	{account_modification_inject()}&nbsp;&nbsp;
	   	{account_info_inject()}
	  	{account_security_lock_inject()}&nbsp;&nbsp;
		{account_administrative_lock_inject()}&nbsp;&nbsp;
		{account_force_logout_inject()}
		{account_security_inject()}
	  	{account_deletion_inject()}
      	</div>
    </div>
  );
}
}

export default AccountControls;
