import React, { Component } from 'react';
import { Container } from 'react-bootstrap';


class Partnerships extends Component {
  render() {
    return (
      <div>
        <div className="founders-journey">
          <h3>Are you finding 40% ROI homes?</h3>
	    <Container>
	   <p><b>
		Partner with us if you are:
	    </b></p>
	    	NAR;&nbsp;Association;&nbsp;Broker;&nbsp;REALTOR
	  	<br/>
	    	506c/506cf/506b;&nbsp;RE&nbsp;syndicate
	  	<br/>
	    	Builder;&nbsp;Contractor;&nbsp;Flipper
	    	<br/>
	  	Affordable Housing Advocate
	    	<br/>
		(i.e. 20-30% off on market rent ... np still not losing $)
		 <br/>
		 <br/>

	    <p><b>If interested in a partnership, please contact Kirill Gorbounov at 571-276-0986</b></p>
	    </Container>
	   <br/>
	    <br/>
        </div>
      </div>
    );
  }
}

export default Partnerships;

