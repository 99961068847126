import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CFRE_logo from '../assets/images/CFRE-AI_logo_simple.png'
import { BiUserCircle } from "react-icons/bi"

//<Nav.Link href="/user/signup">Sign Up</Nav.Link>
function navbar(props) {

	let logo_style = {
		width : "50px"
	}
	if (!props.auth){
  return (
    <>
      <Navbar expand="lg" fixed="top" style={{"background-color": "rgb(0,0,0)"}} variant="dark">
        <Container>
          <Navbar.Brand href="/">
	<img src={CFRE_logo} style={logo_style}/>&nbsp;&nbsp;
	  CFRE-AI</Navbar.Brand>
	<Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/about_us">About Us</Nav.Link>
	    <Nav.Link href="/loan/landing">Get Financing</Nav.Link>	
            <Nav.Link href="/partnerships">Partnerships</Nav.Link>
	  </Nav>
	  <Nav>
	    <Nav.Link href="https://calendly.com/cfre-ai">Sign Up</Nav.Link>
	    <Nav.Link href="/user/login">Log In</Nav.Link>
          </Nav>
	</Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
    </>
  );
	} else {
	    if (!props.admin) {
	 	const subscribe_inject = () => {
			if (props.member_type == "trial") {
				console.log("True")
				return(
					<Nav.Link href="/user/subscribe">Subscribe</Nav.Link>
				)
			}
		}
	    
		  return (
		    <>
		      <Navbar expand="lg" fixed="top" style={{"background-color": "rgb(0,0,0)"}} variant="dark">
			<Container>
			  <Navbar.Brand href="/">
			  <img src={CFRE_logo} style={logo_style}/>&nbsp;&nbsp;
			  CFRE-AI</Navbar.Brand>
			    <Navbar.Toggle aria-controls="basic-navbar-nav" />
			    <Navbar.Collapse id="basic-navbar-nav">
			  <Nav className="me-auto">
			    <Nav.Link href="/about_us">About Us</Nav.Link>
			    <Nav.Link href="/dashboard">Favorites</Nav.Link>
			    <NavDropdown title="Search Listings" id="basic-nav-dropdown">
				<NavDropdown.Item href="/states?ref=zipcode">Zip Code</NavDropdown.Item>
				<NavDropdown.Item href="/states?ref=city">City</NavDropdown.Item>
				<NavDropdown.Item href="/states?ref=county">County</NavDropdown.Item>
				<NavDropdown.Item href="/states">State</NavDropdown.Item>
				<NavDropdown.Item href="/logical_areas">Logical Area</NavDropdown.Item>
			    </NavDropdown>
			    <Nav.Link href="/loan/landing">Get Financing</Nav.Link>
            		    <Nav.Link href="/partnerships">Partnerships</Nav.Link>
			  </Nav>  
			  <Nav>
			    {subscribe_inject()}
			    <NavDropdown title="Account" id="basic-nav-dropdown">
				<NavDropdown.Item href="/account">Manage Account</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item href="/logout">Log Out</NavDropdown.Item>
			    </NavDropdown>
			  </Nav>
			    </Navbar.Collapse>
			</Container>
		      </Navbar>
		      <br />
		    </>
		  );
	    } else {
	    
		  return (
		    <>
		      <Navbar expand="lg" fixed="top" style={{"background-color": "rgb(0,0,0)"}} variant="dark">
			<Container>
			  <Navbar.Brand href="/">
			  <img src={CFRE_logo} style={logo_style}/>&nbsp;&nbsp;
			  CFRE-AI</Navbar.Brand>
			     <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
			  <Nav className="me-auto">
			    <Nav.Link href="/about_us">About Us</Nav.Link>
			    <Nav.Link href="/dashboard">Favorites</Nav.Link>
			    <NavDropdown title="Search Listings" id="basic-nav-dropdown">
				<NavDropdown.Item href="/states?ref=zipcode">Zip Code</NavDropdown.Item>
				<NavDropdown.Item href="/states?ref=city">City</NavDropdown.Item>
				<NavDropdown.Item href="/states?ref=county">County</NavDropdown.Item>
				<NavDropdown.Item href="/states">State</NavDropdown.Item>
				<NavDropdown.Item href="/logical_areas">Logical Area</NavDropdown.Item>
			    </NavDropdown>
			    <Nav.Link href="/loan/landing">Get Financing</Nav.Link>
            		    <Nav.Link href="/partnerships">Partnerships</Nav.Link>
			  </Nav>  
			  <Nav>
			    <NavDropdown title="Admin Functions" id="basic-nav-dropdown">
			    <NavDropdown.Item href="/admin/manage-users">Manage Users</NavDropdown.Item>
				<NavDropdown.Divider />
			    <NavDropdown.Item href="/admin/reported-listings">Reported Listings</NavDropdown.Item>
			    <NavDropdown.Item href="/admin/quarantined-listings">Quarantined Listings</NavDropdown.Item>
			    </NavDropdown>
			     <NavDropdown title="Account" id="basic-nav-dropdown">
			  	
				<NavDropdown.Item href="/account">Manage Account</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item href="/logout">Log Out</NavDropdown.Item>
			    </NavDropdown>
			  </Nav>
			     </Navbar.Collapse>
			</Container>
		      </Navbar>
		      <br />
		    </>
		  );

	    }
	}


}
export default navbar;
