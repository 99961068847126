import React, { useState, useEffect } from "react";
import { Card, Form, Table, Col, Row, FormControl, Button, DropdownButton, Dropdown } from "react-bootstrap";

function RentDataPage() {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [zipcodes, setZipcodes] = useState([]);
  const [selectedZipcode, setSelectedZipcode] = useState("");
  const [subdivisions, setSubdivisions] = useState([]);
  const [selectedSubdivision, setSelectedSubdivision] = useState("");
  
  const [structureTypes, setStructureTypes] = useState([]);
  const [selectedStructureType, setSelectedStructureType] = useState("");
  
  const [bedrooms, setBedrooms] = useState([]);
  const [selectedBedrooms, setSelectedBedrooms] = useState("");


  const [interiorSqFt, setInteriorSqFt] = useState('')

  const [rentAnalysis, setRentAnalysis] = useState({})
  const [loading, setLoading] = useState(false);

const display_results = () => {
	console.log(rentAnalysis)
	if ("rent" in rentAnalysis) {
		return(
			<>
			<Card>
			<Table>
				<thead>
					<th>Rent</th>
				</thead>
				<tbody>
					<tr>
						<td>{rentAnalysis["rent"]}</td>
					</tr>
				</tbody>
			</Table>
			</Card>

			</>
		)

	}

}

const inputStyle = {
    width: "100px", // Adjust the width as needed
    height: "40px", // Adjust the height as needed
    padding: "0.375rem 0.75rem", // Maintain the input field padding
  };

const containerStyle = {
    textAlign: "center", // Center the input field
  };


function handleSubmit(event) {
    event.preventDefault();
    setRentAnalysis({})
    let data = {}
    if (selectedZipcode == "") {
    	fetch("/api/query_cfre_ai_rent/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + selectedCity + "&structure_type=" + selectedStructureType + "&bedrooms=" + selectedBedrooms + "&interior_sqft=" + interiorSqFt  + "&level=city")
	.then(response => response.json())
	.then(data => {
		setRentAnalysis(data)
	})
    } else if (selectedSubdivision == "") {
    	fetch("/api/query_cfre_ai_rent/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + selectedCity + "&zipcode=" + selectedZipcode + "&structure_type=" + selectedStructureType + "&bedrooms=" + selectedBedrooms + "&interior_sqft=" + interiorSqFt + "&level=zipcode")
	.then(response => response.json())
	.then(data => {
		setRentAnalysis(data)
	})
    } else {
    	fetch("/api/query_cfre_ai_rent/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + selectedCity + "&zipcode=" + selectedZipcode + "&subdivision=" + selectedSubdivision + "&structure_type=" + selectedStructureType + "&bedrooms=" + selectedBedrooms + "&interior_sqft=" + interiorSqFt + "&level=subdivision")
	.then(response => response.json())
	.then(data => {
		setRentAnalysis(data)
	})
    }
}

const handleIntSqftChange = (event) => {
    setInteriorSqFt(event.target.value);
  };

  useEffect(() => {
    async function fetchStates() {
      setLoading(true);
      const response = await fetch("/api/query_model_states/");
      const data = await response.json();
      setStates(data.state_list.sort((a, b) => a.localeCompare(b)));
      setLoading(false);
    }
    fetchStates();
  }, []);

  async function fetchCounties(state) {
    setLoading(true);
    const response = await fetch("/api/query_model_counties/?state=" + state);
    const data = await response.json();
    setCounties(data.county_list.sort((a, b) => a.localeCompare(b)));
    setLoading(false);
  }
  
  async function fetchCities(county) {
    setLoading(true);
    const response = await fetch("/api/query_model_cities/?state=" + selectedState + "&county=" + county);
    const data = await response.json();
    setCities(data.city_list.sort((a, b) => a.localeCompare(b)));
    setLoading(false);
  }
  
  async function fetchZipcodes(city) {
    setLoading(true);
    const response = await fetch("/api/query_model_zipcodes/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + city);
    const data = await response.json();
    setZipcodes(data.zipcode_list.sort((a, b) => a.localeCompare(b)));
    setLoading(false);
  }
  
  async function fetchSubdivisions(zipcode) {
    setLoading(true);
    const response = await fetch("/api/query_model_subdivisions/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + selectedCity + "&zipcode=" + zipcode);
    const data = await response.json();
    setSubdivisions(data.subdivision_list.sort((a, b) => a.localeCompare(b)));
    setLoading(false);
  }
  
  async function fetchStructureTypes(level, name) {
    setLoading(true);
    let data = {}
    if (level == "city") {
    	const response = await fetch("/api/query_model_structure_types/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + name + "&level=city");
    	data = await response.json();
    } else if (level == "zipcode") {
    	const response = await fetch("/api/query_model_structure_types/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + selectedCity + "&zipcode=" + name + "&level=zipcode");
    	data = await response.json();
    } else {
    	const response = await fetch("/api/query_model_structure_types/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + selectedCity + "&zipcode=" + selectedZipcode + "&subdivision=" + name + "&level=subdivision");
    	data = await response.json();
    }
    setStructureTypes(data.structure_types_list.sort((a, b) => a.localeCompare(b)));
    setLoading(false);
  }
  
  async function fetchBedrooms(structure_type, level, name) {
    setLoading(true);
    let data = {}
    if (level == "city") {
    	const response = await fetch("/api/query_model_bedrooms/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + name + "&structure_type=" + structure_type + "&level=city");
    	data = await response.json();
    } else if (level == "zipcode") {
    	const response = await fetch("/api/query_model_bedrooms/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + selectedCity + "&zipcode=" + name + "&structure_type=" + structure_type+ "&level=zipcode");
    	data = await response.json();
    } else {
    	const response = await fetch("/api/query_model_bedrooms/?state=" + selectedState + "&county=" + selectedCounty + "&city=" + selectedCity + "&zipcode=" + selectedZipcode + "&subdivision=" + name + "&structure_type=" + structure_type + "&level=subdivision");
    	data = await response.json();
    }
    setBedrooms(data.bedrooms_list.sort((a, b) => a.localeCompare(b)));
    setLoading(false);
  }

  function handleStateChange(event) {
    const state = event;
    setSelectedState(state);
    setSelectedCounty("");
    setSelectedCity("");
    setSelectedZipcode("");
    setSelectedSubdivision("");
    setSelectedStructureType("");
    setSelectedBedrooms("");
    
    setCounties([]);
    setCities([]);
    setZipcodes([]);
    setSubdivisions([]);
    setStructureTypes([]);
    setBedrooms([])
    
    fetchCounties(state);
  }
  
  function handleCountyChange(event) {
    const county = event;
    setSelectedCounty(county);
    setSelectedCity("");
    setSelectedZipcode("");
    setSelectedSubdivision("");
    setSelectedStructureType("");
    setSelectedBedrooms("");
    
    setCities([]);
    setZipcodes([]);
    setSubdivisions([]);
    setStructureTypes([]);
    setBedrooms([])
    
    fetchCities(county);
  }

  function handleCityChange(event) {
    const city = event;
    setSelectedCity(city);
    setSelectedZipcode("");
    setSelectedSubdivision("");
    setSelectedStructureType("");
    setSelectedBedrooms("");
    
    setZipcodes([]);
    setSubdivisions([]);
    setStructureTypes([]);
    setBedrooms([])
    
    fetchZipcodes(city);
    fetchStructureTypes("city", city)
  }
  
  function handleZipcodeChange(event) {
    const zipcode = event;
    setSelectedZipcode(zipcode);
    setSelectedSubdivision("");
    setSelectedStructureType("");
    setSelectedBedrooms("");
    
    setSubdivisions([]);
    setStructureTypes([]);
    setBedrooms([])
    
    fetchSubdivisions(zipcode);
    fetchStructureTypes("zipcode", zipcode)
  }
  
  function handleSubdivisionChange(event) {
    const subdivision = event;
    setSelectedSubdivision(subdivision);
    setSelectedStructureType("");
    setSelectedBedrooms("");
    
    setStructureTypes([]);
    setBedrooms([])
    
    fetchStructureTypes("subdivision", subdivision)
  }
  
  function handleStructureTypeChange(event) {
    const structure_type = event;
    setSelectedStructureType(structure_type);
    setSelectedBedrooms("");
    setBedrooms([])
    if (selectedZipcode == "") {
    	fetchBedrooms(structure_type, "city", selectedCity);
    }
    else if (selectedSubdivision == "") {
    	fetchBedrooms(structure_type, "zipcode", selectedZipcode);
    }
    else {
    	fetchBedrooms(structure_type, "subdivision", selectedSubdivision);
    }
  }
  
  function handleBedroomsChange(event) {
    const bedrooms = event;
    setSelectedBedrooms(bedrooms);
  }
  
  return (
      <div className="card-wrapper">
      <Card>
        <Card.Body>
          <h3>CFRE-AI Rent</h3>
            <Form.Group>
              <Form.Label>Select State:</Form.Label>
              <DropdownButton
	  	variant="dark"
                title={loading ? "Loading..." : selectedState || "Select State"}
                disabled={loading || states.length === 0}
                onSelect={handleStateChange}
              >
	  	<div style={{"max-height": "300px", overflowY : "auto"}}>
                {states.map((state) => (
                  <Dropdown.Item key={state} eventKey={state}>
                    {state}
                  </Dropdown.Item>
                ))}
	  	</div>
              </DropdownButton>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select County:</Form.Label>
              <DropdownButton
	  	variant="dark"
                title={loading ? "Loading..." : counties.length === 0 ? "No Counties Found" : selectedCounty.slice(0, -2) || "Select County"}
                disabled={loading || counties.length === 0}
                onSelect={handleCountyChange}
              >
                <div style={{ "max-height": "300px", overflowY : "auto" }}>
                {counties.map((county) => (
                  <Dropdown.Item key={county} eventKey={county}>
                    {county.slice(0, -2)}
                  </Dropdown.Item>
                ))}
	  	</div>
              </DropdownButton>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select City:</Form.Label>
              <DropdownButton
	  	variant="dark"
                title={loading ? "Loading..." : cities.length === 0 ? "No Cities Found" : selectedCity || "Select City"}
                disabled={loading || cities.length === 0}
                onSelect={handleCityChange}
              >
                <div style={{ "max-height": "300px", overflowY : "auto" }}>
                {cities.map((city) => (
                  <Dropdown.Item key={city} eventKey={city}>
                    {city}
                  </Dropdown.Item>
                ))}
	  	</div>
              </DropdownButton>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Zipcode:</Form.Label>
              <DropdownButton
	  	variant="dark"
                title={loading ? "Loading..." : zipcodes.length === 0 ? "No Zipcodes Found" : selectedZipcode || "Select Zipcode"}
                disabled={loading || zipcodes.length === 0}
                onSelect={handleZipcodeChange}
              >
                <div style={{ "max-height": "300px", overflowY : "auto" }}>
                {zipcodes.map((zipcode) => (
                  <Dropdown.Item key={zipcode} eventKey={zipcode}>
                    {zipcode}
                  </Dropdown.Item>
                ))}
	  	</div>
              </DropdownButton>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Subdivision:</Form.Label>
              <DropdownButton
	  	variant="dark"
                title={loading ? "Loading..." : subdivisions.length === 0 ? "No Subdivisions Found" : selectedSubdivision || "Select Subdivision"}
                disabled={loading || subdivisions.length === 0}
                onSelect={handleSubdivisionChange}
              >
                <div style={{ "max-height": "300px", overflowY : "auto" }}>
                {subdivisions.map((subdivision) => (
                  <Dropdown.Item key={subdivision} eventKey={subdivision}>
                    {subdivision}
                  </Dropdown.Item>
                ))}
	  	</div>
              </DropdownButton>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Structure Type:</Form.Label>
              <DropdownButton
	  	variant="dark"
                title={loading ? "Loading..." : structureTypes.length === 0 ? "No Structure Types Found" : selectedStructureType || "Select Structure Type"}
                disabled={loading || structureTypes.length === 0}
                onSelect={handleStructureTypeChange}
              >
                <div style={{ "max-height": "300px", overflowY : "auto" }}>
                {structureTypes.map((structure_type) => (
                  <Dropdown.Item key={structure_type} eventKey={structure_type}>
                    {structure_type}
                  </Dropdown.Item>
                ))}
	  	</div>
              </DropdownButton>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Bedrooms:</Form.Label>
              <DropdownButton
	  	variant="dark"
                title={loading ? "Loading..." : bedrooms.length === 0 ? "No Bedrooms Found" : selectedBedrooms || "Select Bedrooms"}
                disabled={loading || bedrooms.length === 0}
                onSelect={handleBedroomsChange}
              >
                <div style={{ "max-height": "300px", overflowY : "auto" }}>
                {bedrooms.map((bedroom) => (
                  <Dropdown.Item key={bedroom} eventKey={bedroom}>
                    {bedroom}
                  </Dropdown.Item>
                ))}
	  	</div>
              </DropdownButton>
            </Form.Group>
	  <Form.Group className="d-flex justify-content-center align-items-center flex-column" >
              <Form.Label>Enter Interior SqFt:</Form.Label>
	  <FormControl
		type="text"
		style={inputStyle}
	  	value={interiorSqFt}
		onChange={handleIntSqftChange}
	      />
	  </Form.Group>
	  <br/>
            <Button
	  	variant="primary"
	  	onClick={handleSubmit}
              	disabled={loading || selectedState == "" || selectedCounty == "" || selectedCity == "" || selectedStructureType == "" || selectedBedrooms == "" || interiorSqFt == ""}
	  	>
	  	Get Rent
            </Button>
        </Card.Body>
      </Card>
     {display_results()}
    </div>


  );
}

export default RentDataPage;

