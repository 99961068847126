import React from 'react';
import { Card } from 'react-bootstrap';

const SubscriptionDisclaimer = () => {
  return (
<div className="table-wrapper">
          <div style={{ textAlign: "left"}}>
          <Card style={{"width" : "100%"}}>


      <Card.Header className="text-center" as="h3">Subscription Disclaimer</Card.Header>
      <Card.Body>
        <Card.Text>
          Thank you for your interest in our subscription service. We want to make sure that our customers understand our policies regarding refunds.
        </Card.Text>
        <Card.Text>
          Currently, all purchases of our subscription service are final and non-refundable. This is because the information learned from our service cannot be returned or "unlearned" once it has been accessed. By subscribing to our service, you acknowledge and agree to this policy.
        </Card.Text>
        <Card.Text>
          We understand that there may be circumstances where a refund is warranted, such as in the case of technical issues or billing errors. In such cases, please contact our customer support team and we will work with you to find a solution.
        </Card.Text>
        <Card.Text>
          We want our customers to be satisfied with our service and we strive to provide a high-quality experience. If you have any questions or concerns about our refund policy, please don't hesitate to contact us.
        </Card.Text>
        <Card.Text>
          Thank you for your understanding.
        </Card.Text>
      </Card.Body>
    </Card>
	 </div>
	  </div>
  );
};

export default SubscriptionDisclaimer;

