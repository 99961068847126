import React, { Text, useState, useEffect } from "react";
import { Button, Container, Col, Row, Card, Form } from 'react-bootstrap'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkout_form";
import "../css/stripe.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function Pricing(props) {
	const stripePromise = loadStripe(props.stripe_api_key);
  const [subscription_amount, setSubscriptionAmount] = useState("")
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/api/retrieve_pricing/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "monthly_standard_subscription" }] }),
    })
      .then((res) => res.json())
      .then((data) => { 
	      setSubscriptionAmount(data.amount);
      })
  }, []);

  const usdFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

  const appearance = {
    theme: 'stripe',
  };
	const buttonStyle = {
      "min-width": '280px',
      height: '60px',
      lineHeight: '45px',
        fontSize: '19px',
        textAlign : "left",
        fontWeight : "lighter",
          boxShadow: "0 2px 4px rgba(0,0,0,0.3)"
  };


	//<a><b style={{color: 'red'}}>{usdFormat.format(subscription_amount)}</b><b> Billed Monthly</b></a>
	//<a style={{color: 'black'}}><b>Pricing will increase to </b><b style={{color: 'red'}}>$179</b><b> very soon to accomodate increase in demand.</b></a>
  return (
	<>
		    <div className="card-wrapper">
			<Card style={{maxWidth :"90%"}} >
			  <div >
			    <h4>Standard Subscription:</h4>
				    <br/>
				    <a><b style={{color: 'red'}}>$49.00</b><b> Billed Monthly</b></a>
	  		</div>
	  		<b>Access to Cashflow, ROI, Rent Analysis, and more for the following states:</b>
	  		<br/>
	  		 <div>
	  		    <ul>
	  			<li style={{"list-style-type": "none"}}>Delaware</li>
	  			<li style={{"list-style-type": "none"}}>Maryland</li>
	  			<li style={{"list-style-type": "none"}}>New Jersey</li>
	  			<li style={{"list-style-type": "none"}}>Pennsylvania</li>
	  			<li style={{"list-style-type": "none"}}>Virginia</li>
	  			<li style={{"list-style-type": "none"}}>Washington DC</li>
	  			<li style={{"list-style-type": "none"}}>West Virginia</li>
			    </ul>		
	  		</div>
			<a style={{color: 'black'}}><b>Price is subject to change to accomodate demand.</b></a>
	  		<br/>
	  		<Container style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>

                        <Button href="/user/signup" variant="info" style={buttonStyle}><b style={{color : "white", fontWeight : "bold"}}>Start Your Free Trial Today >></b></Button>
                        </Container>

	  		</Card>
	  	</div>
	  </>
  );
}
