import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { Offcanvas, InputGroup, Button, Container, Card, Form, Row, Col, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import User_vars from '../../components/user_vars'
import navbar from '../../components/navbar'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import SpinnerLoading from '../../components/spinner.js';
import AddressDetails from "./AddressDetails";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

class CategorySearch extends Component
{
	state = {
		total_entries: 0,
		total_pages: 0,
		current_page: 1,
		records_shown: 20,
		distance: 0,
		index: 0,
		hit_top : false,
		hit_bottom : false,
		
		showUserVars : false,
		down_pmt: '20',
		renovation: '10',
		close_cost: '2',
		cost_of_sale: '4.5',
		loan_apr: '5',
		mgmt_fee: '0',
		mnth_vacant: '1',
		rent_annual_inc: '5',
		depreciation: '27.5',
		appreciation: '5',
		income_tax_rate: '30',
		cap_gains_tax_rate: '15',
		term: '30',
		condo_fee: '300',
		renovation_add: '70',

		listing_status_active: true,
		listing_status_coming_soon: true,
		listing_status_pending: false,
		listing_status_withdrawn: false,
		listing_status_active_under_contract: true,
		listing_status_hold: false,
		listing_status_closed: false,
		listing_status_canceled: false,
		listing_status_expired: false,

		listing_structure_townhouse: true,
		listing_structure_apartment_condo: true,
		listing_structure_detached: true,
		listing_structure_semi_detached: true,

		showFilters : false,
		rent_acc_very_high : true,
		rent_acc_high : true,
		rent_acc_medium : true,
		rent_acc_low : true,
		rent_acc_very_low : true,

		showPreferences : false,
		low_price : "0",
		high_price : "0",
		low_int_sqft : "0",
		high_int_sqft : "0",
		bed_0 : true,
		bed_1 : false,
		bed_2 : false,
		bed_3 : false,
		bed_4 : false,
		bed_5 : false,
		active_bed_plus : 0,
		bath_0 : true,
		bath_1 : false,
		bath_2 : false,
		bath_3 : false,
		bath_4 : false,
		bath_5 : false,
		active_bath_plus : 0,

		sortedField : "Yearly Cash Flow",
		sortInvert : false,
		sortProfitChecked: true,
		records: [],
		paged_records : [],
		search_text: "",
		active_state: "",

		show_address_details : false,
		prop_id : "",
		prop_id_code : "",
	}

set_bed_plus = (count) => {

	let bed_dict = {
		"0" : false,
		"1" : false,
		"2" : false,
		"3" : false,
		"4" : false,
		"5" : false,
	}
	bed_dict[count] = true
	this.setState({
		bed_0 : bed_dict["0"],
		bed_1 : bed_dict["1"],
		bed_2 : bed_dict["2"],
		bed_3 : bed_dict["3"],
		bed_4 : bed_dict["4"],
		bed_5 : bed_dict["5"],
		active_bed_plus : count,
	})
}

set_bath_plus = (count) => {

	let bath_dict = {
		"0" : false,
		"1" : false,
		"2" : false,
		"3" : false,
		"4" : false,
		"5" : false,
	}
	bath_dict[count] = true
	this.setState({
		bath_0 : bath_dict["0"],
		bath_1 : bath_dict["1"],
		bath_2 : bath_dict["2"],
		bath_3 : bath_dict["3"],
		bath_4 : bath_dict["4"],
		bath_5 : bath_dict["5"],
		active_bath_plus : count,
	})
}

set_rent_accuracy_filter = (name) => {
	this.setState({
		[name] : !this.state[name]
	})
}

set_structure_type = (name) => {
	this.setState({
		[name] : !this.state[name]
	})
}

hideFilters = () => {
	this.setState({
    		showFilters : false
	})
}

showFilters = () => {
	this.setState({
		showFilters : true
	})
}
hidePreferences = () => {
	this.setState({
    		showPreferences : false
	})
}

showPreferences = () => {
	this.setState({
		showPreferences : true
	})
}

set_user_vars = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    this.setState({
    	[nam]: val
	})
}

set_search_text = (event) => {
    let search_text = event.target.name;
    let val = event.target.value
    this.setState({
    	search_text: val
  })
}

set_price_limits = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    if (!isNaN(val)) {
	this.setState({
    	[nam] : val
    })
    }
  
}

set_int_sqft_limits = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    if (!isNaN(val)) {
	this.setState({
    	[nam] : val
    })
    }
  
}

hideUserVars = () => {
	this.setState({
    	showUserVars : false
	})
}

showUserVars = () => {
	this.setState({
		showUserVars : true
	})
}

submit_user_vars = () => {
	const param_dict = {
		"down_pmt" : this.state.down_pmt.toString(),
		"renovation" : this.state.renovation.toString(),
		"close_cost" : this.state.close_cost.toString(),
		"cost_of_sale" : this.state.cost_of_sale.toString(),
		"loan_apr" : this.state.loan_apr.toString(),
		"mgmt_fee" : this.state.mgmt_fee.toString(),
		"mnth_vacant" : this.state.mnth_vacant.toString(),
		"rent_annual_inc" : this.state.rent_annual_inc.toString(),
		"depreciation" : this.state.depreciation.toString(),
		"appreciation" : this.state.appreciation.toString(),
		"income_tax_rate" : this.state.income_tax_rate.toString(),
		"cap_gains_tax_rate" : this.state.cap_gains_tax_rate.toString(),
		"term" : this.state.term.toString(),
		"condo_fee" : this.state.condo_fee.toString(),
		"renovation_add" : this.state.renovation_add.toString(),
		"records_shown" : this.state.records_shown.toString(),
		"index" : this.state.index.toString(),
	}
	axios.post("/api/save_user_vars/", { 
		params : param_dict
  	}).then(() => {
		this.queryCategory()
  	})
}

sortByKey(array, key, parse, rev) {
    let arr = array.sort(function(a, b) {
		if (parse == "float") {
        	var x = parseFloat(a[key]); var y = parseFloat(b[key]);
		} else {
        	var x = a[key]; var y = b[key];
		}
    	return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    })
	if (rev) {
		return arr.reverse();
	} else {
		return arr
	}
}

setSortInvert = () => {
	this.setState({sortInvert : !this.state.sortInvert}, this.kickSort)
}

kickSort = () => {

	this.changeSort(this.state.sortedField)
}

changeSort = (value) => {

	if (value == "Yearly Cash Flow") {
		this.setState({sortedField : "Yearly Cash Flow"}, this.setSortParams("year_nom_cf", "float"))
	}
	if (value == "Debt Coverage Ratio") {
		this.setState({sortedField : "Debt Coverage Ratio"}, this.setSortParams("debt_cover_ratio", "float"))
	}
	if (value == "Sale List Price") {this.setState({sortedField : "Sale List Price"}, this.setSortParams("sale_list_price", "float"))}
	

}
setSortParams = (field, type) => {
	let records = []
	if (!this.state.sortInvert) {
		records = this.sortByKey(this.state.records, field, type, true)
	} else {
		records = this.sortByKey(this.state.records, field, type, false)
	}
 	this.setState({
  		records : records,
  	})
}

componentDidUpdate(prevProps, prevState) {	
	if (prevState.distance !== this.state.distance) {
		this.queryCategory()
	}
}

pageUp = (total_entries) => {
	const index = this.state.index
	const records_shown = this.state.records_shown
	if ((index + records_shown) < total_entries) {
		const new_index = index + records_shown
		const current_page = Math.floor(new_index / this.state.records_shown) + 1
		this.setState({ index : new_index,
			current_page : current_page,
			hit_bottom : false
		})
	} else {
		this.setState({ hit_top : true })
	}
}

pageDown = (total_entries) => {
	const index = this.state.index
	const records_shown = this.state.records_shown
	if ((index - records_shown) >= 0) {
		const new_index = index - records_shown
		const current_page =  Math.floor(new_index / this.state.records_shown) + 1
		this.setState({ index : new_index ,
			current_page : current_page,
			hit_top : false
		})
	} else {
		this.setState({ hit_bottom : true,
			index : 0		
		})
	}
}

changeItemCount = (value) => {
	const records_shown = parseInt(value)
	const paged_records = this.state.records.slice(this.state.index, this.state.index + records_shown)
	const total_pages = Math.floor(this.state.records.length / records_shown) + 1
	const current_page = Math.floor(this.state.index / records_shown) + 1
	this.setState({
		records_shown : records_shown, 
		paged_records : paged_records,
		current_page : current_page,
		total_pages : total_pages
	})
}

changeDistance = (value) => {
	this.setState({
		distance : parseInt(value)
	})
}

queryCategory = () => {
	this.setState({
		loaded : false
	})
	const queryParams = new URLSearchParams(window.location.search);
	console.log(queryParams)
	var code = ""
	if (queryParams.get("state")) {
		code = queryParams.get("state")
	}
	if (queryParams.get("logical_area")) {
		code = queryParams.get("logical_area")
	}
	this.setState({
			active_state : code
	})

	const param_dict = {
		"state" : code.toString(),
		"down_pmt" : this.state.down_pmt.toString(),
		"renovation" : this.state.renovation.toString(),
		"close_cost" : this.state.close_cost.toString(),
		"cost_of_sale" : this.state.cost_of_sale.toString(),
		"loan_apr" : this.state.loan_apr.toString(),
		"mgmt_fee" : this.state.mgmt_fee.toString(),
		"mnth_vacant" : this.state.mnth_vacant.toString(),
		"rent_annual_inc" : this.state.rent_annual_inc.toString(),
		"depreciation" : this.state.depreciation.toString(),
		"appreciation" : this.state.appreciation.toString(),
		"income_tax_rate" : this.state.income_tax_rate.toString(),
		"cap_gains_tax_rate" : this.state.cap_gains_tax_rate.toString(),
		"term" : this.state.term.toString(),
		"condo_fee" : this.state.condo_fee.toString(),
		"renovation_add" : this.state.renovation_add.toString(),
		"distance" : this.state.distance.toString(),
		
		"listing_status_active" : this.state.listing_status_active.toString(),
		"listing_status_coming_soon" : this.state.listing_status_coming_soon.toString(),
		//"listing_status_pending" : this.state.listing_status_pending,
		//"listing_status_withdrawn" : this.state.listing_status_withdrawn,
		"listing_status_active_under_contract" : this.state.listing_status_active_under_contract.toString(),
		//"listing_status_hold" : this.state.listing_status_hold,
		//"listing_status_closed" : this.state.listing_status_closed,
		//"listing_status_canceled" : this.state.listing_status_canceled,
		//"listing_status_expired" : this.state.listing_status_expired,
	}
	if (this.props.category == "city") {	
	    const city = queryParams.get("city")
	    param_dict["city"] = city
	}
	if (this.props.category == "county") {	
	    const county = queryParams.get("county")
	    param_dict["county"] = county
	}
	if (this.props.category == "zipcode") {
	    const prox_zip = queryParams.get("zipcode")
	    param_dict["zipcode"] = prox_zip
	}
	if (this.props.category == "logical_area") {	
	    const logical_area = queryParams.get("logical_area")
	    param_dict["logical_area"] = logical_area
	    delete param_dict["state"]
	}
  	axios.get("/api/query_category/", { 
		params : param_dict
  	}).then((response) => {
		const records = this.sortByKey(response.data.records, "year_nom_cf", "float", true)
    	this.setState({
        	records : records,
		loaded : true
        })
    })
}

resetValues = () => {
	this.setState({
		down_pmt: '20',
		renovation: '10',
		close_cost: '2',
		cost_of_sale: '4.5',
		loan_apr: '5',
		mgmt_fee: '0',
		mnth_vacant: '1',
		rent_annual_inc: '5',
		depreciation: '27.5',
		appreciation: '5',
		income_tax_rate: '30',
		cap_gains_tax_rate: '15',
		term: '30',
		condo_fee: '300',
		renovation_add: '70',
	})
}

initialize = () => {
	axios.get("/api/get_user_vars/", { 
		params : ""
  	}).then((response) => {
		const vars = response.data["var"]
        this.setState({
			down_pmt: vars["down_pmt"],
			renovation: vars["renovation"],
			close_cost: vars["close_cost"],
			cost_of_sale: vars["cost_of_sale"],
			loan_apr: vars["loan_apr"],
			mgmt_fee: vars["mgmt_fee"],
			mnth_vacant: vars["mnth_vacant"],
			rent_annual_inc: vars["rent_annual_inc"],
			depreciation: vars["depreciation"],
			appreciation: vars["appreciation"],
			income_tax_rate: vars["income_tax_rate"],
			cap_gains_tax_rate: vars["cap_gains_tax_rate"],
			term: vars["term"],
			condo_fee: vars["condo_fee"],
			renovation_add : vars["renovation_add"],
        }, this.queryCategory)
	})
}

componentDidMount() {
	this.initialize()
}

usdFormat = new Intl.NumberFormat('en-US', {
	style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

bedroom_plus_filter(listing) {
	const bedrooms = parseInt(listing["bedrooms"])
	const bed_plus = parseInt(this.state.active_bed_plus)
	if (bedrooms < bed_plus) {
		return false
	}
	return true
}
bathroom_plus_filter(listing) {
	const bathrooms = parseInt(listing["bathrooms"])
	const bath_plus = parseInt(this.state.active_bath_plus)
	if (bathrooms < bath_plus) {
		return false
	}
	return true
}
rent_accuracy_filter(listing) {
	let acc_rating = listing["rent_analysis_accuracy_rating"]["Rating"] 
	if (this.state.rent_acc_very_high) {
		if (acc_rating == "Very High") {
			return true
		}
	}
	if (this.state.rent_acc_high) {
		if (acc_rating == "High") {
			return true
		}
	}
	if (this.state.rent_acc_medium) {
		if (acc_rating == "Medium") {
			return true
		}
	}
	if (this.state.rent_acc_low) {
		if (acc_rating == "Low") {
			return true
		}
	}
	if (this.state.rent_acc_very_low) {
		if (acc_rating == "Very Low") {
			return true
		}
	}
	return false
}

search_filter(listing) {
	if (!this.state.search_text == "") {
		const search_text = this.state.search_text
		for (let field in listing) {
			if (typeof(listing[field]) == "string" && listing[field].includes(search_text)) {
				return true
			}
		}
		return false
	}
	return true
}

price_filter(listing) {

	if (this.state.low_price == "" || this.state.low_price == "0" || listing["sale_list_price"] >= parseInt(this.state.low_price)) {
		if (this.state.high_price == "" || this.state.high_price == "0" || listing["sale_list_price"] <= parseInt(this.state.high_price)) {
			return true
		}
		return false
	}
	return false
}
int_sqft_filter(listing) {

	if (this.state.low_int_sqft == "" || this.state.low_int_sqft == "0" || listing["int_sqft"] >= parseInt(this.state.low_int_sqft)) {
		if (this.state.high_int_sqft == "" || this.state.high_int_sqft == "0" || listing["sale_list_price"] <= parseInt(this.state.high_int_sqft)) {
			return true
		}
		return false
	}
	return false
}

status_filter(listing) {
	if (listing["status"] == "Inactive Account") {
		return true
	}
	if (this.state.listing_status_active) {
		if (listing["status"] == "Active") {
			return true
		}
	}
	
	if (this.state.listing_status_active_under_contract) {
		if (listing["status"] == "ActiveUnderContract") {
			return true
		}
	}
	
	if (this.state.listing_status_coming_soon) {
		if (listing["status"] == "ComingSoon") {
			return true
		}
	}
	return false
}

structure_filter(listing) {
	if (listing["structure_type"] == "Inactive Account") {
		return true
	}
	if (this.state.listing_structure_townhouse) {
		if (listing["structure_type"] == "Townhouse") {
			return true
		}
	}
	
	if (this.state.listing_structure_apartment_condo) {
		if (listing["structure_type"] == "Apartment") {
			return true
		}
	}
	
	if (this.state.listing_structure_detached) {
		if (listing["structure_type"] == "Detached") {
			return true
		}
	}
	if (this.state.listing_structure_semi_detached) {
		if (listing["structure_type"] == "Semi-Detached") {
			return true
		}
	}
	return false
}

load_address_details(prop_id, code) {
	this.setState({
		show_address_details : true,
		prop_id : prop_id,
		prop_id_code : code,

	})
}

return_function() {

	this.setState({
		show_address_details : false,
		prop_id : "",
		prop_id_code : "",
	})

}

render() {
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("state")
	const Checkbox = ({ label, value, onChange }) => {
  		return (
    		<label>
      		<input type="checkbox" checked={value} onChange={onChange} />
      		&nbsp;{label}
    		</label>
  		);
	};

	const userVarsInject = () => {
			return(
				<User_vars user_vars = {this.state}
					set_user_vars = {this.set_user_vars}
					submit_user_vars = {this.submit_user_vars}
					resetValues = {this.resetValues}
					hideUserVars = {this.hideUserVars}		
					stateShowUserVars = {this.state.showUserVars}
				/>
			)
	}

	const distance_inject = () => {
		if (this.state.records.length > 0) {
			if ("distance_away" in this.state.records[0]) {
				return(<th>Distance Away</th>)
			} else {
				return
			}
		}
	}

	const list_addr = this.state.records.filter(listing => this.search_filter(listing)).filter(listing => this.status_filter(listing)).filter(listing => this.structure_filter(listing)).filter(listing => this.price_filter(listing)).filter(listing => this.int_sqft_filter(listing)).filter(listing => this.rent_accuracy_filter(listing)).filter(listing => this.bedroom_plus_filter(listing)).filter(listing => this.bathroom_plus_filter(listing))
	const paged_addr = list_addr.slice(this.state.index, this.state.index + this.state.records_shown).map(x => {
		let listing_status = x["status"]
		if (listing_status == "ActiveUnderContract") {
			listing_status = "Under Contract"
		}
		const distance_away = () => {
			if ("distance_away" in x) {
				return(<td>{Number(x["distance_away"].toFixed(2))}&nbsp;Miles</td>)
			} else {
				return
			}
		}
		const prop_id = x["prop_id"]
		const address = x["address"]
		const code = x["code"]
		const city = x["city"]
		const county = x["county"]
		const zipcode = x["zipcode"]
		const raw_year_nom_cf = x["year_nom_cf"]
		const year_nom_cf = this.usdFormat.format(x["year_nom_cf"])
		const debt_cover_ratio = x["debt_cover_ratio"]
		const sale_list_price = this.usdFormat.format(x["sale_list_price"])
		const link_addr = "/address-details/?address=" + encodeURIComponent(address) + "&city=" + city + "&code=" + this.state.active_state
		const link_city = "/cities/citysearch/?state=" + code + "&city=" + city
		const link_county = "/counties/countysearch/?state=" + code + "&county=" + county
		const link_zipcode = "/zipcodes/zipcodesearch/?state=" + code + "&zipcode=" + zipcode
		const analysis_rating = x["rent_analysis_accuracy_rating"]["Rating"]

		return(	
	  		<tr>
				<td style={{color : listing_status == "Active" ? "green" : listing_status == "ComingSoon" ? "blue" : "black"}}>{listing_status}</td>
				<td style={{color : raw_year_nom_cf >= 0 ? "green" : "red" }}>{year_nom_cf}</td>
				<td>{analysis_rating}</td>
				<td>{debt_cover_ratio}</td>
				<td>{sale_list_price}</td>
				{distance_away()}
	 			<td><Button variant="link" onClick={() => this.load_address_details(prop_id, code)}>{address}</Button></td>
	  			<td><a href={link_city}>{city}</a></td>
	  			<td><a href={link_county}>{county}</a></td>
	  			<td><a href={link_zipcode}>{zipcode}</a></td>
	  		</tr>
		)
	})
	
	const loadedLogic = () => {
		if (!this.state.loaded) {
			return(
				<tr>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>	
				</tr>
			)
		} else {
			return(paged_addr)
		}
	}

	const distance_select_inject = () => {
	
		if (this.props.category == "zipcode") {
			return(
	
				<Col>
					<Dropdown as={ButtonGroup} title="Dropdown-items-shown" onSelect={this.changeDistance}>
						<Dropdown.Toggle variant="primary">
							{this.state.distance}&nbsp;&nbsp;Miles From
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item eventKey="0">0</Dropdown.Item>
							<Dropdown.Item eventKey="5">5</Dropdown.Item>
							<Dropdown.Item eventKey="10">10</Dropdown.Item>
							<Dropdown.Item eventKey="15">15</Dropdown.Item>
							<Dropdown.Item eventKey="20">20</Dropdown.Item>
							<Dropdown.Item eventKey="25">25</Dropdown.Item>
							<Dropdown.Item eventKey="30">30</Dropdown.Item>
							<Dropdown.Item eventKey="35">35</Dropdown.Item>
							<Dropdown.Item eventKey="40">40</Dropdown.Item>
							<Dropdown.Item eventKey="45">45</Dropdown.Item>
							<Dropdown.Item eventKey="50">50</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Col>
			)
		} else {

			return
		}

	}

	const filtersInject = () => {
		return(
			<Offcanvas show={this.state.showFilters} onHide={this.hideFilters}>
			<Offcanvas.Header closeButton>
			</Offcanvas.Header>
			<Offcanvas.Body style={{ minWidth: '150px', overflowY : "auto" }}>
			<div className='var-wrapper'>
			<Container>
			
			<Row>
				<Col>
					<h4>
						Rent Accuracy Rating
					</h4>
				</Col>
			</Row>
			<Row>
                                <Col>
                                        <Form>
                                        <Form.Label>Very High</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="rent_acc_very_high"
						checked={this.state.rent_acc_very_high}
						onChange={(e) => this.set_rent_accuracy_filter("rent_acc_very_high", e)}
					/>
                                </Col>
			</Row>
			<Row>
				<br/>
                                <Col>
                                        <Form>
                                        <Form.Label>High</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="rent_acc_high"
						checked={this.state.rent_acc_high}
						onChange={(e) => this.set_rent_accuracy_filter("rent_acc_high", e)}
					/>
                                </Col>
			</Row>
			<Row>
				<br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Medium</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="rent_acc_medium"
						checked={this.state.rent_acc_medium}
						onChange={(e) => this.set_rent_accuracy_filter("rent_acc_medium", e)}
					/>
                                </Col>
                        </Row>
			<Row>
                        <br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Low</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="rent_acc_low"
						checked={this.state.rent_acc_low}
						onChange={(e) => this.set_rent_accuracy_filter("rent_acc_low", e)}
					/>
                                </Col>
                        </Row>
			<Row>
                        <br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Very Low</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="rent_acc_very_low"
						checked={this.state.rent_acc_very_low}
						onChange={(e) => this.set_rent_accuracy_filter("rent_acc_very_low", e)}
					/>
                                </Col>
                        </Row>
                        <br/>
			<Row>
				<Col>
					<h4>
						Listing Status
					</h4>
				</Col>
			</Row>
			<Row>
                                <Col>
                                        <Form>
                                        <Form.Label>Active</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="listing_status_active"
						checked={this.state.listing_status_active}
						onChange={(e) => this.set_rent_accuracy_filter("listing_status_active", e)}
					/>
                                </Col>
			</Row>
			<Row>
				<br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Active Under Contract</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="listing_status_active_under_contract"
						checked={this.state.listing_status_active_under_contract}
						onChange={(e) => this.set_rent_accuracy_filter("listing_status_active_under_contract", e)}
					/>
                                </Col>
			</Row>
			<Row>
				<br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Coming Soon</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="listing_status_coming_soon"
						checked={this.state.listing_status_coming_soon}
						onChange={(e) => this.set_rent_accuracy_filter("listing_status_coming_soon", e)}
					/>
                                </Col>
                        </Row>
			<br/>
			<Row>
				<Col>
					<h4>
						Structure Type
					</h4>
				</Col>
			</Row>
			<Row>
				<br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Detached</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="listing_structure_detached"
						checked={this.state.listing_structure_detached}
						onChange={(e) => this.set_structure_type("listing_structure_detached", e)}
					/>
                                </Col>
                        </Row>
			<Row>
				<br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Semi-Detached</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="listing_structure_semi_detached"
						checked={this.state.listing_structure_semi_detached}
						onChange={(e) => this.set_structure_type("listing_structure_semi_detached", e)}
					/>
                                </Col>
                        </Row>
			<Row>
				<br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Townhouse</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="listing_structure_townhouse"
						checked={this.state.listing_structure_townhouse}
						onChange={(e) => this.set_structure_type("listing_structure_townhouse", e)}
					/>
                                </Col>
                        </Row>
			<Row>
				<br/>
                                <Col>
                                        <Form>
                                        <Form.Label>Condo/Apartment</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
                                        <Form.Check
						type="switch"
						id="listing_structure_apartment_condo"
						checked={this.state.listing_structure_apartment_condo}
						onChange={(e) => this.set_structure_type("listing_structure_apartment_condo", e)}
					/>
                                </Col>
                        </Row>
			<br/>
			<Row>
				<Col>
					<Button variant="dark" onClick={this.hideFilters}>Hide Filters</Button>
				</Col>
			</Row>

			</Container>
			</div>
			</Offcanvas.Body>
			</Offcanvas>
		)

	}
	
	const preferencesInject = () => {
		return(
			<Offcanvas show={this.state.showPreferences} onHide={this.hidePreferences}>
			<Offcanvas.Header closeButton>
			</Offcanvas.Header>
			<Offcanvas.Body style={{ minWidth: '150px', overflowY : "auto" }}>
			<div className='var-wrapper'>
			<Container>
			
			<Row>
				<Col>
					<h4>
						Price
					</h4>
				</Col>
			</Row>
			<Row>
                                <Col>
                                        <Form>
                                        <Form.Label>Min Price</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
					<InputGroup>
                                        <Form.Control name='low_price' id='low_price' value={this.state.low_price} onChange={(e) => this.set_price_limits(e)}/>
                                        </InputGroup>
                                </Col>
                        </Row>
                        <br/>
                        <Row>
                                <Col>
                                        <Form>
                                        <Form.Label>Max Price</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
					<InputGroup>
                                        <Form.Control name='high_price' id='high_price' value={this.state.high_price} onChange={(e) => this.set_price_limits(e)}/>
                                        </InputGroup>
                                </Col>
                        </Row>
			<Row>
				<Col>
					<h4>
						Interior SqFt
					</h4>
				</Col>
			</Row>
			<Row>
                                <Col>
                                        <Form>
                                        <Form.Label>Min SqFt</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
					<InputGroup>
                                        <Form.Control name='low_int_sqft' id='low_int_sqft' value={this.state.low_int_sqft} onChange={(e) => this.set_int_sqft_limits(e)}/>
                                        </InputGroup>
                                </Col>
                        </Row>
                        <br/>
                        <Row>
                                <Col>
                                        <Form>
                                        <Form.Label>Max SqFt</Form.Label>
                                        </Form>
                                </Col>
                                <Col>
					<InputGroup>
                                        <Form.Control name='high_int_sqft' id='high_int_sqft' value={this.state.high_int_sqft} onChange={(e) => this.set_int_sqft_limits(e)}/>
                                        </InputGroup>
                                </Col>
                        </Row>
			<br/>
			<Row>
				<Col>
					<h4>
						Bedrooms
					</h4>
				</Col>
			</Row>
			<Row>
                                <Col>
                                        <Form>
                                        <Form.Label>Min Bedrooms</Form.Label>
                                        </Form>
                                </Col>
			</Row>
			<Row>
                                <Col>
                                	<ButtonToolbar aria-label="Toolbar with button groups">
					      <ButtonGroup className="me-2" aria-label="First group">
						<Button variant={this.state.bed_0 ? "primary" : "dark"} onClick={() => this.set_bed_plus("0")}>0+</Button>
						<Button variant={this.state.bed_1 ? "primary" : "dark"} onClick={() => this.set_bed_plus("1")}>1+</Button>
						<Button variant={this.state.bed_2 ? "primary" : "dark"} onClick={() => this.set_bed_plus("2")}>2+</Button>
						<Button variant={this.state.bed_3 ? "primary" : "dark"} onClick={() => this.set_bed_plus("3")}>3+</Button>
						<Button variant={this.state.bed_4 ? "primary" : "dark"} onClick={() => this.set_bed_plus("4")}>4+</Button>
						<Button variant={this.state.bed_5 ? "primary" : "dark"} onClick={() => this.set_bed_plus("5")}>5+</Button>
					      </ButtonGroup>
					</ButtonToolbar>
				</Col>
                        </Row>
			<br/>
			<Row>
				<Col>
					<h4>
						Bathrooms
					</h4>
				</Col>
			</Row>
			<Row>
                                <Col>
                                        <Form>
                                        <Form.Label>Min Bathrooms</Form.Label>
                                        </Form>
                                </Col>
			</Row>
			<Row>
                                <Col>
                                	<ButtonToolbar aria-label="Toolbar with button groups">
					      <ButtonGroup className="me-2" aria-label="First group">
						<Button variant={this.state.bath_0 ? "primary" : "dark"} onClick={() => this.set_bath_plus("0")}>0+</Button>
						<Button variant={this.state.bath_1 ? "primary" : "dark"} onClick={() => this.set_bath_plus("1")}>1+</Button>
						<Button variant={this.state.bath_2 ? "primary" : "dark"} onClick={() => this.set_bath_plus("2")}>2+</Button>
						<Button variant={this.state.bath_3 ? "primary" : "dark"} onClick={() => this.set_bath_plus("3")}>3+</Button>
						<Button variant={this.state.bath_4 ? "primary" : "dark"} onClick={() => this.set_bath_plus("4")}>4+</Button>
						<Button variant={this.state.bath_5 ? "primary" : "dark"} onClick={() => this.set_bath_plus("5")}>5+</Button>
					      </ButtonGroup>
					</ButtonToolbar>
				</Col>
                        </Row>
			<br/>
			<Row>
				<Col>
					<Button variant="dark" onClick={this.hidePreferences}>Hide Preferences</Button>
				</Col>
			</Row>

			</Container>
			</div>
			</Offcanvas.Body>
			</Offcanvas>
		)

	}



	const inputStyle = {
    	//"font-size" : "10px",
    	//"height" : "100px",
      	"width" : "300px"
	}

	if (this.state.show_address_details) {

		//const address = this.state.active_address["Address"]
		const code = this.state.prop_id_code
		const prop_id = this.state.prop_id
		return (
			<>
			<AddressDetails prop_id={prop_id}
					code={code}
					return_function={() => this.return_function()}
					/>
			</>
		)
	} else {
	return (
		<div className='App'>
		<div className="table-wrapper">
                                <Card style={{width : "100%"}}>
                                    <Container fluid >
                                        <Row md="auto">
                                                <Col md={2}>
                                                        {list_addr.length}&nbsp;Listings Found<br/>
                                                </Col>
                                        </Row>
                                        <Row md="auto">
                                                <Col md={2}>
                                                        <Form>
                                                                <Form.Control
                                                                        type="search"
                                                                        placeholder="Search"
                                                                        className="me-2"
                                                                        aria-label="Search"
                                                                        value={this.state.search_text}
                                                                        onChange={this.set_search_text}
                                                                />
                                                        </Form>
                                                </Col>
                                                <Col>
							<Button variant="primary" onClick={this.showUserVars}>Show Inputs</Button>
							{filtersInject()}
                                                </Col>
                                                <Col>
							<Button variant="primary" onClick={this.showFilters}>Show Filters</Button>
							{userVarsInject()}
                                                </Col>
                                                <Col>
							<Button variant="primary" onClick={this.showPreferences}>Show Preferences</Button>
							{preferencesInject()}
                                                </Col>
						<Col>
                                                        <Dropdown as={ButtonGroup} title="Dropdown-items-shown" onSelect={this.changeSort}>
                                                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                                Sort By {this.state.sortedField}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                                <Dropdown.Item eventKey="Yearly Cash Flow">Yearly Cash Flow</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Debt Coverage Ratio">Debt Coverage Ratio</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Sale List Price">Sale List Price</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                        </Dropdown>
						</Col>
						<Col>
							<Form.Check
								type="switch"
								id="sortInvert"
								label="Invert Sort"
								value={this.state.sortInvert}
								onChange={this.setSortInvert}
							/>
                                                </Col>
                                        </Row>
                                    </Container>
                                </Card>
                        </div>


		<div className="table-wrapper">
			<Card style={{width : "100%"}}>
	  		<div>
	  			<a href={"/counties/?state=" + code}>Search By County</a>&nbsp;&nbsp;
	  			<a href={"/cities/?state=" + code}>Search By City</a>&nbsp;&nbsp;
	  			<a href={"/zipcodes/?state=" + code}>Search By Zipcode</a>
				<br/>
				<>  
				</>
	  			<Table responsive striped bordered hover>
					<tbody>
          				<tr>
							<th>Status</th>
	  						<th>Yearly Cash Flow</th>
							<th>Rent Analysis Rating</th>
							<th>Debt Coverage Ratio</th>
	  						<th>Sale List Price</th>
	  						<th>Address</th>
	  						<th>City</th>
	  						<th>County</th>
	  						<th>Zip Code</th>
	  					</tr>
	  					{loadedLogic()}
	  				</tbody>
	  			</Table>
      		</div>
		</Card>
		</div>
			<Button className='my-2' variant="dark" onClick= {() => {this.pageDown(list_addr.length)}}>Prev</Button>&nbsp;&nbsp;
			<Button className='my-2' variant="dark" onClick={() => {this.pageUp(list_addr.length)}}>Next</Button>&nbsp;&nbsp;
			<a>Page {this.state.current_page} of {Math.floor(list_addr.length / this.state.records_shown) + 1}</a>&nbsp;&nbsp;&nbsp;&nbsp;
	
						<Dropdown as={ButtonGroup} title="Dropdown-items-shown" onSelect={this.changeItemCount}>
						<Dropdown.Toggle className='my-2' variant="dark" id="dropdown-basic">
							{this.state.records_shown}&nbsp;&nbsp;Per Page
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item eventKey="20">20</Dropdown.Item>
							<Dropdown.Item eventKey="50">50</Dropdown.Item>
							<Dropdown.Item eventKey="100">100</Dropdown.Item>
						</Dropdown.Menu>
						</Dropdown>
		</div>
  	);
	}
	
	}
}

export default CategorySearch;
