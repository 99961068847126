import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { Modal, Table, Alert, Button, Container, Card, Row, Col, Form } from 'react-bootstrap'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from 'react-router-dom';

import navbar from '../../components/navbar'
import validator from 'validator';
import StripeForm from "../../components/stripe_form";
import "../../css/stripe.css";

import ScrollToTop from "react-scroll-to-top";

class Account extends Component {

	state = {
	    	email_address : "",
		first_name : "",
		last_name : "",
		member_status: "",
		member_type: "",
		is_customer: false,
		active_until: 0,
		renew_date: 0,
		email_marketing : false,
		email_all : false,
		existing_pass: "",
		new_pass_1: "",
		new_pass_2: "",
		passComplexError : false,
		passNoMatchError : false,
		passVerifyError : false,
		passUpdateSuccess : false,
		profileInfoUpdateSuccess : false,

		add_payment_method : false,
		subscription_payment_missing : false,
		payment_list : [],
		subscription_dict : {},
		cancel_subscription_modal : false,
		renew_subscription_modal : false,
		delete_payment_method_modal : false,
		payment_method_to_delete: "",
	}

componentDidMount() {
	
	axios.get("/api/user/get_account/", {
	    params: ""
  	
	}).then((response) => {
		let member_status = ""
		if (response.data["user"]["member_active"]) {
			member_status = "ACTIVE"
		} else {
			member_status = "INACTIVE"
		}
	    this.setState({
            	email_address : response.data["user"]["email_address"],
            	first_name : response.data["user"]["first_name"],
            	last_name : response.data["user"]["last_name"],
            	member_status : member_status,
            	member_type : response.data["user"]["member_type"],
            	email_marketing : response.data["user"]["email_marketing"],
            	email_all : response.data["user"]["email_all"],
		is_customer: response.data["user"]["is_customer"],
		active_until : response.data["user"]["active_until"],    
		renew_date : response.data["user"]["renew_date"],    
          })
	})
	
	axios.get("/api/retrieve_payment_methods/", {
	    params: ""
  	
	}).then((response) => {
	    this.setState({
            	payment_list : response.data["payment_list"]
		
          })
	
	})
	axios.get("/api/retrieve_subscriptions/", {
	    params: ""
  	
	}).then((response) => {
	    this.setState({
            	subscription_dict : response.data["subscription_dict"]
		
          })
	
	})
}

handleChange = (event) => {
  let nam = event.target.name;
  let val = event.target.value
  this.setState({
    [nam] : val
  })
}

handleSwitch = (event, email_type) => {
  if (email_type == "marketing") {
	if (!this.state.email_marketing) {
		this.setState({
			email_all : true,
			email_marketing : !this.state.email_marketing
		})
	} else {
		this.setState({
			email_marketing : !this.state.email_marketing
		})

  	}
  }
  else if (email_type == "all") {
	if (this.state.email_marketing && this.state.email_all) {
		this.setState({
			email_marketing : false,
			email_all : !this.state.email_all
		})
	} else {
		this.setState({
			email_all : !this.state.email_all
		})

  	}
	}
}

handleSubmitDetails = (event) => {

	event.preventDefault()
	this.setState({
		passComplexError : false,
		passNoMatchError : false,
		passUpdateSuccess : false,
		profileInfoUpdateSuccess : false,
		passVerifyError : false,
	})
	axios.post("/api/user/update_account_details/", {
		first_name : this.state.first_name,
		last_name : this.state.last_name,
		email_marketing : this.state.email_marketing.toString(),
		email_all : this.state.email_all.toString()
	
	}).then((response) => {
		if (response.data.code == "0") {
			this.setState({
				profileInfoUpdateSuccess : true
			})
		}
	})
	}

handleSubmitPassword = (event) => {
	event.preventDefault()
	this.setState({
		passComplexError : false,
		passNoMatchError : false,
		passUpdateSuccess : false,
		profileInfoUpdateSuccess : false,
		passVerifyError : false,
	})
	if (!this.validatePass(this.state.new_pass_1)) {
		this.setState({
			passComplexError : true
		})
		 window.scrollTo(0, 0)
		return
	} else {
		if (this.state.new_pass_1 != this.state.new_pass_2) {
			this.setState({
				passNoMatchError : true
			})
			 window.scrollTo(0, 0)
		}
		const credentials = {"existing_pass" : this.state.existing_pass,
					"new_pass_1" : this.state.new_pass_1,
					"new_pass_2" : this.state.new_pass_2}
		axios.post("/api/user/update_account_password/", credentials 
		).then((response) => {
			if (response.data.code == "5") {
				this.setState({
					passVerifyError : true
				})
			}
			if (response.data.code == "0") {
				this.setState({
					passUpdateSuccess : true
				})
			}
			
		})
	}

}
validatePass = (value) => {

	if (validator.isStrongPassword(value, {
		minLength: 8, minLowercase: 1,
		minUppercase: 1, minNumbers: 1, minSymbols: 1
	})) {
		return true
        } else {
                return false
        }
}

close_add_payment_method = () => {
	
	this.setState({
		add_payment_method : false
	}, this.componentDidMount())

}

open_add_payment_method = () => {

	this.setState({
		add_payment_method : true
	})
}

delete_method = (id) => {

	axios.post("/api/delete_payment_method/", {
	    params: {
		"payment_method_id" : id
	    }
  	
	}).then((response) => {
		this.setState({
			delete_payment_method_modal : false
		},
		this.componentDidMount())
	
	})
}

set_default_method = (id) => {

	axios.post("/api/set_default_method/", {
	    params: {
		"payment_method_id" : id
	    }
  	
	}).then((response) => {
		this.componentDidMount()
	
	})
}

create_new_subscription = (id) => {

	axios.post("/api/create_new_subscription/", {
	    params: {
	    }
  	
	}).then((response) => {
		console.log(response)
		if (response.data.code == 1) {
		this.setState({
			renew_subscription_modal : false,
			subscription_payment_missing : true
		})
		window.scrollTo(0, 0)
		} else {

		this.setState({
			renew_subscription_modal : false
		},
		this.componentDidMount())
		}
	})
}

open_cancel_subscription_modal = () => {

	this.setState({
		cancel_subscription_modal : true
	})

}

close_cancel_subscription_modal = () => {

	this.setState({
		cancel_subscription_modal : false
	})

}
open_renew_subscription_modal = () => {

	this.setState({
		renew_subscription_modal : true
	})

}

close_renew_subscription_modal = () => {

	this.setState({
		renew_subscription_modal : false
	})

}
open_delete_payment_method_modal = (id) => {

	this.setState({
		delete_payment_method_modal : true,
		payment_method_to_delete: id,
	})

}

close_delete_payment_method_modal = () => {

	this.setState({
		delete_payment_method_modal : false,
		payment_method_to_delete: "",
	})

}
cancel_subscription = (id) => {

	axios.post("/api/cancel_subscription/", {
	    params: {
	    }
  	
	}).then((response) => {
		this.close_cancel_subscription_modal()
		this.componentDidMount()
	
	})
}

usdFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

render() {

	 const subscription_payment_missing_error = () => {
                if (this.state.subscription_payment_missing) {
                return (
                        <>
                        <Alert variant="danger" onClose={() => this.setState({subscription_payment_missing : false})} dismissible >
                              <a>You need to add a payment method to activate a subscription</a>
                        </Alert>
                        <br/>
                        </>
          );
                }
        };

	const modal_inject = () => {
		let end_date = new Date(parseFloat(this.state.subscription_dict["end_date"]) * 1000)
                end_date = (end_date.getMonth() + 1).toString() + "/" + end_date.getDate().toString() + "/" + end_date.getFullYear().toString()

		if (this.state.cancel_subscription_modal) {
			return (
			<Modal show={this.state.cancel_subscription_modal} onHide={this.close_cancel_subscription_modal}>
                                <Modal.Header closeButton>
                                        <Modal.Title>Confirm Subscription Cancellation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                	<a>Are you sure you want to cancel your subscription?</a>
					<br/>
					<a>Your account will remain active until {end_date}.</a>
				</Modal.Body>
                                <Modal.Footer>

                                        <Button className='my-2' variant="success" onClick={() => this.close_cancel_subscription_modal()}>Keep My Subscription</Button>
                                        <Button className='my-2' variant="dark" onClick={() => this.cancel_subscription()}>Confirm Cancellation</Button>
                                </Modal.Footer>
                        </Modal>
			)
		}
		else if (this.state.renew_subscription_modal) {
			const subscription_amount = this.usdFormat.format(this.state.subscription_dict["amount"])
			const subscription_interval = this.state.subscription_dict["interval"]
			let renew_date = "Immediately"
			if (this.state.renew_date != "Immediately") {
				renew_date = new Date(parseFloat(this.state.renew_date) * 1000)
				renew_date = (renew_date.getMonth() + 1).toString() + "/" + renew_date.getDate().toString() + "/" + renew_date.getFullYear().toString()
				renew_date = " on " + renew_date.toString()
			}		
			return (
			<Modal show={this.state.renew_subscription_modal} onHide={this.close_renew_subscription_modal}>
                                <Modal.Header closeButton>
                                        <Modal.Title>Confirm Subscription Re-Activation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                	<a>Please Confirm Subscription Re-Activation</a>
					<br/>
					<a>You will be charged <b style={{color : "red"}}>{subscription_amount}</b> {renew_date}.</a>
				</Modal.Body>
                                <Modal.Footer>

                                        <Button className='my-2' variant="secondary" onClick={() => this.close_renew_subscription_modal()}>Cancel</Button>
                                        <Button className='my-2' variant="success" onClick={() => this.create_new_subscription()}>Re-Activate My Subscription</Button>
                                </Modal.Footer>
                        </Modal>
			)
		}
		else if (this.state.delete_payment_method_modal) {
			const id = this.state.payment_method_to_delete
			const last_payment_method = () => {
				if (this.state.payment_list.length == 1) {

					return (
						<>
						<p style={{ color : "red"}}><b>WARNING: You are about to remove your last payment method.</b></p>
						<p style={{ color : "red"}}><b>If you don't have a payment method on file, your subscription won't be able to renew.</b></p>
						</>
					)

				}
			}		
			return (
			<Modal show={this.state.delete_payment_method_modal} onHide={this.close_delete_payment_method_modal}>
                                <Modal.Header closeButton>
                                        <Modal.Title>Confirm Payment Method Deletion</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                	<a>Please Confirm Payment Method Deletion</a>
					<br/>
					{last_payment_method()}
				</Modal.Body>
                                <Modal.Footer>

                                        <Button className='my-2' variant="secondary" onClick={() => this.close_delete_payment_method_modal()}>Cancel</Button>
                                        <Button className='my-2' variant="danger" onClick={() => this.delete_method(id)}>Confirm Delete</Button>
                                </Modal.Footer>
                        </Modal>
			)
		}
	}

	const subscription_management_inject = () => {
		if (!this.state.is_customer) {
			return (
					<>
					<div className="card">
					<h1 className="account_details">Subscription Details</h1>
					<a>No Active Subscriptions Found</a>
					<Button as={Link} to="/user/subscribe" variant="warning">Subscribe</Button>
					</div>
					</>

			)


		} else {
			if (this.state.subscription_dict["status"] == "inactive") {
				const subscription_amount = this.usdFormat.format(this.state.subscription_dict["amount"])
				const subscription_interval = this.state.subscription_dict["interval"]
				const remaining_time_inject = () => {
					if (this.state.remaining_time != 0) {
						let end_date = new Date(parseFloat(this.state.active_until) * 1000)
                				end_date = (end_date.getMonth() + 1).toString() + "/" + end_date.getDate().toString() + "/" + end_date.getFullYear().toString()
						return (
							<>
							<br/>
							<a><b>Account active until {end_date}.</b></a>
							<a><b>Re-activate before then to prevent any interuption.</b></a>
							<br/>
							</>
						)
					}
				}

				return(
					<>
					<div className="card">
					<h1 className="account_details">Subscription Details</h1>
					<a>No Active Subscriptions Found</a>
					{remaining_time_inject()}
					<Button variant="success" onClick={() => this.open_renew_subscription_modal()}>Re-Activate Subscription</Button>
					<Row>
						<Col style={{ textAlign: 'left' }}>
							<a><b>Renewal Price:</b></a>
						</Col>
						<Col style={{ textAlign: 'left', color : "red" }}>
							<a><b>{subscription_amount} per {subscription_interval}</b></a>
						</Col>
					</Row>
					</div>
					</>
				)
			} else {
				const subscription_id = this.state.subscription_dict["id"]
				const subscription_amount = this.usdFormat.format(this.state.subscription_dict["amount"])
				const subscription_interval = this.state.subscription_dict["interval"]
				return(
					<>
					<div className="card">
					<h1 className="account_details">Subscription Details</h1>
					<Row>
						<Col style={{ textAlign: 'left' }}>
							<a><b>ID:</b></a>
						</Col>
						<Col style={{ textAlign: 'left' }}>
							<a>{subscription_id}</a>
						</Col>
					</Row>
					<Row>
						<Col style={{ textAlign: 'left' }}>
							<a><b>Details:</b></a>
						</Col>
						<Col style={{ textAlign: 'left' }}>
							<a>{subscription_amount} per {subscription_interval}</a>
						</Col>
					</Row>
					<br/>
					<br/>
					<Row>	
					<Button variant="dark" onClick={() => this.open_cancel_subscription_modal()}>Cancel Subscription</Button>
					</Row>
					</div>
					</>
				)
			}
		}
	}

	const payment_management_inject = () => {	
		if (!this.state.is_customer) {
			return (
					<>
					<div className="card">
					<h1 className="account_details">Payment Details</h1>
					<a>No Payment Methods Found</a>
					<Button as={Link} to="/user/subscribe" variant="warning">Subscribe</Button>
					</div>
					</>

			)
	
		} else {
			if (!this.state.add_payment_method) {
				const payment_table = this.state.payment_list.map((x) => {
					const id = x["id"]
					const last4 = x["last4"]
					const brand = x["brand"]
					const default_function_inject = () => {
						if (x["default"] == true) {
							return (
								<td>Default</td>

							)} else {
								return(
									<td><Button variant="primary" onClick={() => this.set_default_method(id)}>Set Default</Button></td>
								)
						}
					}
					return(
						<>
						<tr>
							<td>{brand}</td>
							<td>{last4}</td>
							{default_function_inject()}
							<td><Button variant="dark" onClick={() => this.open_delete_payment_method_modal(id)}>Delete</Button></td>
						</tr>
						</>
					)
				})
				return(
					<>
		
					<div className="card">
					    <h1>Payment Details</h1>
						<div>	
						<Table striped bordered hover>
							<thead></thead>
							<tbody>
							{payment_table}
							</tbody>

						</Table>
						</div>
				       
						<Button variant="primary" onClick={() => this.open_add_payment_method()}>
						Add New Payment Method
						</Button>
					</div>
					</>
				)
			} else {
				const stripePromise = loadStripe(this.props.stripe_api_key);
				const appearance = { theme : 'stripe' }
				const options = { "appearance": appearance }
				return (	
					<>
					<div className='table-wrapper'>
						<Card>
							<Elements stripe={stripePromise} options={options}>
								<StripeForm closeForm={() => this.close_add_payment_method()}/>
							</Elements>
						</Card>
					</div>
					</>
				)
			}

		}
	}

	const showPassComplexError = () => {	
		if (this.state.passComplexError) {
			return(
				<>
				 <Alert variant="danger" onClose={() => this.setState({passComplexError : false})} dismissible >
                              <a>New Password Does Not Meet Complexity Requirements:</a>
                              <ul>
                                <li>Minimum 8 Characters</li>
                                <li>At Least 1 Uppercase Letter</li>
                                <li>At Least 1 Lowercase Letter</li>
                                <li>At Least 1 Number</li>
                                <li>At Least 1 Symbol</li>
                             </ul>

                        </Alert>
                        <br/>
				</>
			)
		}
	}

	const showPassNoMatchError = () => {	
		if (this.state.passNoMatchError) {
			return(
				<>
				 <Alert variant="danger" onClose={() => this.setState({passNoMatchError : false})} dismissible >
                              <a>New Passwords Do Not Match</a>

                        </Alert>
                        <br/>
				</>
			)
		}
	}
	const showPassVerifyError = () => {	
		if (this.state.passVerifyError) {
			return(
				<>
				 <Alert variant="danger" onClose={() => this.setState({passVerifyError : false})} dismissible >
                              <a>Existing Password Does Not Match</a>

                        </Alert>
                        <br/>
				</>
			)
		}
	}
	
	const showPassUpdateSuccess = () => {	
		if (this.state.passUpdateSuccess) {
			return(
				<>
				 <Alert variant="success" onClose={() => this.setState({passUpdateSuccess : false})} dismissible >
                              <a>Password Updated Successfully</a>

                        </Alert>
                        <br/>
				</>
			)
		}
	}
	
	const showProfileInfoUpdateSuccess = () => {	
		if (this.state.profileInfoUpdateSuccess) {
			return(
				<>
				 <Alert variant="success" onClose={() => this.setState({profileInfoUpdateSuccess : false})} dismissible >
                              <a>Profile Information Updated Successfully</a>

                        </Alert>
                        <br/>
				</>
			)
		}
	}

  return (
	<>
  {subscription_payment_missing_error()}
  {showPassComplexError()}
  {showPassNoMatchError()}
  {showPassVerifyError()}
  {showPassUpdateSuccess()}
  {showProfileInfoUpdateSuccess()}
  {modal_inject()}
  <div className="card-wrapper">

          <div className="card">
            <h1 className="account_details">Account Details</h1>

            <Form name="signup_form" onSubmit={this.handleSubmitDetails}>
              {/* Labels and inputs for form data */}
                <label className="label">Email</label>
                <input name="email_address" className="field" value={this.state.email_address} type="email" disabled />

                <label className="label">First Name</label>
                <input name="first_name" onChange={this.handleChange} className="field" value={this.state.first_name} type="text" />

                <label className="label">Last Name</label>
                <input name="last_name" onChange={this.handleChange} className="field" value={this.state.last_name} type="text" />

                <label className="label">Membership Type</label>
                <input name="member_type" className="field" value={this.state.member_type} type="text" disabled/>
                
	  	<label className="label">Membership Status</label>
                <input name="member_status" className="field" value={this.state.member_status} type="text" disabled/>
       
		<Form.Check 
			type="switch"
			id="email_marketing"
			label="Email Marketing"
	  		checked={this.state.email_marketing}
	  		onChange={(e) => this.handleSwitch(e, "marketing")}
		      />
		      <Form.Check 
			type="switch"
			id="email_all"
			label="All Emails"
	  		checked={this.state.email_all}
	  		onChange={(e) => this.handleSwitch(e, "all")}
		      />

	  	<Button  className="btn" type="submit">
                Update Details
                </Button>
            </Form>
        </div>
          <div className="card">
            <h1 className="account_details">Change Password</h1>

            <form name="signup_form" onSubmit={this.handleSubmitPassword}>
              {/* Labels and inputs for form data */}
	  	<label className="label">Enter Existing Password</label>
                <input name="existing_pass" onChange={this.handleChange} className="field" value={this.state.existing_pass} type="password" />
                
	  	<label className="label">Enter New Password</label>
                <input name="new_pass_1" onChange={this.handleChange} className="field" value={this.state.new_pass_1} type="password" />
	  	 <Row>
                        <Col>
                          <Form.Label>Min 8 Characters</Form.Label>
                        </Col>
                        <Col>
                          <Form.Label>Min 1 Uppercase</Form.Label>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                          <Form.Label>Min 1 Lowercase</Form.Label>
                        </Col>
                        <Col>
                          <Form.Label>Min 1 Number</Form.Label>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                          <Form.Label>Min 1 Symbol</Form.Label>
                        </Col>
                        <Col>
                        </Col>
                        </Row>

	  	<label className="label">Confirm New Password</label>
                <input name="new_pass_2" onChange={this.handleChange} className="field" value={this.state.new_pass_2} type="password" />
	  	<Button  type="submit" className="btn">
                Update Password
                </Button>

            </form>
        </div>
	  {subscription_management_inject()}
	  {payment_management_inject()}

          </div>
</>
  );
}
}

export default Account;
