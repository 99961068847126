import React, { useState, useEffect } from 'react';
import '../css/styles.css'
import '../css/error.css'
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import validator from 'validator';
import ScrollToTop from "react-scroll-to-top";

export default function EmailUnsubscribe() {
	
	const queryParams = new URLSearchParams(window.location.search);
	const [validated, setValidated] = useState(false);
	const [email_all, setEmailAll] = useState(false);
	const [success_message, setSuccessMessage] = useState(false);

	const user_id = queryParams.get("user_id")

	const handleEmailAll = (e) => {
        	setEmailAll(true);
        };
	
	const handleEmailMarketing = (e) => {
        	setEmailAll(false);
        };

	// Handling the form submission
	const handleSubmit = (event) => {
	const form = event.currentTarget;	
	event.preventDefault();
	event.stopPropagation();
	let email_type = "marketing"
	if (email_all) {
		email_type = "all"
	}
	axios.post("/api/user/email_unsubscribe/", { 
		    user_id : user_id,
		    email_type : email_type,
	}).then((response) => {
		if (response.status == 200) {
			if (response.data.code == 0) {
				setSuccessMessage(true)
				if ("redirect" in response.data) {
					window.location = response.data["redirect"]
				}
			}
			else if (response.data.code == 1) {
				console.log("Error")
			}
		} else {
			console.log("Error")
		
		}
		})
      	}

	// Showing success message
	const successMessageBanner = () => {
		if (success_message) {
		  return (
			<>
			<Alert variant="success">
				<a>Email Preferences Updated</a>
			</Alert>
			<br/>
			</>

		  );
		}
	};
	return (
		<>
		{successMessageBanner()}
		<div className="card-wrapper">
		  <div className="card">

		<b>Unsubscribe Marketing:</b> You will still receive essential information (Non-marketing).
		<br/><br/>
		<b>Unsubscribe All:</b> You will no longer recieve any more emails INCLUDING password reset emails (contact support if you get locked out). You WILL receive emails that we are required to send you by law.
		<br/><br/>
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
		      <Row>
			<Form.Check 
			    type={"radio"}
			    id={"email_marketing"}
			    label="Unsubscribe from Marketing Emails"
			    checked={!email_all}
			    onChange={() => handleEmailMarketing()}
			  />
		      </Row>
		      <Row>
			<Form.Check 
			    type={"radio"}
			    id={"email_all"}
			    label={"Unsubscribe from All Emails"}
			    checked={email_all}
			    onChange={() => handleEmailAll()}
			  />
		     </Row>
			<br/>
		      <Button variant="danger" type="submit">Submit Email Preference</Button>
		    </Form>
	</div>

	  </div>
		</>
	);
}
