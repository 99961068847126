import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { Form, Button, Container, Card, Row, Col, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import User_vars from '../../components/user_vars'
import navbar from '../../components/navbar'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

class ManageUsers extends Component
{
		state = {
		total_entries: 0,
		total_pages: 0,
		current_page: 1,
		records_shown: 20,
		index: 0,
		hit_top : false,
		hit_bottom : false,
		
		sortProfitChecked: false,
		records: [],
		all_records : ["test"],
		active_state: "",
		users : [],
		search_text: "",
		csrf_token: ""
		}

set_search_text = (event) => {
    let search_text = event.target.name;
    let val = event.target.value
    this.setState({
      search_text: val
  })
}

submit_user_vars = () => {
	const param_dict = {
		"down_pmt" : this.state.down_pmt,
		"renovation" : this.state.renovation,
		"close_cost" : this.state.close_cost,
		"cost_of_sale" : this.state.cost_of_sale,
		"loan_apr" : this.state.loan_apr,
		"mgmt_fee" : this.state.mgmt_fee,
		"mnth_vacant" : this.state.mnth_vacant,
		"rent_annual_inc" : this.state.rent_annual_inc,
		"depreciation" : this.state.depreciation,
		"income_tax_rate" : this.state.income_tax_rate,
		"cap_gains_tax_rate" : this.state.cap_gains_tax_rate,
		"term" : this.state.term,
		"records_shown" : this.state.records_shown,
		"index" : this.state.index,
		"sortProfitChecked" : this.state.sortProfitChecked
	}
  axios.post("/api/save_user_vars/", { 
	  params : param_dict
  }).then(() => {
	this.queryUsers()
  })
}

setSortProfit = () => {
  this.setState({
	  "sortProfitChecked" : !this.state.sortProfitChecked
  })
}

//componentDidUpdate(prevProps, prevState) {
//	if (prevState.sortProfitChecked !== this.state.sortProfitChecked) {
//		this.queryCategory()
//	};
//	if (prevState.index !== this.state.index) {
//		this.queryCategory()
//	}
//	if (prevState.records_shown !== this.state.records_shown) {
//		this.queryCategory()
//	}
//	if (prevState.distance !== this.state.distance) {
//		this.queryCategory()
//	}
//	
//}

pageUp = (total_entries) => {
        const index = this.state.index
        const records_shown = this.state.records_shown
        if ((index + records_shown) < total_entries) {
                const new_index = index + records_shown
                const current_page =  Math.floor(new_index / this.state.records_shown) + 1
                this.setState({ index : new_index,
                                current_page : current_page,
                                hit_bottom : false
                })
        } else {
                console.log(true)
                this.setState({ hit_top : true })
        }
}

pageDown = (total_entries) => {
        const index = this.state.index
        const records_shown = this.state.records_shown
        if ((index - records_shown) >= 0) {
                const new_index = index - records_shown
                const current_page =  Math.floor(new_index / this.state.records_shown) + 1
                this.setState({ index : new_index ,
                                current_page : current_page,
                                hit_top : false
                                })
        } else {
                this.setState({ hit_bottom : true,
                                index : 0
                })
        }
}

changeItemCount = (value) => {

        const records_shown = parseInt(value)
        const paged_records = this.state.records.slice(this.state.index, this.state.index + records_shown)
        const total_pages = Math.floor(this.state.records.length / records_shown) + 1
        const current_page = Math.floor(this.state.index / records_shown) + 1
        this.setState({
                records_shown : records_shown,
                paged_records : paged_records,
                current_page : current_page,
                total_pages : total_pages,
        })
}

sortByKey(array, key, parse, rev) {
        let arr = array.sort(function(a, b) {
                if (parse == "float") {
                        var x = parseFloat(a[key]); var y = parseFloat(b[key]);
                } else {
                        var x = a[key]; var y = b[key];
                }
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        })
        if (rev) {
                return arr.reverse();
        } else {
                return arr
        }
}


searchRecords = (value) => {

	if (value == "") {

		this.setState({
			
		})
	}
	this.setState({
		distance : parseInt(value)
	})
}
	
queryUsers = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const param_dict = {
		"sortProfitChecked" : this.state.sortProfitChecked
	}
	if (this.props.category == "city") {	
	    const city = queryParams.get("city")
	    param_dict["city"] = city
	}
	if (this.props.category == "county") {	
	    const county = queryParams.get("county")
	    param_dict["county"] = county
	}
	if (this.props.category == "zipcode") {
	    const prox_zip = queryParams.get("zipcode")
	    param_dict["zipcode"] = prox_zip
	}

  axios.get("/api/admin_retrieve_users/", { 
	  params : param_dict
  }
  )
      .then((response) => {
          this.setState({
              users : response.data.users,
		csrf_token : response.data.csrf_token
		//total_entries : response.data.total_entries,
		 // total_pages : response.data.total_pages,
		 // current_page : response.data.current_page
          })
	  console.log(response.data)
      })
}

initialize() {

	this.queryUsers()
      
}

componentDidMount() {
	this.initialize()	

}

usdFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
})

search_filter(user) {
	if (!this.state.search_text == "") {
		const search_text = this.state.search_text
		for (let field in user) {
			if (typeof(user[field]) == "string" && user[field].includes(search_text)) {
				return true
			}
		}
		return false
	}
	return true
}

render() {

	const queryParams = new URLSearchParams(window.location.search);
	const Checkbox = ({ label, value, onChange }) => {
  		return (
    		<label>
      		<input type="checkbox" checked={value} onChange={onChange} />
      		&nbsp;{label}
    		</label>
  		);
	};

	const list_accounts = this.state.users.filter(user => this.search_filter(user))

	const paged_accounts = list_accounts.slice(this.state.index, this.state.index + this.state.records_shown).map(x => {
		
		const account_link = "/admin/account-control/?account=" + x["_id"]
		const email_address = x["email_address"]
		const first_name = x["first_name"]
		const last_name = x["last_name"]
		let member_status = x["member_active"]
		if (member_status) {
			member_status = "ACTIVE"
		} else {
			member_status = "INACTIVE"
		}
		const member_type = x["member_type"]
		const user_type = x["user_type"]
		
		return(	
	  		<tr>
				<td><a href={account_link}>{email_address}</a></td>
				<td>{first_name}</td>
				<td>{last_name}</td>
				<td style={{color : member_status == "ACTIVE" ? "green" : "red" }} >{member_status}</td>
				<td>{member_type}</td>
				<td>{user_type}</td>
	  		</tr>
		)
	})

const inputStyle = {
      //"font-size" : "10px",
      //"height" : "100px",
      "width" : "300px"
}

  return (
	<div className='App'>
	<Card style={{"width" : "100%"}}>
	  <Container fluid >
	  <Row md="auto">
	  <Col md={2}>
	  {list_accounts.length}&nbsp;Users Found <br/>
	  </Col>
	  </Row>
	  <Row md="auto">
	  <Col md={2}>
	  <style>{"table{ border:1px solid black; width:100%; table-layout:fixed;}"}</style>
		
		<Form>
			<Form.Control
				type="search"
				placeholder="Search"
				className="me-2"
				aria-label="Search"
				value={this.state.search_text}
				onChange={this.set_search_text}
			/>
		</Form>

	</Col>
	<Col>
	<Dropdown
		as={ButtonGroup}	
		title="Dropdown-items-shown"
		onSelect={this.changeItemCount}>
		<Dropdown.Toggle variant="success" id="dropdown-basic">
		{this.state.records_shown}&nbsp;&nbsp;Per Page
      		</Dropdown.Toggle>
      		<Dropdown.Menu>
        		<Dropdown.Item eventKey="20">20</Dropdown.Item>
        		<Dropdown.Item eventKey="50">50</Dropdown.Item>
        		<Dropdown.Item eventKey="100">100</Dropdown.Item>
      		</Dropdown.Menu>
    	</Dropdown>&nbsp;&nbsp;
	</Col>
	</Row>
	</Container>
	</Card>
	<div className="table-wrapper">
	<Card style={{"width" : "100%"}}>
	<br/>
	<>  
	</>
	   <Table striped bordered>
		<tbody>
          	<tr>
			<th>Email Address</th>
			<th>First Name</th>
	  		<th>Last Name</th>
	  		<th>Member Status</th>
	  		<th>Member Type</th>
	  		<th>User Type</th>
	  	</tr>
	  {paged_accounts}
	  </tbody>
	  </Table>
	</Card>
      	</div>
		<Button className='my-2' variant="primary" onClick={() => {this.pageDown(list_accounts.length)}}>Prev</Button>&nbsp;&nbsp;
		<Button className='my-2' variant="primary" onClick={() => {this.pageUp(list_accounts.length)}}>Next</Button>&nbsp;&nbsp;
		<a>Page {this.state.current_page} of {Math.floor(list_accounts.length / this.state.records_shown) + 1}</a>
	  </div>
  );
}
}

export default ManageUsers;
