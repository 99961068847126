import React, { useState } from 'react';
import '../css/styles.css'
import '../css/error.css'
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import validator from 'validator';
import ScrollToTop from "react-scroll-to-top";

export default function ForgotPassword() {
	
	const [validated, setValidated] = useState(false);
	const [email_address, setEmailAddress] = useState('');
	const [successMessage, setSuccessMessage] = useState(false);
	const [email_registration_error, setEmailRegistrationError] = useState(false);

	const handleEmailAddress = (e) => {
          setEmailAddress(e.target.value);
        };

	// Handling the form submission
	const handleSubmit = (event) => {
	setSuccessMessage(false)
	setEmailRegistrationError(false)
	const form = event.currentTarget;	
	      event.preventDefault();
		event.stopPropagation();
	if (form.checkValidity() === false) {
	setValidated(true)
	} else {
	    axios.post("/api/user/forgot_password/", { 
		    email_address : email_address,
		})
      		.then((response) => {
          		if (response.status == 200) {	
				if (response.data.code == 0) {
					setSuccessMessage(true)
					if ("redirect" in response.data) {
						window.location = response.data["redirect"]
					}
				}
				else if (response.data.code == 1) {
					setEmailRegistrationError(true)
					window.scrollTo(0, 0)
				}
			} else {
				console.log("Error")
          		
			}
			})
      		}
	}

	

	// Showing success message
	const successMessageBanner = () => {
		if (successMessage) {
		  return (
			<>
			<Alert variant="success">
				<a>Recovery Email Sent</a>
			</Alert>
			<br/>
			</>

		  );
		}
	};

	const emailRegistrationErrorMessage = () => {
		if (email_registration_error) {
		return (
			<>
			<Alert variant="danger" onClose={() => setEmailRegistrationError(false)} dismissible >
			      <a>An error has occured.</a>
			</Alert>
			<br/>
			</>
	  );
		}
	};
	if (successMessage) {

		return (
			<>
			<a>Thank you, please check your email inbox for a password reset email (including spam and promotions folder).</a>
			</>
		);
	} else {
		return (
			<>
			{successMessageBanner}
			{emailRegistrationErrorMessage()}
			<div className="card-wrapper">
			  <div className="card">
			
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
			      <Row>
				<Form.Group controlId="email_address">
				  <Form.Label>Please Enter A Valid Email Address</Form.Label>
				  <InputGroup hasValidation>
				    <Form.Control
				      onChange={handleEmailAddress}
				      type="email"
				     
				      required
				    />
				    <Form.Control.Feedback type="invalid">
				      Please enter your email address
				    </Form.Control.Feedback>
				  </InputGroup>
				</Form.Group>
			      </Row>
			<br/>
			      <Button type="submit">Reset Password</Button>
			    </Form>
		</div>

		  </div>
			</>
		);
	}
}
