import { React, useState, useEffect } from 'react';
import '../css/styles.css'
import '../css/error.css'
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import validator from 'validator';
import ScrollToTop from "react-scroll-to-top";

export default function ResetPassword() {
	
	const queryParams = new URLSearchParams(window.location.search);
	console.log(window.location.search)
	const [validated, setValidated] = useState(false);
	const [password_1, setPassword1] = useState('');
	const [password_2, setPassword2] = useState('');
	const [password_reset_form, setPasswordResetForm] = useState(false)
	const [time_valid_error, setTimeValidError] = useState(false)
	const [pass_complex_error, setPassComplexError] = useState(false);
	const [pass_match_error, setPassMatchError] = useState(false);
	const [email_registration_error, setEmailRegistrationError] = useState(false);
	const [success_message, setSuccessMessage] = useState(false);

	const token = queryParams.get("token")
	const user_id = queryParams.get("user_id")
	useEffect(() => {
		
		axios.post("/api/user/validate_password_reset_token/", {
                    user_id : user_id,
                    token : token,
                })
                .then((response) => {
                        if (response.status == 200) {
				if (response.data.code == 0) {
					setPasswordResetForm(true)
				}
				else if (response.data.code == 1) {
                                        setTimeValidError(true)
                                }
                        } else {
                                console.log("Error")

                        }
        	})
	}, [])

	const handlePassword1 = (e) => {
          setPassword1(e.target.value);
        };

	const handlePassword2 = (e) => {
          setPassword2(e.target.value);
        };

	const validatePass = (value) => {

		if (validator.isStrongPassword(value, {
			minLength: 8, minLowercase: 1,
			minUppercase: 1, minNumbers: 1, minSymbols: 1
		})) {
			return true
	} else {
		return false
	}
	}

	// Handling the form submission
	const handleSubmit = (event) => {
	setPassComplexError(false)
	setTimeValidError(false)
	const form = event.currentTarget;	
	      event.preventDefault();
		event.stopPropagation();
	if (form.checkValidity() === false) {
	setValidated(true)
	} else {
	    if (!validatePass(password_1)) {
		setPassComplexError(true)
	    } else {
		if (password_1 != password_2) {
			setPassMatchError(true)
		} else {
	    		axios.post("/api/user/commit_password_reset/", { 
			    user_id : user_id,
			    token : token,
			    password_1 : password_1,
			    password_2 : password_2,
		})
      		.then((response) => {
          		if (response.status == 200) {
				if (response.data.code == 0) {
					setSuccessMessage(true)
					if ("redirect" in response.data) {
						window.location = response.data["redirect"]
					}
				}
				else if (response.data.code == 1) {
					setTimeValidError(true)
				}
			} else {
				console.log("Error")
          		
			}
			})
      		}
	    }
	}
	}

	

	// Showing success message
	const successMessageBanner = () => {
		if (success_message) {
		  return (
			<>
			<Alert variant="success">
				<a>Signup Successful</a>
			</Alert>
			<br/>
			</>

		  );
		}
	};

	// Showing error message if error is true
	const passComplexErrorMessage = () => {
		if (pass_complex_error) {
		return (
			<>
			<Alert variant="danger" onClose={() => setPassComplexError(false)} dismissible >
			      <a>Password Does Not Meet Complexity Requirements:</a>
			      <ul>
				<li>Minimum 8 Characters</li>
				<li>At Least 1 Uppercase Letter</li>
				<li>At Least 1 Lowercase Letter</li>
				<li>At Least 1 Number</li>
				<li>At Least 1 Symbol</li>
			     </ul>
				
			</Alert>
			<br/>
			</>
	  );
		}
	};
	const passMatchErrorMessage = () => {
		if (pass_match_error) {
		return (
			<>
			<Alert variant="danger" onClose={() => setPassMatchError(false)} dismissible >
			      <a>Passwords Do Not Match.</a>
			</Alert>
			<br/>
			</>
	  );
		}
	};
	const timeValidErrorMessage = () => {
		if (time_valid_error) {
		return (
			<>
			<Alert variant="danger" onClose={() => setTimeValidError(false)} dismissible >
			      <a>Link is has expired, please reset again.</a>
			</Alert>
			<br/>
			</>
	  );
		}
	};

	if (!password_reset_form) {
		return (

			<>
			<a>Link has expired, please resubmit password reset form. </a>
			</>
		)
	} else {
		return (
			<>
			{passComplexErrorMessage()}
			{passMatchErrorMessage()}
			{timeValidErrorMessage()}
			{successMessageBanner()}
			<div className="card-wrapper">
			  <div className="card">
			
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
			      <Row>
				<Form.Group controlId="password_1">
				  <Form.Label>Enter Password</Form.Label>
				  <Form.Control type="password" 
				
				      onChange={handlePassword1} required />
				  <Form.Control.Feedback type="invalid">
				    Please set a password.
				  </Form.Control.Feedback>
				</Form.Group>
				</Row>
			      <Row>
				<Form.Group controlId="password_2">
				  <Form.Label>Retype Password</Form.Label>
				  <Form.Control type="password" 
				
				      onChange={handlePassword2}
				 required />
				  <Form.Control.Feedback type="invalid">
				    Please set a password.
				  </Form.Control.Feedback>
				</Form.Group>
				</Row>

				<Row>
				<Col>
				  <Form.Label>Min 8 Characters</Form.Label>
				</Col>
				<Col>
				  <Form.Label>Min 1 Uppercase</Form.Label>
				</Col>
				</Row>
				<Row>
				<Col>
				  <Form.Label>Min 1 Lowercase</Form.Label>
				</Col>
				<Col>
				  <Form.Label>Min 1 Number</Form.Label>
				</Col>
				</Row>
				<Row>
				<Col>
				  <Form.Label>Min 1 Symbol</Form.Label>
				</Col>
				<Col>
				</Col>
				</Row>
				  
				<br/>
			      <Button type="submit">Change Password</Button>
			    </Form>
		</div>

		  </div>
			</>
		);
	}
}
