import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const PaymentSuccess = () => {
  return (
    <div>
      <h1>Payment Success!</h1>
      <p>Your payment has been processed successfully.</p>
      <p>Thank you for your purchase! An email receipt has been sent to your inbox.</p>
     <div className="table-wrapper"> 
      <Card>
        <Card.Body>
          <Card.Title>Get started now</Card.Title>
          <Card.Text>
            Click the button below to get started using CFRE-AI to exploring listings.
          </Card.Text>
          <Button as={Link} to="/states" variant="primary">Click here to get started</Button>
        </Card.Body>
      </Card>
	</div>
    </div>
  );
};

export default PaymentSuccess;

