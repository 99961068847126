import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";


function PrivacyPolicy() {
  return (
    <div className="table-wrapper">
          <div style={{ textAlign: "left"}}>
          <Card style={{"width" : "100%"}}>
            <Card.Header className="text-center" as="h3">Privacy Policy</Card.Header>
            <Card.Body>

      <p>
        This Privacy Policy describes how Your Skyline LLC ("CFRE-AI", "we",
        "us", "our") collects, uses, and discloses personal information when you
        use our website located at cashflowrealestate.ai (the "Website").
      </p>
      <ol>
        <li>
          <p>Information We Collect
            We may collect personal information from you when you register for
            an account, use our website, and interact with our customer support
            team. The personal information we may collect includes:
          </p>
          <ul>
            <li>
              <p>Contact information such as name, email address, and phone
              number</p>
            </li>
            <li>
              <p>Billing information such as credit card number and billing
              address</p>
            </li>
            <li>
              <p>Property information such as location and investment
              criteria</p>
            </li>
            <li>
              <p>Information about your use of our website, including your IP
              address, browser type, and operating system</p>
            </li>
          </ul>
          <p>
            We may also collect information about your use of our website
            through cookies and other tracking technologies. This information
            may be used to personalize your experience, analyze usage of our
            website, and serve targeted advertising.
          </p>
        </li>
        <li>
          <p>Use of Information
            We use your personal information to provide and improve our
            services, process transactions, and communicate with you. We may
            also use your information to personalize your experience, send you
            promotional offers, and conduct market research.
          </p>
          <p>
            We may use cookies and other tracking technologies to collect
            information about your use of our website and to serve targeted
            advertising to you.
          </p>
        </li>
        <li>
          <p>Disclosure of Information
            We may share your personal information with third-party service
            providers who assist us in providing our services, such as payment
            processors and marketing providers. We may also share your
            information with our affiliates and business partners for marketing
            and business purposes.
          </p>
          <p>
            We may use cookies and other tracking technologies to serve
            targeted advertising on our website and on third-party websites.
            We may also share your information with third-party advertising
            partners for the purpose of serving targeted advertising to you.
          </p>
          <p>
            We will not sell or rent your personal information to third parties
            for their marketing purposes without your consent.
          </p>
        </li>
        <li>
          <p>Data Security
            We take appropriate measures to protect your personal information
            from unauthorized access, disclosure, and misuse. We use
            industry-standard encryption and security technologies to protect
            your information.
          </p>
          <p>
            However, no method of transmission over the internet or electronic
            storage is completely secure, so we cannot guarantee the absolute
            security of your information.
          </p>
        </li>
       <li>
          <p>Third-Party Websites
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or the content of third-party
            websites. We encourage you to review the privacy policies of these
            websites before providing them with your personal information.
          </p>
        </li>
        <li>
          <p>Children's Privacy
            Our website is not intended for children under the age of 13. We do
            not knowingly collect personal information from children under the
            age of 13. If you are a parent or guardian of a child under the age
            of 13 and believe that your child has provided us with personal
            information, please contact us at [support@cashflowrealestate.ai]
            and we will delete the information from our system.
          </p>
        </li>
        <li>
          <p>Changes to Privacy Policy
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by email or by posting a notice on our website. Your continued use
            of our website after the effective date of the updated Privacy
            Policy constitutes your acceptance of the updated Privacy Policy.
          </p>
        </li>
        <li>
          <p>Contact Us
            If you have any questions or concerns about this Privacy Policy,
            please contact us at [support@cashflowrealestate.ai].
          </p>
        </li>
      </ol>
	 </Card.Body>
	 </Card>
	 </div>
    </div>
  );
}
export default PrivacyPolicy;


