import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentElement, Elements } from "@stripe/react-stripe-js";
import "../css/stripe.css";

const StripeForm = (props) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (!error) {
      const response = await fetch('/api/add_payment_method/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
        }),
      })
	  .then(() => props.closeForm())
	   ;

      const data = await response.json();
      console.log(data);
      setLoading(false);
    } else {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-row'>
        <label htmlFor="card-element">Credit or debit card</label>
	  <br/><br/>
        <div id='card-element'>
          <CardElement />
        </div>
        <div id="card-errors" role="alert"></div>
      </div>
	  <br/><br/>
      <Button variant='success' type="submit" disabled={!stripe || loading}>
        {loading ? 'Loading...' : 'Add Payment Method'}
      </Button>
	  <br/>
      <Button variant='secondary' onClick={props.closeForm}>
	  Cancel
	</Button>
    </form>
  );
};

export default StripeForm;

