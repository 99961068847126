import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Button, Container, Card, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import User_vars from '../components/user_vars'
import navbar from '../components/navbar'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

class Demo extends Component
{
		state = {
		total_entries: 0,
		total_pages: 0,
		current_page: 0,
		records_shown: 10000,
		distance: 0,
		index: 0,
		hit_top : false,
		hit_bottom : false,
		down_pmt: '20',
                renovation: '0',
                close_cost: '2',
                cost_of_sale: '4.5',
                loan_apr: '5',
                mgmt_fee: '0',
                mnth_vacant: '0',
                rent_annual_inc: '5',
                depreciation: '27.5',
                tax_rate: '15',
                term: '30',
		sortProfitChecked: false,
		records: [],
		records2: [],
		all_records : ["test"],
		active_state: ""
		}

set_user_vars = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    this.setState({
      [nam]: val
  })
}

submit_user_vars = () => {
	const param_dict = {
		"down_pmt" : this.state.down_pmt,
		"renovation" : this.state.renovation,
		"close_cost" : this.state.close_cost,
		"cost_of_sale" : this.state.cost_of_sale,
		"loan_apr" : this.state.loan_apr,
		"mgmt_fee" : this.state.mgmt_fee,
		"mnth_vacant" : this.state.mnth_vacant,
		"rent_annual_inc" : this.state.rent_annual_inc,
		"depreciation" : this.state.depreciation,
		"tax_rate" : this.state.tax_rate,
		"term" : this.state.term,
		"records_shown" : this.state.records_shown,
		"index" : this.state.index,
		"sortProfitChecked" : this.state.sortProfitChecked
	}
  axios.post("/api/save_user_vars/", { 
	  params : param_dict
  }).then(() => {
	this.queryCategory()
  })
}

setSortProfit = () => {
  this.setState({
	  "sortProfitChecked" : !this.state.sortProfitChecked
  })
}

componentDidUpdate(prevProps, prevState) {
	if (prevState.sortProfitChecked !== this.state.sortProfitChecked) {
		this.queryCategory()
	};
	if (prevState.index !== this.state.index) {
		this.queryCategory()
	}
	if (prevState.records_shown !== this.state.records_shown) {
		this.queryCategory()
	}
	if (prevState.distance !== this.state.distance) {
		this.queryCategory()
	}
	
}

pageUp = () => {
	console.log("Hit Up")
	console.log(this.state)
	const index = this.state.index
	const records_shown = this.state.records_shown
	const total_entries = this.state.total_entries
	if ((index + records_shown) < total_entries) {
		console.log("Up condition")
		const new_index = index + records_shown
		this.setState({ index : new_index ,
				hit_bottom : false
				})
	} else {
		this.setState({ hit_top : true })
	}
	console.log(this.state)
}

pageDown = () => {
	console.log("Hit Down")
	//console.log(this.state)	
	const index = this.state.index
	const records_shown = this.state.records_shown
	const total_entries = this.state.total_entries
	if ((index - records_shown) >= 0) {
		const new_index = index - records_shown
		this.setState({ index : new_index ,
				hit_top : false
				})
	} else {
		this.setState({ hit_bottom : true,
				index : 0		
		})
	}
	console.log(this.state)
}

changeItemCount = (value) => {

	this.setState({
		records_shown : parseInt(value)
	})
}
changeDistance = (value) => {

	this.setState({
		distance : parseInt(value)
	})
}
searchRecords = (value) => {

	if (value == "") {

		this.setState({
			
		})
	}
	this.setState({
		distance : parseInt(value)
	})
}
	
queryCategory = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("state")
	this.setState({ active_state : code })
	const param_dict = {
		"state" : "VA",
		"city" : "Arlington",
		"down_pmt" : this.state.down_pmt,
		"renovation" : this.state.renovation,
		"close_cost" : this.state.close_cost,
		"cost_of_sale" : this.state.cost_of_sale,
		"loan_apr" : this.state.loan_apr,
		"mgmt_fee" : this.state.mgmt_fee,
		"mnth_vacant" : this.state.mnth_vacant,
		"rent_annual_inc" : this.state.rent_annual_inc,
		"depreciation" : this.state.depreciation,
		"tax_rate" : this.state.tax_rate,
		"term" : this.state.term,
		"records_shown" : this.state.records_shown,
		"index" : this.state.index,
		"distance" : this.state.distance,
		"sortProfitChecked" : this.state.sortProfitChecked,
		"category" : "city"
	}
	if (this.props.category == "city") {	
	    const city = queryParams.get("city")
	    param_dict["city"] = city
	}
	if (this.props.category == "county") {	
	    const county = queryParams.get("county")
	    param_dict["county"] = county
	}
	if (this.props.category == "zipcode") {
	    const prox_zip = queryParams.get("zipcode")
	    param_dict["zipcode"] = prox_zip
	}

  axios.get("/api/query_category/", { 
	  params : param_dict
  }
  )
      .then((response) => {
	  this.setState({
              records : response.data.records,
		total_entries : response.data.total_entries,
		  total_pages : response.data.total_pages,
		  current_page : response.data.current_page
          })
	  console.log(response.data)
      })
}

resetValues = () => {

		this.setState({
		down_pmt: '20',
                renovation: '0',
                close_cost: '2',
                cost_of_sale: '4.5',
                loan_apr: '5',
                mgmt_fee: '0',
                mnth_vacant: '0',
                rent_annual_inc: '5',
                depreciation: '27.5',
                tax_rate: '15',
                term: '30',
		sortProfitChecked: false
		})

}

initialize = () => {

  axios.get("/api/get_user_vars/", { 
	  params : ""
  }
  )
      .then((response) => {
	      const vars = response.data["var"]
          	this.setState({
		down_pmt: vars["down_pmt"],
                renovation: vars["renovation"],
                close_cost: vars["close_cost"],
                cost_of_sale: vars["cost_of_sale"],
                loan_apr: vars["loan_apr"],
                mgmt_fee: vars["mgmt_fee"],
                mnth_vacant: vars["mnth_vacant"],
                rent_annual_inc: vars["rent_annual_inc"],
                depreciation: vars["depreciation"],
                tax_rate: vars["tax_rate"],
                term: vars["term"],
          })
	  console.log(response.data)
      }).then(() => {

	      this.queryCategory()
      })
	
}

componentDidMount() {
	this.initialize()	

}

usdFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
})


render() {

	  {console.log("Rendered", this.state)}	
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("state")
	const Checkbox = ({ label, value, onChange }) => {
  		return (
    		<label>
      		<input type="checkbox" checked={value} onChange={onChange} />
      		&nbsp;{label}
    		</label>
  		);
	};

	const distance_inject = () => {
		
		if (this.state.records.length > 0){
		
			if ("distance_away" in this.state.records[0]) {
				return(<th>Distance Away</th>)
			} else {
				return
			}

		}
	}
	const list_addr = this.state.records.slice(0, 10).sort().map(x => {

		const listing_status = x["status"]
		const distance_away = () => {
			if ("distance_away" in x) {
				return(<td>{Number(x["distance_away"].toFixed(2))}&nbsp;Miles</td>)
			} else {
				return
			}
		}
		const address = x["address"]
		const city = x["city"]
		const county = x["county"]
		const raw_year_nom_cf = x["year_nom_cf"]
		const year_nom_cf = this.usdFormat.format(x["year_nom_cf"])
		let debt_cover_ratio = ""
		if (x["debt_cover_ratio"] == "0.00") {
			debt_cover_ratio = ""
		} else {
			debt_cover_ratio = x["debt_cover_ratio"]
		}	
		const sale_list_price = this.usdFormat.format(x["sale_list_price"])
		const link_addr = "/address-details/?address=" + encodeURIComponent(address) + "&city=" + city + "&code=" + this.state.active_state
		const link_city = document.location + "city/" + city
		const link_county = document.location + "county/" + county
		
		return(	
	  		<tr>
				<td style={{color : listing_status == "Active" ? "green" : "blue"}}>{listing_status}</td>
				<td style={{color : raw_year_nom_cf >= 0 ? "green" : "red" }}>{year_nom_cf}</td>
				<td>{debt_cover_ratio}</td>
				<td>{sale_list_price}</td>
				{distance_away()}
	 			<td><a href={link_addr}>{address}</a></td>
	  			<td><a href={link_city}>{city}</a></td>
	  			<td><a href={link_county}>{county}</a></td>
	  		</tr>
		)
	})
//1460 1470
	const list_addr2 = this.state.records.reverse().slice(1440, 1450).sort().map(x => {

		const listing_status = x["status"]
		const distance_away = () => {
			if ("distance_away" in x) {
				return(<td>{Number(x["distance_away"].toFixed(2))}&nbsp;Miles</td>)
			} else {
				return
			}
		}
		const address = x["address"]
		const city = x["city"]
		const county = x["county"]
		const raw_year_nom_cf = x["year_nom_cf"]
		const year_nom_cf = this.usdFormat.format(x["year_nom_cf"])
		let debt_cover_ratio = ""
		if (x["debt_cover_ratio"] == "0.00") {
			console.log("True")
			debt_cover_ratio = ""
		} else {
			console.log("False")
			debt_cover_ratio = x["debt_cover_ratio"]
		}	
		const sale_list_price = this.usdFormat.format(x["sale_list_price"])
		const link_addr = "/address-details/?address=" + encodeURIComponent(address) + "&city=" + city + "&code=" + this.state.active_state
		const link_city = document.location + "city/" + city
		const link_county = document.location + "county/" + county
		
		return(	
	  		<tr>
				<td style={{color : listing_status == "Active" ? "green" : "blue"}}>{listing_status}</td>
				<td style={{color : raw_year_nom_cf >= 0 ? "green" : "red" }}>{year_nom_cf}</td>
				<td>{debt_cover_ratio}</td>
				<td>{sale_list_price}</td>
				{distance_away()}
	 			<td><a href={link_addr}>{address}</a></td>
	  			<td><a href={link_city}>{city}</a></td>
	  			<td><a href={link_county}>{county}</a></td>
	  		</tr>
		)
	})

  const tableStyle = {
   width: "50%",
   float: 'left',
}

  return (
	<div className='App'>
	<User_vars user_vars = {this.state} set_user_vars = {this.set_user_vars} submit_user_vars = {this.submit_user_vars} resetValues = {this.resetValues}/>
          <Container>
              <Row>
              </Row>
          </Container>
	  <style>{"table{ border:1px solid black; width:100%; table-layout:fixed;}"}</style>
	  <div>

	  <Checkbox
        	label="Sort By Highest ROI"
        	value={this.state.sortProfitChecked}
        	onChange={this.setSortProfit}
      		/>&nbsp;&nbsp;
	  <a href={"/counties/?state=" + code}>Search By County</a>&nbsp;&nbsp;
	  <a href={"/cities/?state=" + code}>Search By City</a>&nbsp;&nbsp;
	  <a href={"/zipcodes/?state=" + code}>Search By Zipcode</a>
	<br/>
	<>  
	</>
	   <table>
          	<thead>
			<th>Status</th>
	  		<th>Yearly Nominal Cash Flow</th>
			<th>Debt Coverage Ratio</th>
	  		<th>Sale List Price</th>
			{distance_inject()}
	  		<th>Address</th>
	  		<th>City</th>
	  		<th>County</th>
	  	</thead>
	  <tbody>
	  {list_addr}
	  </tbody>
	  </table>
	   <table>
          	<thead>
			<th>Status</th>
	  		<th>Yearly Nominal Cash Flow</th>
			<th>Debt Coverage Ratio</th>
	  		<th>Sale List Price</th>
			{distance_inject()}
	  		<th>Address</th>
	  		<th>City</th>
	  		<th>County</th>
	  	</thead>
	  <tbody>
	  {list_addr2}
	  </tbody>
	  </table>
      	</div>
		<Button className='my-2' variant="primary" onClick={this.pageDown}>Prev</Button>&nbsp;&nbsp;
		<Button className='my-2' variant="primary" onClick={this.pageUp}>Next</Button>&nbsp;&nbsp;
		<a>Page {this.state.current_page} of {this.state.total_pages}</a>
	  </div>
  );
}
}

export default Demo;
