import React, { Text, useState, useEffect } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkout_form";
import "../css/stripe.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function Subscribe(props) {
	const stripePromise = loadStripe(props.stripe_api_key);
  const [clientSecret, setClientSecret] = useState("");
  const [subscription_amount, setSubscriptionAmount] = useState("")
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/api/create_new_purchase_payment_intent/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "monthly_standard_subscription" }] }),
    })
      .then((res) => res.json())
      .then((data) => { 
	      setClientSecret(data.clientSecret);
	      setSubscriptionAmount(data.amount);
      })
  }, []);

  const usdFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
	<>
	  <Row>
	  <Col>
	    <div className="table-wrapper">
		    <div className="stripe">
			<Card>
			  <div key={`default-radio`} className="mb-3">
			  <Form.Check
	  			checked
			    type="radio"
			    id={`default-radio`}
			    label={<a style={{color: 'black'}}><b style={{color: 'red'}}>7 Day Trial</b><b> then </b><b style={{color: 'red'}}>{usdFormat.format(subscription_amount)}</b><b> Billed Monthly (Standard Subscription)</b></a>}
			  />	
	  		</div>
	  			<p>If you cancel your subscription before the end of the trial period, you will not be charged. Trial will begin immediately upon submitting payment information. Subscription will begin 7 days following and at that time you will be charged {usdFormat.format(subscription_amount)} monthly until cancelled. You may cancel a subscription at any time without prior notice and if there is remaining time on the current billing cycle, your account will remain active until the end of the billing cycle. You will not be charged again after cancellation unless you reactivate your account. Account reactivation must be performed from within the account management page.</p>
						
	  		</Card>
	  </div>
	    </div>
	  </Col>
	  <Col>
	    <div className="table-wrapper">
		    <div className="stripe">
			   {clientSecret && (
			   <Elements options={options} stripe={stripePromise}>
			     <CheckoutForm />
			   </Elements>
		      )}
	  		</div>
	    </div>
	  </Col>
	  </Row>
	  </>
  );
}
