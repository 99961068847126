import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { Table, Button, Container, Card, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import User_vars from '../../components/user_vars'
import navbar from '../../components/navbar'

class Amortization extends Component
{
		state = {
	
		showUserVars : false,
		down_pmt: '20',
                renovation: '10',
                close_cost: '2',
                cost_of_sale: '4.5',
                loan_apr: '5',
                mgmt_fee: '0',
                mnth_vacant: '1',
                rent_annual_inc: '5',
                depreciation: '27.5',
                appreciation: '5',
                income_tax_rate: '30',
                cap_gains_tax_rate: '15',
                term: '30',
                condo_fee: '300',
		renovation_add: '70',

		sortProfitChecked: false,
		amortize_headers : {},
		amortize_data : [],
		prop_id : "",
		active_state : "",
		}

set_user_vars = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    this.setState({
      [nam]: val
  })
}

update_user_vars = (event) => {
  this.setState({
    reviewUpdate: event.target.value
  })
}

hideUserVars = () => {
        this.setState({
                showUserVars : false
        })
}
showUserVars = () => {
        this.setState({
                showUserVars : true
        })
}

submit_user_vars = () => {
/*        const param_dict = {
                "down_pmt" : this.state.down_pmt.toString(),
                "renovation" : this.state.renovation.toString(),
                "close_cost" : this.state.close_cost.toString(),
                "cost_of_sale" : this.state.cost_of_sale.toString(),
                "loan_apr" : this.state.loan_apr.toString(),
                "mgmt_fee" : this.state.mgmt_fee.toString(),
                "mnth_vacant" : this.state.mnth_vacant.toString(),
                "rent_annual_inc" : this.state.rent_annual_inc.toString(),
                "depreciation" : this.state.depreciation.toString(),
                "tax_rate" : this.state.tax_rate.toString(),
                "term" : this.state.term.toString(),
        }
  axios.post("/api/save_user_vars/", {
          params : param_dict
  }).then(() => {*/
        this.queryAmortization()
  //})
}


setSortProfit = () => {
  this.setState({
	  "sortProfitChecked" : !this.state.sortProfitChecked
  })
}

componentDidUpdate(prevProps, prevState) {
	if (prevState.sortProfitChecked !== this.state.sortProfitChecked) {
		if (this.state.sortProfitChecked) {
		const records = this.state.records
		records.sort(function(a, b) {
  		return b[0] - a[0];
	});
		this.setState({
			"records" : records
		})
	}
	
	else {
		this.queryAmortization()
	}
	}
}
	
queryAmortization = () => {

	const prop_id = this.props.prop_id
        //const address = this.props.address
        const code = this.props.code

        this.setState({ active_state : code,
                        prop_id : prop_id })

//	const queryParams = new URLSearchParams(window.location.search);
//	const address = queryParams.get("address")
//	const city = queryParams.get("city")
//	const code = queryParams.get("code")
//	this.setState({ active_address : address })
	let param_dict = {
		"prop_id" : prop_id.toString(),
		"code" : code.toString(),
		"down_pmt" : this.state.down_pmt.toString(),
		"renovation" : this.state.renovation.toString(),
		"close_cost" : this.state.close_cost.toString(),
		"cost_of_sale" : this.state.cost_of_sale.toString(),
		"loan_apr" : this.state.loan_apr.toString(),
		"mgmt_fee" : this.state.mgmt_fee.toString(),
		"mnth_vacant" : this.state.mnth_vacant.toString(),
		"rent_annual_inc" : this.state.rent_annual_inc.toString(),
		"depreciation" : this.state.depreciation.toString(),
		"appreciation" : this.state.appreciation.toString(),
		"income_tax_rate" : this.state.income_tax_rate.toString(),
		"cap_gains_tax_rate" : this.state.cap_gains_tax_rate.toString(),
		"term" : this.state.term.toString(),
		"condo_fee" : this.state.condo_fee.toString(),
		"renovation_add": this.state.renovation_add.toString()
	}

  axios.get("/api/query_amortization/", { 
	  params : param_dict
  }
  )
      .then((response) => {
          this.setState({
              amortize_headers : response.data["amortize_headers"],
		amortize_data : response.data["amortize_data"]
          })
      })
}

resetValues = () => {

		this.setState({
		down_pmt: '20',
                renovation: '10',
                close_cost: '2',
                cost_of_sale: '4.5',
                loan_apr: '5',
                mgmt_fee: '0',
                mnth_vacant: '1',
                rent_annual_inc: '5',
                depreciation: '27.5',
                appreciation: '5',
                income_tax_rate: '30',
                cap_gains_tax_rate: '15',
                term: '30',
                condo_fee: '300',
		renovation_add: '70',
		})

}

initialize = () => {

  axios.get("/api/get_user_vars/", {
          params : ""
  })
      .then((response) => {
              const vars = response.data["var"]
                this.setState({
                down_pmt: vars["down_pmt"],
                renovation: vars["renovation"],
                close_cost: vars["close_cost"],
                cost_of_sale: vars["cost_of_sale"],
                loan_apr: vars["loan_apr"],
                mgmt_fee: vars["mgmt_fee"],
                mnth_vacant: vars["mnth_vacant"],
                rent_annual_inc: vars["rent_annual_inc"],
                depreciation: vars["depreciation"],
                appreciation: vars["appreciation"],
                income_tax_rate: vars["income_tax_rate"],
                cap_gains_tax_rate: vars["cap_gains_tax_rate"],
                term: vars["term"],
                condo_fee: vars["condo_fee"],
		renovation_add: vars["renovation_add"],
          }, this.queryAmortization)
      })
}



componentDidMount() {
	this.initialize()
}

usdFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
})


render() {

	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("code")
	const address = queryParams.get("address")
	const Checkbox = ({ label, value, onChange }) => {
  		return (
    		<label>
      		<input type="checkbox" checked={value} onChange={onChange} />
      		&nbsp;{label}
    		</label>
  		);
	};

	const userVarsInject = () => {
                        return(
				<>
				<Row>
				<Col>
                                <Button className='my-2'
                                        variant="light"
                                        onClick={this.props.return_function}>
                                        {"<<"} Return To Search
                                </Button>
                                </Col>
				<Col>
                                <User_vars      user_vars = {this.state}
                                                set_user_vars = {this.set_user_vars}
                                                submit_user_vars = {this.submit_user_vars}
                                                resetValues = {this.resetValues}
                                                hideUserVars = {this.hideUserVars}
                                		stateShowUserVars = {this.state.showUserVars}
				/>
				<Button className='my-2' variant="primary" onClick={this.showUserVars}>Show Inputs</Button>
				</Col>
				</Row>
				</>
                        )

        }


	const list_address_title = () => {
		const x = this.state.amortize_headers
		const listing_status = x["status"]
		const address = x["address"]
		const city = x["city"]
		const county = x["county"]
		const link_addr = "/address-details/?address=" + encodeURIComponent(address) + "&city=" + city + "&code=" + code
                const link_city = "/cities/citysearch/?state=" + this.state.active_state + "&city=" + city
                const link_county = "/counties/countysearch/?state=" + this.state.active_state + "&county=" + county
		return(
			   <Table responsive striped bordered hover>
				<thead>
				<tr>
					<th>Status</th>
					<th>Address</th>
					<th>City</th>
					<th>County</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>{listing_status}</td>
					<td>{address}</td>
					<td><a href={link_city}>{city}</a></td>
					<td><a href={link_county}>{county}</a></td>
				</tr>
				</tbody>
			  </Table>
		)
	}

	const list_pricing_info = () => {
		const x = this.state.amortize_headers
		const sale_list_price = this.usdFormat.format(x["sale_list_price"])
		let down_pmt = this.usdFormat.format(x["down_pmt"])
		let loan_amt = this.usdFormat.format(x["loan_amt"])
		const term = x["term"]
		const term_mnth = x["term_mnth"]
		const loan_apr = x["loan_apr"]
		if (x["address"] == "Inactive Account") {
			down_pmt = "$0.00"
			loan_amt = "$0.00"
		}
		return(	
	  		<tr>
				<td>{sale_list_price}</td>
				<td>{down_pmt}</td>
				<td>{loan_amt}</td>
				<td>{term}</td>
				<td>{term_mnth}</td>
				<td>{loan_apr}</td>
	  		</tr>
		)
	}


	const list_amortization_schedule = this.state.amortize_data.map(x => {
		const number = x["number"]
		const amount = this.usdFormat.format(x["amount"])
		const interest = this.usdFormat.format(x["interest"])
		const principle = this.usdFormat.format(x["principle"])
		const balance = this.usdFormat.format(x["balance"])
		return(	
	  		<tr>
				<td>{number}</td>
				<td>{amount}</td>
				<td>{interest}</td>
				<td>{principle}</td>
				<td>{balance}</td>
	  		</tr>
		)
	})

  return (
     	
	<div className='App'>
	  <div className="table-wrapper">
		<Card style={{width : "50%"}}>
					{userVarsInject()}
		</Card>
          </div>

	  <div className="table-wrapper">
		<Card style={{width : "100%"}}>
	  <div>
			<a href={"/counties/?state=" + this.state.active_state}>Search By County</a>&nbsp;&nbsp;
			<a href={"/cities/?state=" + this.state.active_state}>Search By City</a>&nbsp;&nbsp;
			<a href={"/zipcodes/?state=" + this.state.active_state}>Search By Zipcode</a>
			{list_address_title()}
			   <Table responsive striped bordered hover>
				<th>Sale List Price</th>
					<th>Down Payment</th>
					<th>Loan Amount</th>
					<th>Term Years</th>
					<th>Term Months</th>
					<th>Interest Rate</th>
				<tbody>{list_pricing_info()}</tbody>
			  </Table>
			  <Table responsive striped bordered hover>
				<thead><th>Payment #</th>
					<th>Total</th>
					<th>Interest</th>
					<th>Principle</th>
					<th>Remaining Balace</th></thead>
				<tbody>{list_amortization_schedule}</tbody>
			  </Table>
	  </div>
		</Card>
      	</div>
    </div>
  );
}
}

export default Amortization;
