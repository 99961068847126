import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { Button, Container, Card, Row, Table } from 'react-bootstrap'
import navbar from '../../components/navbar'
import SpinnerLoading from '../../components/spinner.js';

class Category extends Component {

	state = {
	    records: [],
	    active_state: "",
	    ref : "",
	    display_category : "",
	}

componentDidMount() {
	
	this.setState({
                loaded : false
        })
	let param_dict = {}
	const queryParams = new URLSearchParams(window.location.search);
        const ref = queryParams.get("ref")
	if (ref != "") {
		this.setState({
			ref : ref
		})
	}
	if (this.props.category == "city") {
	    const code = queryParams.get("state")
	    this.setState({ "active_state" : code, "display_category" : "city" })
	    param_dict = {"category" : "city", "state" : code}
	}
	if (this.props.category == "zipcode") {
	    const code = queryParams.get("state")
	    this.setState({ "active_state" : code, "display_category" : "zipcode"})
	    param_dict = {"category" : "zipcode", "state" : code}
	}
  	if (this.props.category == "state") {
	    this.setState({ "display_category" : "state"})
	    param_dict = {"category" : "state"}
	}
	if (this.props.category == "county") {
	    const code = queryParams.get("state")
	    this.setState({ "active_state" : code, "display_category" : "county" })
	    param_dict = {"category" : "county", "state" : code}
	}
	if (this.props.category == "logical_area") {
	    const code = queryParams.get("logical_area") 
	    this.setState({ "active_logical_area" : code, "display_category" : "logical area" })
	    param_dict = {"category" : "logical_area", "logical_area" : code}
	}
	axios.get("/api/get_categories/", {
	    params: param_dict
  	
	}).then((response) => {
            if ("redirect" in response.data) {
		    window.location = response.data["redirect"]
	    }
	    this.setState({
            records : response.data,
	    loaded : true
          })
	})
}
render() {
	let list_category = ""
	if (this.props.category == "state") {
	    list_category = this.state.records.map(x => {
		let link = ""
		if (this.state.ref == "zipcode") {
			link = "../zipcodes/?state=" + x.code
		} else if (this.state.ref == "city") {
			link = "../cities/?state=" + x.code
		} else if (this.state.ref == "county") {
			link = "../counties/?state=" + x.code
		} else {
			link = "../states/statesearch/?state=" + x.code
		}
		return(
	  		<tr>
	  		<td>
			<a href={link}>{x.state}</a>
	  		</td>
	  		</tr>
		)
	    })
	}
	if (this.props.category == "city") {
	    list_category = this.state.records.map(x => {
		let link = "citysearch/?state=" + this.state.active_state + "&city=" + x.city
		return(
	  		<tr>
	  		<td>
			<a href={link}>{x.city}</a>&nbsp;&nbsp;{x.count}
	  		</td>
	  		</tr>
		)
	    })
	}
	if (this.props.category == "county") {
	    list_category = this.state.records.map(x => {
		let link = "countysearch/?state=" + this.state.active_state + "&county=" + x.county
		return(
	  		<tr>
	  		<td>
			<a href={link}>{x.county}</a>&nbsp;&nbsp;{x.count}
	  		</td>
	  		</tr>
		)
	    })
	}
	if (this.props.category == "zipcode") {
	    list_category = this.state.records.map(x => {
		let link = "zipcodesearch/?state=" + this.state.active_state + "&zipcode=" + x.zipcode
		return(
	  		<tr>
	  		<td>
			<a href={link}>{x.zipcode}</a>&nbsp;&nbsp;{x.count}
	  		</td>
	  		</tr>
		)
	    })
	}
	if (this.props.category == "logical_area") {
	    list_category = this.state.records.map(x => {
		let link = "logical_areas/logical_area_search/?logical_area=" + x.code
		return(
	  		<tr>
	  		<td>
			<a href={link}>{x.logical_area}</a>
	  		</td>
	  		</tr>
		)
	    })
	}
	const loadedLogic = () => {
                if (!this.state.loaded) {
                        return(
                                <tr>
                                <td><SpinnerLoading /></td>
                                </tr>
                        )
                } else {
			return list_category
		}
	}
  return (
	<div className='App'>
		<div className="card-wrapper">
          		<Container>
				<Card style={{width : "100%"}}>
					<Card.Header>
	  					<h1>Select a {this.state.display_category}</h1>
	  				</Card.Header>
	  				<Table striped bordered hover>
	  					<tbody>
	  						{loadedLogic()}
	  					</tbody>
	  				</Table>
				</Card>
        		</Container>
      		</div>
	</div>
  );
}
}

export default Category;
