import { React, createContext, useState } from "react";

const AuthContext = createContext();

export function AuthProvider({children}) {

	const [isAuth, setIsAuth] = useState(false)

	const updateAuth = (auth) => {
		setIsAuth((isAuth) => auth )
	};

	return(
		<AuthContext.Provider value={{ isAuth, updateAuth }}>
			{children}
		</AuthContext.Provider>

	)}

export default AuthContext;
