import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from "./AuthContext"

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log(localStorage)
root.render(
		<AuthProvider>
			<App />
		</AuthProvider>
);

document.body.style.marginTop = '70px';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
