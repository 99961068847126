import React, { useState } from 'react';
import '../css/styles.css'
import '../css/error.css'
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import validator from 'validator';
import ScrollToTop from "react-scroll-to-top";

export default function SignUp() {
	
	const [validated, setValidated] = useState(false);
	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('');
	const [email_address, setEmailAddress] = useState('');
	const [password, setPassword] = useState('');
	const [terms, setTerms] = useState(false);
	const [opt_email, setOptEmail] = useState(true);
	const [pass_complex_error, setPassComplexError] = useState(false);
	const [email_registration_error, setEmailRegistrationError] = useState(false);

	const handleFirstName = (e) => {
          setFirstName(e.target.value);
        };

	const handleLastName = (e) => {
          setLastName(e.target.value);
        };

	const handleEmailAddress = (e) => {
          setEmailAddress(e.target.value);
        };

	const handlePassword = (e) => {
          setPassword(e.target.value);
        };
	const handleTerms = (e) => {
          setTerms(!terms);
        };
	const handleOptEmail = (e) => {
          setOptEmail(!opt_email);
        };

	const validatePass = (value) => {

		if (validator.isStrongPassword(value, {
			minLength: 8, minLowercase: 1,
			minUppercase: 1, minNumbers: 1, minSymbols: 1
		})) {
			return true
	} else {
		return false
	}
	}

	// Handling the form submission
	const handleSubmit = (event) => {
	setPassComplexError(false)
	setEmailRegistrationError(false)
	const form = event.currentTarget;	
	      event.preventDefault();
		event.stopPropagation();
	if (form.checkValidity() === false) {
	setValidated(true)
	} else {
	    if (!validatePass(password)) {
		setPassComplexError(true)
		    window.scrollTo(0, 0)
	    } else {
	    axios.post("/api/user/signup/", { 
		    first_name : first_name,
		    last_name : last_name,
		    email_address : email_address,
		    password : password,
		    agree_terms : terms.toString(),
		    email_marketing : opt_email.toString(),
		})
      		.then((response) => {
          		if (response.status == 200) {
				if ("redirect" in response.data) {
				window.location = response.data["redirect"]
				}
				else if (response.data.code == 1) {
					setEmailRegistrationError(true)
					window.scrollTo(0, 0)
				}
			} else {
				console.log("Error")
          		
			}
			})
      		}
	    }
	}

	

	// Showing success message
	const successMessage = () => {
		  return (
			<>
			<Alert variant="success">
				<a>Signup Successful</a>
			</Alert>
			<br/>
			</>

		  );
	};

	// Showing error message if error is true
	const passComplexErrorMessage = () => {
		if (pass_complex_error) {
		return (
			<>
			<Alert variant="danger" onClose={() => setPassComplexError(false)} dismissible >
			      <a>Password Does Not Meet Complexity Requirements:</a>
			      <ul>
				<li>Minimum 8 Characters</li>
				<li>At Least 1 Uppercase Letter</li>
				<li>At Least 1 Lowercase Letter</li>
				<li>At Least 1 Number</li>
				<li>At Least 1 Symbol</li>
			     </ul>
				
			</Alert>
			<br/>
			</>
	  );
		}
	};
	const emailRegistrationErrorMessage = () => {
		if (email_registration_error) {
		return (
			<>
			<Alert variant="danger" onClose={() => setEmailRegistrationError(false)} dismissible >
			      <a>Email Address Already Registered.</a>
			</Alert>
			<br/>
			</>
	  );
		}
	};
	
	const accountRegistrationDisabled = () => {
		return (
			<>
			<Alert variant="danger" >
			      <a>Open account registration is temporarily disabled. Please reach out to CashFlowRealEstateAI@gmail.com to request an account. Open account registration will resume on March 1st, 2023. Our apologies for the inconvenience.</a>
			</Alert>
			<br/>
			</>
	  );
	};
		//{accountRegistrationDisabled()}
	return (
		<>
		{passComplexErrorMessage()}
		{emailRegistrationErrorMessage()}
		<div className="card-wrapper">
		  <div className="card">
		<h1>Create Account</h1>
		<br/>
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
		      <Row>
			<Form.Group  controlId="first_name">
			  <Form.Label>First Name</Form.Label>
			  <InputGroup hasValidation>
			  <Form.Control type="text"
					required
			    		onChange={handleFirstName}
			  />
			<Form.Control.Feedback type="invalid">
				Please enter your first name.
			</Form.Control.Feedback>
			</InputGroup>
			</Form.Group>
		      </Row>
			<br/>
		      <Row>
			<Form.Group controlId="last_name">
			  <Form.Label>Last Name</Form.Label>
			  <InputGroup hasValidation>
			  <Form.Control
			    onChange={handleLastName}
			    required
			    type="text"
			  />
			  <Form.Control.Feedback type="invalid">
				Please enter your last name.
			</Form.Control.Feedback>
			</InputGroup>
			</Form.Group>
		      </Row>
			<br/>
		      <Row>

			<Form.Group controlId="email_address">
			  <Form.Label>Email Address</Form.Label>
			  <InputGroup hasValidation>
			    <Form.Control
			      onChange={handleEmailAddress}
			      type="email"
			     
			      required
			    />
			    <Form.Control.Feedback type="invalid">
			      Please enter your email address
			    </Form.Control.Feedback>
			  </InputGroup>
			</Form.Group>
		      </Row>
			<br/>
		      <Row>
			<Form.Group controlId="password">
			  <Form.Label>Password</Form.Label>
			  <Form.Control type="password" 
			
			      onChange={handlePassword}
			 required />
			  <Form.Control.Feedback type="invalid">
			    Please set a password.
			  </Form.Control.Feedback>
			<Row>
			<Col>
			  <Form.Label>Min 8 Characters</Form.Label>
			</Col>
			<Col>
			  <Form.Label>Min 1 Uppercase</Form.Label>
			</Col>
			</Row>
			<Row>
			<Col>
			  <Form.Label>Min 1 Lowercase</Form.Label>
			</Col>
			<Col>
			  <Form.Label>Min 1 Number</Form.Label>
			</Col>
			</Row>
			<Row>
			<Col>
			  <Form.Label>Min 1 Symbol</Form.Label>
			</Col>
			<Col>
			</Col>
			</Row>
			  
			</Form.Group>
		      </Row>
			<br/>
		      <Row>
			<Form.Group>
			  <Form.Check
			    required
			    onChange={handleTerms}
			    label={
			      <>
				I agree to and accept the <Nav.Link href="/user/terms_and_conditions">Terms &amp; Conditions</Nav.Link>
			      </>
			    }
			    feedback="You must agree before submitting."
			    feedbackType="invalid"
			  />
			</Form.Group>

			<Form.Group>
			<Form.Check
			defaultChecked={opt_email}
			  label="I want to receive email offers."
			onChange={handleOptEmail}
			/>
		      </Form.Group>
			</Row>
			<br/>
		      <Button type="submit">Create Account</Button>
		    </Form>
	</div>

	  </div>
		</>
	);
}
