import React, { Text, useState, useEffect } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkout_form";
import "../css/stripe.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51McGhZEP98R1WWIDF3HHRJIG2MQFyQnjJ4f451ai3wqyvPbw2hEQwTsP3zlhIFH9axY3KZC0MLfzkVs6Je1vwrbz00YjNbT6sC");

export default function ManageSubscription() {
  const [clientSecret, setClientSecret] = useState("");
  
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/api/add_payment_method/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "monthly_standard_subscription" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    appearance,
  };

  return (
	<>
	<Row>
	  <Col>
	    <div className="table-wrapper">
		    <div className="stripe">
			<Card>
			  <div key={`default-radio`} className="mb-3">
			  <Form.Check
	  			checked
			    type="radio"
			    id={`default-radio`}
			    label={<p style={{color: 'red'}}><b>$1,300 Billed Monthly (Standard Subscription)</b></p>}
			  />	
	  		</div>
	  			<p>Email Address Entered is for Reciept</p>
	  			<p>All transactions are final</p>
	  			<p>Cancel at any time</p>
						
	  		</Card>
	  	</div>
	    </div>
	    </Col>
	    <Col>
	    <div className="table-wrapper">
		    <div className="stripe">
			   <Elements options={options} stripe={stripePromise}>
			     <CheckoutForm />
			   </Elements>
		    </div>
	    </div>
	    </Col>
	    </Row>
	  </>
  );
}
