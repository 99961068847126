import React from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";

const Footer = () => {
  return (
	 <>
    <Navbar variant="dark" className="footer" style={{ position: "relative", bottom: 0, width: "100%", height : "60px", "background-color": "rgb(0,0,0)" }}>
      <div className="card-wrapper">
	<Container>
        <Row>
          <Col md="auto">
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/user/contact_form">Contact Us</Nav.Link>
            </Nav>
          </Col>
          <Col md="auto">
            <Nav className="mr-auto">
              <Nav.Link href="/faq">FAQ</Nav.Link>
              <Nav.Link href="/user/terms_and_conditions">Terms &amp; Conditions</Nav.Link>
              <Nav.Link href="/user/privacy_policy">Privacy Policy</Nav.Link>
              <Nav.Link href="/user/asset_credits">Asset Credits</Nav.Link>
	    </Nav>
          </Col>
          <Col className="text-muted">
            &copy; {new Date().getFullYear()} <b>CashFlowRealEstate.AI</b>
          </Col>
        </Row>
      </Container>
	  </div>
    </Navbar>
	  </>
  );
};

export default Footer;

