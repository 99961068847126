import React, { Component, useState } from 'react';
import '../App.css';
import axios from 'axios';
import { Button, Container, Card, Form, InputGroup, Row, Col, Offcanvas } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

function user_vars(props) {
const user_vars = props.user_vars
const set_user_vars = props.set_user_vars
const submit_user_vars = props.submit_user_vars
const resetValues = props.resetValues
const hideUserVars = props.hideUserVars
const stateShowUserVars = props.stateShowUserVars
const handleChange = (event) => {
  let nam = event.target.name;
  let val = event.target.value
  this.setState({
    [nam]: val
  })
}


const inputStyle = {
      //"font-size" : "10px",
      //"height" : "100px",
      "width" : "60%"
}
const halfInputStyle = {
      //"font-size" : "10px",
      //"height" : "100px",
      "width" : "40%"
}

	return (
		<>
		<Offcanvas show={stateShowUserVars} onHide={hideUserVars}>
		<Offcanvas.Header closeButton>
		</Offcanvas.Header>
		<Offcanvas.Body style={{ minWidth: '150px', overflowY : "auto" }}>
          	<div className='user-var-wrapper'>
			<Container>
			<Row>
				<Col>
					<Form>
					<Form.Label>Down Payment:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control name='down_pmt' id="down_pmt" value={user_vars.down_pmt} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Renovation:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={inputStyle} name='renovation' id="renovation" value={user_vars.renovation} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Percent of Renovation Value Add:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={inputStyle} name='renovation_add' id="renovation_add" value={user_vars.renovation_add} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Closing Cost:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={inputStyle} name='close_cost' id="close_cost" value={user_vars.close_cost} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Cost of Sale:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={inputStyle} name='cost_of_sale' id="cost_of_sale" value={user_vars.cost_of_sale} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Interest Rate:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={inputStyle} name='loan_apr' id='loan_apr' value={user_vars.loan_apr} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Management Fee:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={inputStyle} name='mgmt_fee' id='mgmt_fee' value={user_vars.mgmt_fee} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Rent Annual Increase:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={inputStyle} name='rent_annual_inc' id='rent_annual_inc' value={user_vars.rent_annual_inc} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Monthly Condo Fee (Condos Only):</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={inputStyle} name='condo_fee' id='condo_fee' value={user_vars.condo_fee} onChange={set_user_vars} />
					<InputGroup.Text>$</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Vacancy (Months):</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={halfInputStyle}>
					<Form.Control style={halfInputStyle} name='mnth_vacant' id='mnth_vacant' value={user_vars.mnth_vacant} onChange={set_user_vars} />
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Depreciation (Years):</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={halfInputStyle}>
					<Form.Control style={halfInputStyle} name='depreciation' id='depreciation' value={user_vars.depreciation} disabled />
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Appreciation:</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={inputStyle}>
					<Form.Control style={halfInputStyle} name='appreciation' id='appreciation' value={user_vars.appreciation} onChange={set_user_vars} />
					<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Form>
					<Form.Label>Term (Years):</Form.Label>
					</Form>
				</Col>
				<Col>
					<InputGroup style={halfInputStyle}>
					<Form.Control style={halfInputStyle} name='term' id='term' value={user_vars.term} onChange={set_user_vars} />
					</InputGroup>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<Button variant="primary" onClick={submit_user_vars}>Update</Button> &nbsp;
					<Button variant="primary" onClick={resetValues}>Reset Values</Button> &nbsp;
				</Col>
			</Row>
			<Row>
				<Col>
					<Button variant="dark" onClick={hideUserVars}>Hide Inputs</Button>
				</Col>
			</Row>
			</Container>
		  </div>
		</Offcanvas.Body>
		</Offcanvas>
		</>
  );
}

export default user_vars
