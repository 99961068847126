import React from 'react';
import hero_image from "../../assets/images/Robot CFRE Hero Image.png"
import landing_banner from "../../assets/images/CFRE_landing_hd.png"
import cfre_pos_snip from "../../assets/images/cfre_very_pos.png"
import cfre_neg_snip from "../../assets/images/cfre_very_neg.png"
import cfre_roi from "../../assets/images/cfre_roi.png"
import cfre_analytics from "../../assets/images/cfre_analytics.png"
import cfre_high_neg_snip from "../../assets/images/CFRE_high_neg_snip.png"
import cfre_detailed_view from "../../assets/images/cfre_detailed_view.png"
import { Col, Row, Container, Card, Button } from "react-bootstrap"
// import sections

const Home = () => {

  const banner_style = {
            "max-width": "90%",
            "max-height": "90%",
	    "margin" : "auto",
        }
  const snip_style = {
            "max-width": "100%",
            "max-height": "100%",
	    "margin" : "auto"
        }
  const buttonStyle = {
      "min-width": '200px',
      height: '60px',
      lineHeight: '45px',
	fontSize: '19px',
	textAlign : "left",
	fontWeight : "lighter"
  };
  return (
    <>
    <div className="desktop-only">
  	    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
		    <div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'left'  }}>
	  		<Container style={{maxWidth : "80%"}}>	
			    <h1 style={{ fontSize: "36px", fontWeight: 'normal', textAlign: 'left', padding: '3px 0' }}><b>Cash Flow Real Estate - AI</b></h1>
	  			<br/>
	  			<br/>
	  			<br/>
			    <h1 style={{ fontSize: "56px", fontWeight: 'lighter', textAlign: 'left', padding: '0 0' }}>Identify Your Next</h1>
	  		    <h1 style={{ fontSize: "56px", fontWeight: 'lighter', textAlign: 'left', padding: '0 0' }}>Cash Flow Property</h1>
	 		    <h1 style={{ fontSize: "56px", fontWeight: 'lighter', textAlign: 'left', padding: '0 0' }}>with</h1>
	  		    <h1 style={{ fontSize: "56px", fontWeight: 'normal', textAlign: 'left', padding: '0 0' }}><b>Confidence</b></h1>
	  			<br />
				<p style={{maxWidth : "60%"}}>Quickly and easily identify positive cash flow properties using your personal preferences.
	  			Sort and compare listings using AI-driven analytics for in-depth insights.
	  			Supercharge your search with speed and accuracy only achievable with AI.</p>
	  			<br />
			    <Button href="https://calendly.com/cfre-ai" variant="primary" style={buttonStyle}><b>Start Your Free Trial</b></Button>
	  		</Container>
	  		</div>
		    <div style={{ flex: '1' }}>
			<img style={banner_style} src={hero_image} />
			</div>
		</div>
	    </Container>
<br/>
<br/>
<br/>
<hr/>
<br/>
<br/>
<br/>
	<div>
      		<h1 style={{ fontSize: "56px", fontWeight: 'lighter'}}><b>Data-Driven Insights and Strategies</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Real-Time Market Data</h2>
		<p>Stay up-to-date with the latest market trends, rental rates, and cash flow metrics to gain a competitive advantage.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Advanced AI-Powered Search</h2>
		<p>Leverage AI-driven algorithms to find the best investment properties based on your preferences.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Customizable Search Criteria</h2>
		<p>Sort your desired metrics, such as cash flow, debt coverage ratio, property type, price range, and more, to receive tailored results.</p>
		</Container>
	      </div>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_pos_snip} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	  </div>
	<br/>
	<br/>
      		<h1 style={{ fontSize: "56px", fontWeight: 'lighter'}}><b>Detailed Cash Flow Analysis</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_detailed_view} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Informed Decision Making</h2>
		<p>Make better investment decisions based on accurate cash flow data, helping you identify high-performing properties and minimize risk.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Optimize Property Performance</h2>
		<p>Identify areas for improvement in your property's cash flow, such as reducing expenses or increasing rental income, to maximize your returns.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Compare Properties Effortlessly</h2>
		<p>Use the cash flow breakdown to compare multiple investment properties, ensuring you choose the one with the highest potential for strong, positive cash flow.</p>
		</Container>
	      </div>
	  </div>
	<br/>
	<br/>
      		<h1 style={{ fontSize: "56px", fontWeight: 'lighter'}}><b>Comprehensive ROI Breakdown</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Full ROI Analysis</h2>
		<p>Assess the true profitability of your potential investment with an in-depth breakdown of property ROI, factoring in income, expenses, and appreciation.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Plan For Years Ahead</h2>
		<p>Understand where you are now, and where you want to be years down the line. See how an investment today will perform in the future.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Instantly Get The Full Picture</h2>
		<p>No more juggling multiple moving pieces in your head, let our algorithms take the load off.</p>
		</Container>
	      </div>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_roi} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	  </div>
	<br/>
	<br/>
      		<h1 style={{ fontSize: "56px", fontWeight: 'lighter'}}><b>Maximize Your Rental Income</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_analytics} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>AI-Driven Rent Estimates</h2>
		<p>Leverage advanced AI algorithms to receive accurate and up-to-date rent estimates for your potential investments based on local market trends.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Comparative Market Analysis</h2>
		<p>Evaluate a property's rental potential by comparing it with similar properties in the area, ensuring competitive pricing.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Property-Specific CFRE-AI Score</h2>
		<p>Get more than just a rent price. See how that price was determined and why. Understand where the property falls within others like it.</p>
		</Container>
	      </div>
	  </div>
	  </div>
	<br/>
	<br/>
      		<h1 style={{ fontSize: "56px", fontWeight: 'lighter'}}><b>Minimize Risk of Loss</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Avoid Costly Mistakes</h2>
		<p>Not every property is a profitable investment. Only see the ones you want and sort away the rest.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Test Different Inputs</h2>
		<p>See how your preferences affect cash flow. There is no one-size-fits-all property for everyone. A negative situation for one may be a positive situation for another.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Take Away The Guesswork</h2>
		<p>If you could have the ability to make more-informed decisions, why wouldn't you use it? Give yourself the clarity to move forward with confidence.</p>
		</Container>
	      </div>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_neg_snip} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	  </div>
	<br/>
	<br/>
	  	<div className="text-center">
		      <Button href="https://calendly.com/cfre-ai" variant="primary" style={buttonStyle}><b>Start Your Free Trial</b></Button>
	  	</div>
   </div>

    <div className="mobile-only">
  	    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
		    <div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'left'  }}>
	  		<Container style={{maxWidth : "100%"}}>	
			    <h1 style={{ fontSize: "24px", fontWeight: 'normal', textAlign: 'left', padding: '3px 0' }}><b>Cash Flow Real Estate - AI</b></h1>
	  			<br/>
			    <h1 style={{ fontSize: "32px", fontWeight: 'lighter', textAlign: 'left', padding: '0 0' }}>Identify Your Next</h1>
	  		    <h1 style={{ fontSize: "32px", fontWeight: 'lighter', textAlign: 'left', padding: '0 0' }}>Cash Flow Property</h1>
	 		    <h1 style={{ fontSize: "32px", fontWeight: 'lighter', textAlign: 'left', padding: '0 0' }}>with</h1>
	  		    <h1 style={{ fontSize: "32px", fontWeight: 'normal', textAlign: 'left', padding: '0 0' }}><b>Confidence</b></h1>
	  			<br />
				<p style={{maxWidth : "60%"}}>Quickly and easily identify positive cash flow properties using your personal preferences.
	  			Sort and compare listings using AI-driven analytics for in-depth insights.
	  			Supercharge your search with speed and accuracy only achievable with AI.</p>
	  			<br />
			    <Button href="/user/signup" variant="primary" style={buttonStyle}><b>Start Your Free Trial</b></Button>
	  		</Container>
	  		</div>
		</div>
	    </Container>
		    <div style={{ flex: '1' }}>
			<img style={banner_style} src={hero_image} />
			</div>
<br/>
<br/>
<br/>
<hr/>
<br/>
<br/>
<br/>
	<div>
      		<h1 style={{ fontSize: "28px", fontWeight: 'lighter'}}><b>Data-Driven Insights and Strategies</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Real-Time Market Data</h2>
		<p>Stay up-to-date with the latest market trends, rental rates, and cash flow metrics to gain a competitive advantage.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Advanced AI-Powered Search</h2>
		<p>Leverage AI-driven algorithms to find the best investment properties based on your preferences.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Customizable Search Criteria</h2>
		<p>Sort your desired metrics, such as cash flow, debt coverage ratio, property type, price range, and more, to receive tailored results.</p>
		</Container>
	      </div>
	  </div>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_pos_snip} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	<br/>
	<br/>
      		<h1 style={{ fontSize: "28px", fontWeight: 'lighter'}}><b>Detailed Cash Flow Analysis</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Informed Decision Making</h2>
		<p>Make better investment decisions based on accurate cash flow data, helping you identify high-performing properties and minimize risk.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Optimize Property Performance</h2>
		<p>Identify areas for improvement in your property's cash flow, such as reducing expenses or increasing rental income, to maximize your returns.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Compare Properties Effortlessly</h2>
		<p>Use the cash flow breakdown to compare multiple investment properties, ensuring you choose the one with the highest potential for strong, positive cash flow.</p>
		</Container>
	      </div>
	  </div>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_detailed_view} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	<br/>
	<br/>
      		<h1 style={{ fontSize: "24px", fontWeight: 'lighter'}}><b>Comprehensive ROI Breakdown</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Full ROI Analysis</h2>
		<p>Assess the true profitability of your potential investment with an in-depth breakdown of property ROI, factoring in income, expenses, and appreciation.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Plan For Years Ahead</h2>
		<p>Understand where you are now, and where you want to be years down the line. See how an investment today will perform in the future.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Instantly Get The Full Picture</h2>
		<p>No more juggling multiple moving pieces in your head, let our algorithms take the load off.</p>
		</Container>
	      </div>
	  </div>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_roi} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	<br/>
	<br/>
      		<h1 style={{ fontSize: "28px", fontWeight: 'lighter'}}><b>Maximize Your Rental Income</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>AI-Driven Rent Estimates</h2>
		<p>Leverage advanced AI algorithms to receive accurate and up-to-date rent estimates for your potential investments based on local market trends.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Comparative Market Analysis</h2>
		<p>Evaluate a property's rental potential by comparing it with similar properties in the area, ensuring competitive pricing.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Property-Specific CFRE-AI Score</h2>
		<p>Get more than just a rent price. See how that price was determined and why. Understand where the property falls within others like it.</p>
		</Container>
	      </div>
	  </div>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_analytics} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	  </div>
	<br/>
	<br/>
      		<h1 style={{ fontSize: "28px", fontWeight: 'lighter'}}><b>Minimize Risk of Loss</b></h1>
	<br/>
	  <div style={{ display: 'flex'}}>
	      <div style={{ flex: 2, marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Avoid Costly Mistakes</h2>
		<p>Not every property is a profitable investment. Only see the ones you want and sort away the rest.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Test Different Inputs</h2>
		<p>See how your preferences affect cash flow. There is no one-size-fits-all property for everyone. A negative situation for one may be a positive situation for another.</p>
		</Container>
	  	<br/>
	  	<Container style={{maxWidth : "70%", textAlign : "left"}}>
		<h2>Take Away The Guesswork</h2>
		<p>If you could have the ability to make more-informed decisions, why wouldn't you use it? Give yourself the clarity to move forward with confidence.</p>
		</Container>
	      </div>
	  </div>
	      <div style={{ flex: 3 , margin: '20px', boxSizing: 'border-box'}}>
		  <img src={cfre_neg_snip} style={{ width: '95%', height: 'auto', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', }} />
	      </div>
	<br/>
	<br/>
	  	<div className="text-center">
		      <Button href="https://calendly.com/cfre-ai" variant="primary" style={buttonStyle}><b>Start Your Free Trial</b></Button>
	  	</div>
   </div>
	  Disclaimer: This does not constitute investment advice and we are not selling an investment opportunity. We make no guarantee of returns, we only provide our platform for your analysis. It is your responsibility to verify all information.
   </>
  );
}

export default Home;
