import React, { Component } from 'react';
//import './App.css';
import "./css/display.css"
import axios from 'axios';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Alert } from 'react-bootstrap'
import { Button } from "react-bootstrap"
import ManageUsers from "./routes/admin/ManageUsers"
import AccountControl from "./routes/admin/AccountControl"
import ReportedListings from "./routes/admin/ReportedListings"
import QuarantinedListings from "./routes/admin/QuarantinedListings"

import Demo from "./routes/Demo"
import Dashboard from "./routes/user/Dashboard"
import Amortization from "./routes/user/Amortization"
import AddressDetails from "./routes/user/AddressDetails"
import Category from "./routes/user/Category"
import CategorySearch from "./routes/user/CategorySearch"
import Navbar from './components/navbar'
import SignUp from './components/signup'
import LogIn from './components/login'
import Account from './routes/account/Account'
import AuthContext from "./AuthContext"
import Home from "./routes/user/Home"
import Landing from "./routes/user/Landing"
import LoanLanding from "./components/LoanLanding"
import ForgotPassword from "./components/forgot_password"
import ResetPassword from "./components/reset_password"
import EmailUnsubscribe from "./components/email_unsubscribe"
import ContactForm from "./components/contact_form"
import TermsAndConditions from "./legal/terms"
import AssetCredits from "./legal/asset_credits"
import PrivacyPolicy from "./legal/privacy"
import SubscriptionDisclaimer from "./legal/subscription_disclaimer"
import RentDataPage from "./components/cfre_ai_rent"
import Pricing from "./components/pricing"
import AboutUs from "./routes/user/AboutUs"
import Partnerships from "./routes/user/Partnerships"

import Footer from "./components/footer"
import Subscribe from "./components/subscribe"
import PaymentSuccess from "./components/payment_success"
import ManageSubscription from "./components/manage_subscription"


import RemaxLandingPage from "./components/remax"

class App extends Component
{
	state = { isAuth : false,
		  isAdmin  : false,
		  member_type : "trial",
		  stripe_api_key : process.env.REACT_APP_STRIPE_PUBLIC_KEY
		}

static contextType = AuthContext


componentDidMount() {

	this.setLoggedIn()
}

setLoggedIn() {
	axios.get("/api/get_session/", {
            params: ""

        }).then((response) => {
            if ("logged_in" in response.data.session && response.data.session.logged_in) {
                    this.setState({ isAuth : true })
	    }
	    if ("is_admin" in response.data.session && response.data.session.is_admin) {
		    this.setState({ isAdmin : true })
            }
	    if ("member_type" in response.data.session) {
		    this.setState({ member_type : response.data.session.member_type })
            }
    })
}

trial_banner_inject = () => {
const buttonStyle = {
      "min-width": '250px',
        fontSize: '19px',
        textAlign : "left",
        fontWeight : "lighter",
          boxShadow: "0 2px 4px rgba(0,0,0,0.3)"
  };
	if(this.state.member_type == "trial") {
		return (
			<>
			<Alert variant="warning">
				Account Inactive, information will not be visible.
			<br/>
			<br/>
                        <Button href="/user/subscribe" variant="info" style={buttonStyle}><b style={{color : "white", fontWeight : "bold"}}>Start Your Free Trial >></b></Button>
 
			</Alert>
			<br/>
			</>
		)
	}
};


render() {
	//if (!this.state.isAuth) {
	//	this.setLoggedIn()
	//}
	if (!this.state.isAuth) {
	return (
      <div className='App'>
	  <BrowserRouter>
		{<Navbar auth={this.state.isAuth} admin={this.state.isAdmin} />}

          <Routes>
            <Route path="/" element=<Home /> />
	    <Route path="/user/signup/" element={<SignUp />} />
	    <Route path="/user/login/" element={<LogIn />} />
	    <Route path="/loan/landing/" element={<LoanLanding />} />
	    <Route path="/about_us/" element={<AboutUs />} />
	    <Route path="/partnerships/" element={<Partnerships />} />
	    <Route path="/landing/" element={<Landing />} />
	    <Route path="/user/forgot_password/" element={<ForgotPassword />} />
	    <Route path="/user/reset_password/" element={<ResetPassword />} />
	    <Route path="/user/email_unsubscribe/" element={<EmailUnsubscribe />} />
	    <Route path="/user/contact_form/" element={<ContactForm />} />
	    <Route path="/user/terms_and_conditions/" element={<TermsAndConditions />} />
	    <Route path="/user/privacy_policy/" element={<PrivacyPolicy />} />
	    <Route path="/user/subscription_disclaimer/" element={<SubscriptionDisclaimer />} />
	    <Route path="/user/cfre_ai_rent/" element={<RentDataPage />} />
	    <Route path="/pricing/" element={<Pricing stripe_api_key={this.state.stripe_api_key} />} />
	    
	    

	    <Route path="/remax/" element={<RemaxLandingPage />} />
          </Routes>
			{<Footer />}
        </BrowserRouter>
	  </div>
  	);
	} else {
	    
	    if (!this.state.isAdmin) {
		return (
		    <div className='App'>
			<BrowserRouter>
			    <Navbar auth={this.state.isAuth} member_type={this.state.member_type} admin={this.state.isAdmin} />
			  {this.trial_banner_inject()}  
				<Routes>
				    <Route path="/" element=<Home /> />
	    			    <Route path="/about_us/" element={<AboutUs />} />
	    			    <Route path="/partnerships/" element={<Partnerships />} />
				    <Route path="/dashboard/" element=<Dashboard /> />
				    <Route path="/demo/" element=<Demo category="city" /> />
				    <Route path="/account/" element=<Account stripe_api_key={this.state.stripe_api_key}/> />		
				    <Route path="/logout/" element=<LogIn action="logout"/> />		
				    <Route path="/amortization/" element=<Amortization /> />	
				    <Route path="/states/" element=<Category category="state"/> />
			    	    <Route path="/logical_areas/" element=<Category category="logical_area"/> />
				    <Route path="/cities/" element=<Category category="city"/> />
				    <Route path="/zipcodes/" element=<Category category="zipcode"/> />
				    <Route path="/counties/" element=<Category category="county" /> />
				    <Route path="/address-details/" element=<AddressDetails /> />
				    <Route path="/states/statesearch/" element=<CategorySearch category="state" /> />
			    	    <Route path="/logical_areas/logical_area_search/" element=<CategorySearch category="logical_area" /> />
				    <Route path="/cities/citysearch/" element=<CategorySearch category="city" /> />
				    <Route path="/zipcodes/zipcodesearch/" element=<CategorySearch category="zipcode" /> />
				    <Route path="/counties/countysearch/" element=<CategorySearch category="county" /> />
				    <Route path="/loan/landing/" element={<LoanLanding />} />
	    			    <Route path="/user/email_unsubscribe/" element={<EmailUnsubscribe />} />
	    			    <Route path="/user/contact_form/" element={<ContactForm />} />
	    			    <Route path="/user/terms_and_conditions/" element={<TermsAndConditions />} />
	    			    <Route path="/user/asset_credits/" element={<AssetCredits />} />
	    			    <Route path="/user/privacy_policy/" element={<PrivacyPolicy />} />
	    			    <Route path="/user/subscription_disclaimer/" element={<SubscriptionDisclaimer />} />
	    			    <Route path="/user/subscribe/" element={<Subscribe stripe_api_key={this.state.stripe_api_key} />} />
	    			    <Route path="/user/payment_success/" element={<PaymentSuccess />} />
	    			    <Route path="/user/cfre_ai_rent/" element={<RentDataPage />} />
	    			    
				  </Routes>
			{<Footer />}
			</BrowserRouter>
		    </div>
	  	);
	    } else {
		return (
		      <div className='App'>
			  <BrowserRouter>
			  <Navbar auth={this.state.isAuth} admin={this.state.isAdmin} />
			<Routes >
			<Route path="/" element=<Home /> />
			    <Route path="/demo/" element=<Demo category="city" /> />
	    		    <Route path="/about_us/" element={<AboutUs />} />
	    		    <Route path="/partnerships/" element={<Partnerships />} />
			    <Route path="/dashboard/" element=<Dashboard /> />
			    <Route path="/account/" element=<Account stripe_api_key={this.state.stripe_api_key}/> />		
			    <Route path="/logout/" element=<LogIn action="logout"/> />		
			    <Route path="/amortization/" element=<Amortization /> />	
			    <Route path="/states/" element=<Category category="state"/> />
			    <Route path="/logical_areas/" element=<Category category="logical_area"/> />
			    <Route path="/cities/" element=<Category category="city"/> />
			    <Route path="/zipcodes/" element=<Category category="zipcode"/> />
			    <Route path="/counties/" element=<Category category="county" /> />
			    <Route path="/address-details/" element=<AddressDetails /> />
			    <Route path="/states/statesearch/" element=<CategorySearch category="state" /> />
			    <Route path="/logical_areas/logical_area_search/" element=<CategorySearch category="logical_area" /> />
			    <Route path="/cities/citysearch/" element=<CategorySearch category="city" /> />
			    <Route path="/zipcodes/zipcodesearch/" element=<CategorySearch category="zipcode" /> />
			    <Route path="/counties/countysearch/" element=<CategorySearch category="county" /> />
			    <Route path="/loan/landing/" element={<LoanLanding />} />
	    		    <Route path="/user/email_unsubscribe/" element={<EmailUnsubscribe />} />
	    		    <Route path="/user/contact_form/" element={<ContactForm />} />
	    		    <Route path="/user/cfre_ai_rent/" element={<RentDataPage />} />
			    
			    <Route path="/admin/manage-users/" element={<ManageUsers />} />
			    <Route path="admin/account-control/" element={<AccountControl />} />
			    <Route path="admin/reported-listings/" element={<ReportedListings />} />
			    <Route path="admin/quarantined-listings/" element={<QuarantinedListings />} />
	    		    
			    <Route path="/user/terms_and_conditions/" element={<TermsAndConditions />} />
	    		    <Route path="/user/asset_credits/" element={<AssetCredits />} />
	    		    <Route path="/user/privacy_policy/" element={<PrivacyPolicy />} />
	    		    <Route path="/user/subscription_disclaimer/" element={<SubscriptionDisclaimer />} />

			    

			</Routes>
			{<Footer />}
			</BrowserRouter>
			  </div>
		  );


	    }
	}
	
}
}
App.contextType = AuthContext;
export default App;
