import React, { useState, useContext } from 'react';
import '../css/styles.css'
import axios from 'axios';
import AuthContext from '../AuthContext';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';



export default function LogIn(props) {


	const {updateAuth} = useContext(AuthContext)

	// States for registration
	const [email_address, setEmail] = useState('');
	const [password, setPassword] = useState('');

	// States for checking the errors
	const [submitted, setSubmitted] = useState(false);
	const [error, setError] = useState(false);
	const [login_failed, setLoginFailed] = useState(false);
	const [account_locked, setAccountLocked] = useState(false);
	const [account_admin_locked, setAccountAdminLocked] = useState(false);
	const [login_success, setLoginSuccess] = useState(false);
	const [creds_missing, setCredsMissing] = useState(false);
	// Handling the email change
	const handleEmail = (e) => {
	  setEmail(e.target.value);
	  setSubmitted(false);
	};

	// Handling the password change
	const handlePassword = (e) => {
	  setPassword(e.target.value);
	  setSubmitted(false);
	};

	const clearMessages = () => {

		setError(false)
		setCredsMissing(false)
		setLoginFailed(false)
		setLoginSuccess(false)
		setAccountLocked(false)
		setAccountAdminLocked(false)
	}

	// Handling the form submission
	const handleSubmit = (e) => {
	  clearMessages()
	  e.preventDefault();
	  if ( email_address === '' || password === '') {
	    setCredsMissing(true);
	  } else {
	    setSubmitted(true);
	    setError(false);
	    axios.post("/api/user/login/", { 
		    email_address : email_address,
		    password : password
		})
      		.then((response) => {
          		if (response.status == 200) {
				if (response.data["code"] == 0) {
					setLoginSuccess(true)
				} else if (response.data["code"] == 1) {
					setLoginFailed(true)
				} else if (response.data["code"] == 5) {
					setAccountLocked(true)
				} else if (response.data["code"] == 6) {
					setAccountAdminLocked(true)
					
				}
				if ("redirect" in response.data) {
					window.location = response.data["redirect"]
				}
			}
			return
          		})
      		}

	}
        
	const logoutSuccessMessage = () => {
                if(window.location["pathname"].includes("logout")) {
                        return (
				<>
                                <Alert variant="success">
                                        <a>
                                        Successfully Logged Out
                                        </a>
                                </Alert>
				<br/>
				</>
                        )
                }
        };

	// Showing Login Failed Error
        const loginFailedMessage = () => {
                if(login_failed) {
                        return (
				<>
                                <Alert variant="danger" onClose={() => setLoginFailed(false)} dismissible>
                                        <a>
                                        Incorrect Username or Password Provide
                                        </a>
                                </Alert>
				<br/>
				</>
                        )
                }
        };
        const accountLockedMessage = () => {
                if(account_locked) {
                        return (
				<>
                                <Alert variant="danger" onClose={() => setAccountLocked(false)} dismissible>
                                        <a>
                                        Account has been locked for the user's protection, please reset password using Forgot My Password, or contact support.
                                        </a>
                                </Alert>
				<br/>
				</>
                        )
                }
        };
        
        const accountAdminLockedMessage = () => {
                if(account_admin_locked) {
                        return (
				<>
                                <Alert variant="danger" onClose={() => setAccountAdminLocked(false)} dismissible>
                                        <a>
                                        Account has been locked, please contact support.
                                        </a>
                                </Alert>
				<br/>
				</>
                        )
                }
        };
        
	const loginSuccessMessage = () => {
                if(login_success) {
                        return (
				<>
                                <Alert variant="success">
                              	        <a>Login Successful</a>
                                </Alert>
				<br/>
				</>
                        )
                }
        };

	const credsMissing = () => {
                if(creds_missing) {
                        return (
				<>
                                <Alert variant="danger" onClose={() => setCredsMissing(false)} dismissible>
                                        <a>
                                        One Or More Fields Blank
                                        </a>
                                </Alert>
				<br/>
				</>
                        )
                }
        };
        
	const errorMessage = () => {
                if(error) {
                        return (
				<>
                                <Alert variant="warning" onClose={() => setError(false)} dismissible >
                              	        <a>Error Encountered</a>
                                </Alert>
				<br/>
				</>
                        )
                }
        };
	
	if (props.action == "logout") {

	   axios.get("/api/user/logout/", { 
		   	param: ""
		})
		.then((response) => {
			if ("redirect" in response.data) {
					window.location = response.data["redirect"]
				
			}
		})
		}

	return (
		<>
	{loginSuccessMessage()}
	{loginFailedMessage()}
	{accountLockedMessage()}
	{accountAdminLockedMessage()}
	{errorMessage()}
	{credsMissing()}
	{logoutSuccessMessage()}
	<div className="card-wrapper">
	<div className="card-wrapper">
	  <div className="card">
	    <h1 className="Create an Account"></h1>

	    <Form name="signup_form">
	      {/* Labels and inputs for form data */}
		
		<label className="label">Email</label>
		<input onChange={handleEmail} className="field" value={email_address} type="email" />

		<label className="label">Password</label>
		<input onChange={handlePassword} className="field" value={password} type="password" />

		<Button onClick={handleSubmit} className="btn" type="submit">
		Log In
		</Button>
	    </Form>
		<a href="/user/forgot_password">Need a Password Reset?</a>
	  </div>
	</div>

	  </div>
		</>
	);
}
