import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { Form, Button, Container, Card, Row, Col, Table, Alert, Modal } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import User_vars from '../../components/user_vars'
import navbar from '../../components/navbar'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

class ReportedListings extends Component
{
		state = {
		total_entries: 0,
		total_pages: 0,
		current_page: 1,
		records_shown: 20,
		index: 0,
		hit_top : false,
		hit_bottom : false,
		
		sortProfitChecked: false,
		records: [],
		all_records : ["test"],
		active_state: "",
		users : [],
		search_text: "",
		dismiss_report_success : false,
		quarantine_listing_success : false,
		quarantine_listing_modal_open : false,
		dismiss_report_modal_open : false,
		active_modal : "",

		csrf_token: ""
		}


dismissReport = (report_id) => {
  let params_dict = {
	report_id : report_id,
	  csrf_token : this.state.csrf_token
  }
  axios.post("/api/admin_dismiss_reported_listing/", {
	  params : params_dict
  }).then((response) => {
	  console.log(response)
          if (response.data.code == 0) {
		  console.log("Dismissed")
         	  this.setState({
			dismiss_report_success : true,
			dismiss_report_modal_open : false,
			active_modal : ""
		  }, this.queryReportedListings())
	  } else {
		  console.log("Error")
	  }
  })
}

quarantineListing = (x) => {

  let params_dict = {
	report_id : x["_id"],
	prop_id   : x["prop_id"],
	address   : x["address"],
	city	  : x["city"],
	code	  : x["code"],
	zipcode   : x["zipcode"],
	reporter_id : x["reporter_id"],
	reported_time : x["epoch_time"].toString(),
	message   : x["message"],
	  csrf_token : this.state.csrf_token

  }
  axios.post("/api/admin_quarantine_reported_listing/", {
	  params : params_dict
  }).then((response) => {
          if (response.data.code == 0) {
		  console.log("Quarantined")
         	  this.setState({
			quarantine_listing_success : true,
			quarantine_listing_modal_open : false,
			active_modal : ""
		  }, this.queryReportedListings())
	  } else {
		  console.log("Error")
	  }
  })

}

openQuarantineListingModal = (report_id) => {
          this.setState({
                quarantine_listing_modal_open : true,
                active_modal : report_id
          })
}

closeQuarantineListingModal = () => {
          this.setState({
                quarantine_listing_modal_open : false,
                active_modal : ""
          })
}

openDismissReportModal = (report_id) => {
          this.setState({
                dismiss_report_modal_open : true,
                active_modal : report_id
          })
}

closeDismissReportModal = () => {
          this.setState({
                dismiss_report_modal_open : false,
                active_modal : ""
          })
}

set_search_text = (event) => {
    let search_text = event.target.name;
    let val = event.target.value
    this.setState({
      search_text: val
  })
}

pageUp = (total_entries) => {
        const index = this.state.index
        const records_shown = this.state.records_shown
        if ((index + records_shown) < total_entries) {
                const new_index = index + records_shown
                const current_page =  Math.floor(new_index / this.state.records_shown) + 1
                this.setState({ index : new_index,
                                current_page : current_page,
                                hit_bottom : false
                })
        } else {
                console.log(true)
                this.setState({ hit_top : true })
        }
}

pageDown = (total_entries) => {
        const index = this.state.index
        const records_shown = this.state.records_shown
        if ((index - records_shown) >= 0) {
                const new_index = index - records_shown
                const current_page =  Math.floor(new_index / this.state.records_shown) + 1
                this.setState({ index : new_index ,
                                current_page : current_page,
                                hit_top : false
                                })
        } else {
                this.setState({ hit_bottom : true,
                                index : 0
                })
        }
}

changeItemCount = (value) => {

        const records_shown = parseInt(value)
        const paged_records = this.state.records.slice(this.state.index, this.state.index + records_shown)
        const total_pages = Math.floor(this.state.records.length / records_shown) + 1
        const current_page = Math.floor(this.state.index / records_shown) + 1
        this.setState({
                records_shown : records_shown,
                paged_records : paged_records,
                current_page : current_page,
                total_pages : total_pages,
        })
}

sortByKey(array, key, parse, rev) {
        let arr = array.sort(function(a, b) {
                if (parse == "float") {
                        var x = parseFloat(a[key]); var y = parseFloat(b[key]);
                } else {
                        var x = a[key]; var y = b[key];
                }
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        })
        if (rev) {
                return arr.reverse();
        } else {
                return arr
        }
}

searchRecords = (value) => {

	if (value == "") {

		this.setState({
			
		})
	}
	this.setState({
		distance : parseInt(value)
	})
}
	
queryReportedListings = () => {
  axios.get("/api/admin_retrieve_reported_listings/", { 
  }).then((response) => {
          this.setState({
              records : response.data.records,
		csrf_token : response.data.csrf_token
          })
	  console.log(response.data)
      })
}

initialize() {

	this.queryReportedListings()
      
}

componentDidMount() {
	this.initialize()	

}

usdFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
})

search_filter(user) {
	if (!this.state.search_text == "") {
		const search_text = this.state.search_text
		for (let field in user) {
			if (typeof(user[field]) == "string" && user[field].includes(search_text)) {
				return true
			}
		}
		return false
	}
	return true
}

render() {

	const dismiss_report_success_banner = () => {
                if (this.state.dismiss_report_success) {
                        return (
                                <>
                                <Alert variant="success" onClose={() => this.setState({dismiss_report_success : false})} dismissible>
                                        <a>Listing Report Dismissed</a>
                                </Alert>
                                <br/>
                                </>
                        )
                }
        };
	
	const quarantine_listing_success_banner = () => {
                if (this.state.quarantine_listing_success) {
                        return (
                                <>
                                <Alert variant="success" onClose={() => this.setState({quarantine_listing_success : false})} dismissible>
                                        <a>Listing Quarantined</a>
                                </Alert>
                                <br/>
                                </>
                        )
                }
        };

	const quarantine_listing_inject = (x) => {
                if (this.state.quarantine_listing_modal_open && this.state.active_modal == x["_id"]) {
                        return(
                                <Modal show={this.state.quarantine_listing_modal_open} onHide={() => this.closeQuarantineListingModal()}>
                                        <Modal.Header closeButton>
                                                <Modal.Title>Quarantine Listing</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
						<ul>
							<li><b>Address:</b>&nbsp;{x["address"]}</li>
							<li><b>City:</b>&nbsp;{x["city"]}</li>
							<li><b>State:</b>&nbsp;{x["code"]}</li>
						</ul>
					
                                        </Modal.Body>
                                        <Modal.Footer>

                                                <Button className='my-2' variant="secondary" onClick={() => this.closeQuarantineListingModal()}>Cancel</Button>
                                                <Button className='my-2' variant="danger" onClick={() => this.quarantineListing(x)}>Quarantine Listing</Button>
                                        </Modal.Footer>
                                </Modal>
                        )
                }
        }
	
	const dismiss_listing_inject = (x) => {
		console.log(this.state)
                if (this.state.dismiss_report_modal_open && this.state.active_modal == x["_id"]) {
                        return(
                                <Modal show={this.state.dismiss_report_modal_open} onHide={() => this.closeDismissReportModal()}>
                                        <Modal.Header closeButton>
                                                <Modal.Title>Dismiss Report</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
						<ul>
							<li><b>Address:</b>&nbsp;{x["address"]}</li>
							<li><b>City:</b>&nbsp;{x["city"]}</li>
							<li><b>State:</b>&nbsp;{x["code"]}</li>
						</ul>
						
                                        </Modal.Body>
                                        <Modal.Footer>

                                                <Button className='my-2' variant="secondary" onClick={() => this.closeDismissReportModal()}>Cancel</Button>
                                                <Button className='my-2' variant="danger" onClick={() => this.dismissReport(x["_id"])}>Dismiss Listing</Button>
                                        </Modal.Footer>
                                </Modal>
                        )
                }
        }

	const queryParams = new URLSearchParams(window.location.search);
	const Checkbox = ({ label, value, onChange }) => {
  		return (
    		<label>
      		<input type="checkbox" checked={value} onChange={onChange} />
      		&nbsp;{label}
    		</label>
  		);
	};

	const list_records = this.state.records.filter(user => this.search_filter(user))

	const paged_records = list_records.slice(this.state.index, this.state.index + this.state.records_shown).map(x => {
		
		const report_id = x["_id"]
		const prop_id = x["prop_id"]
		const address = x["address"]
		const city = x["city"]
		const zipcode = x["zipcode"]
		const code = x["code"]
		let datetime = new Date(parseFloat(x["epoch_time"]) * 1000)
                //let sale_status_date = new Date(parseFloat(x["sale_status_date"]))
                datetime = (datetime.getMonth() + 1).toString() + "/" + datetime.getDate().toString() + "/" + datetime.getFullYear().toString()

		const reporter_id = x["reporter_id"]
		const message = x["message"]
		const quarantined = x["quarantined"].toString()
		
		return(
			<>
		 {quarantine_listing_inject(x)}
		 {dismiss_listing_inject(x)}
		   <Table striped bordered>
			<tbody>
			<tr>
				<th>Address</th>
				<th>City</th>
				<th>State</th>
				<th>Zipcode</th>
			</tr>
	  		<tr>
				<td>{address}</td>
				<td>{city}</td>
				<td>{code}</td>
				<td>{zipcode}</td>
	  		</tr>
			<tr>
				<th>Quarantined</th>
				<th>Reporter ID</th>
				<th>Reported Date</th>
				<th>Actions</th>
			</tr>
	  		<tr>
				<td>{quarantined}</td>
				<td>{reporter_id}</td>
				<td>{datetime}</td>
				<td><Button className='my-2' variant="info" onClick={() => {this.openDismissReportModal(x["_id"])}}>Dismiss</Button>&nbsp;&nbsp;
				<Button className='my-2' variant="danger" onClick={() => {this.openQuarantineListingModal(x["_id"])}}>Quarantine</Button></td>
	  		</tr>
			<tr>
				<th colSpan={4}>Message</th>
			</tr>
	  		<tr>
				<td colSpan={4}>{message}</td>
	  		</tr>
			  </tbody>
		  </Table>
			</>
		)
	})

const inputStyle = {
      //"font-size" : "10px",
      //"height" : "100px",
      "width" : "300px"
}

  return (
	<div className='App'>
	 {dismiss_report_success_banner()}
	 {quarantine_listing_success_banner()}
	<Card style={{"width" : "100%"}}>
	  <Container fluid >
	  <Row md="auto">
	  <Col md={2}>
	  {list_records.length}&nbsp;Submissions Found <br/>
	  </Col>
	  </Row>
	  <Row md="auto">
	  <Col md={2}>
	  <style>{"table{ border:1px solid black; width:100%; table-layout:fixed;}"}</style>
		
		<Form>
			<Form.Control
				type="search"
				placeholder="Search"
				className="me-2"
				aria-label="Search"
				value={this.state.search_text}
				onChange={this.set_search_text}
			/>
		</Form>

	</Col>
	<Col>
	<Dropdown
		as={ButtonGroup}	
		title="Dropdown-items-shown"
		onSelect={this.changeItemCount}>
		<Dropdown.Toggle variant="success" id="dropdown-basic">
		{this.state.records_shown}&nbsp;&nbsp;Per Page
      		</Dropdown.Toggle>
      		<Dropdown.Menu>
        		<Dropdown.Item eventKey="20">20</Dropdown.Item>
        		<Dropdown.Item eventKey="50">50</Dropdown.Item>
        		<Dropdown.Item eventKey="100">100</Dropdown.Item>
      		</Dropdown.Menu>
    	</Dropdown>&nbsp;&nbsp;
	</Col>
	</Row>
	</Container>
	</Card>
	<div className="table-wrapper">
	<Card style={{"width" : "100%"}}>
	<br/>
	<>  
	</>
	{paged_records}
	</Card>
      	</div>
		<Button className='my-2' variant="primary" onClick={() => {this.pageDown(list_records.length)}}>Prev</Button>&nbsp;&nbsp;
		<Button className='my-2' variant="primary" onClick={() => {this.pageUp(list_records.length)}}>Next</Button>&nbsp;&nbsp;
		<a>Page {this.state.current_page} of {Math.floor(list_records.length / this.state.records_shown) + 1}</a>
	  </div>
  );
}
}

export default ReportedListings;
