import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { Button, Container, Card, Row, Col, Table, Form, Offcanvas } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import User_vars from '../../components/user_vars'
import navbar from '../../components/navbar'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import SpinnerLoading from '../../components/spinner.js';
import AddressDetails from "./AddressDetails";

class Dashboard extends Component
{
	state = {
		total_entries: 0,
		total_pages: 0,
		current_page: 1,
		records_shown: 20,
		distance: 0,
		index: 0,
		hit_top : false,
		hit_bottom : false,
		
		showUserVars : false,
		down_pmt: '20',
		renovation: '10',
		close_cost: '2',
		cost_of_sale: '4.5',
		loan_apr: '5',
		mgmt_fee: '0',
		mnth_vacant: '1',
		rent_annual_inc: '5',
		depreciation: '27.5',
		appreciation: '5',
		income_tax_rate: '30',
		cap_gains_tax_rate: '15',
		term: '30',
		condo_fee: '300',
		renovation_add: '70',

		sortProfitChecked: true,
		records: [],
		paged_records : [],
		search_text: "",
		active_state: "",

		show_address_details: false,
		active_state: "",
		prop_id: "",
	}

set_user_vars = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    this.setState({
    	[nam]: val
	})
}

set_search_text = (event) => {
    let search_text = event.target.name;
    let val = event.target.value
    this.setState({
    	search_text: val
  })
}

hideUserVars = () => {
	this.setState({
    	showUserVars : false
	})
}

showUserVars = () => {
	this.setState({
		showUserVars : true
	})
}

submit_user_vars = () => {
	const param_dict = {
		"down_pmt" : this.state.down_pmt.toString(),
		"renovation" : this.state.renovation.toString(),
		"close_cost" : this.state.close_cost.toString(),
		"cost_of_sale" : this.state.cost_of_sale.toString(),
		"loan_apr" : this.state.loan_apr.toString(),
		"mgmt_fee" : this.state.mgmt_fee.toString(),
		"mnth_vacant" : this.state.mnth_vacant.toString(),
		"rent_annual_inc" : this.state.rent_annual_inc.toString(),
		"depreciation" : this.state.depreciation.toString(),
		"appreciation" : this.state.appreciation.toString(),
		"income_tax_rate" : this.state.income_tax_rate.toString(),
		"cap_gains_tax_rate" : this.state.cap_gains_tax_rate.toString(),
		"term" : this.state.term.toString(),
		"condo_fee" : this.state.condo_fee.toString(),
		"renovation_add" : this.state.renovation_add.toString(),
		"records_shown" : this.state.records_shown.toString(),
		"index" : this.state.index.toString(),
	}
	axios.post("/api/save_user_vars/", { 
		params : param_dict
  	}).then(() => {
		this.queryFavorites()
  	})
}

sortByKey(array, key, parse, rev) {
    let arr = array.sort(function(a, b) {
		if (parse == "float") {
        	var x = parseFloat(a[key]); var y = parseFloat(b[key]);
		} else {
        	var x = a[key]; var y = b[key];
		}
    	return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    })
	if (rev) {
		return arr.reverse();
	} else {
		return arr
	}
}

setSortProfit = () => {
	let records = []
	if (!this.state.sortProfitChecked) {
		records = this.sortByKey(this.state.records, "year_nom_cf", "float", true)
	} else {
		records = this.sortByKey(this.state.records, "year_nom_cf", "float", false)
	}
 	this.setState({
		sortProfitChecked : !this.state.sortProfitChecked,
  		records : records,
  	})
}

componentDidUpdate(prevProps, prevState) {	
	if (prevState.distance !== this.state.distance) {
		this.queryCategory()
	}
}

pageUp = (total_entries) => {
	const index = this.state.index
	const records_shown = this.state.records_shown
	if ((index + records_shown) < total_entries) {
		const new_index = index + records_shown
		const current_page = Math.floor(new_index / this.state.records_shown) + 1
		this.setState({ index : new_index,
			current_page : current_page,
			hit_bottom : false
		})
	} else {
		this.setState({ hit_top : true })
	}
}

pageDown = (total_entries) => {
	const index = this.state.index
	const records_shown = this.state.records_shown
	if ((index - records_shown) >= 0) {
		const new_index = index - records_shown
		const current_page =  Math.floor(new_index / this.state.records_shown) + 1
		this.setState({ index : new_index ,
			current_page : current_page,
			hit_top : false
		})
	} else {
		this.setState({ hit_bottom : true,
			index : 0		
		})
	}
}

changeItemCount = (value) => {
	const records_shown = parseInt(value)
	const paged_records = this.state.records.slice(this.state.index, this.state.index + records_shown)
	const total_pages = Math.floor(this.state.records.length / records_shown) + 1
	const current_page = Math.floor(this.state.index / records_shown) + 1
	this.setState({
		records_shown : records_shown, 
		paged_records : paged_records,
		current_page : current_page,
		total_pages : total_pages
	})
}

changeDistance = (value) => {
	this.setState({
		distance : parseInt(value)
	})
}

queryFavorites = () => {
	this.setState({
		loaded : false
	})
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("state")
	this.setState({
			active_state : code
	})
	const param_dict = {
		"down_pmt" : this.state.down_pmt.toString(),
		"renovation" : this.state.renovation.toString(),
		"close_cost" : this.state.close_cost.toString(),
		"cost_of_sale" : this.state.cost_of_sale.toString(),
		"loan_apr" : this.state.loan_apr.toString(),
		"mgmt_fee" : this.state.mgmt_fee.toString(),
		"mnth_vacant" : this.state.mnth_vacant.toString(),
		"rent_annual_inc" : this.state.rent_annual_inc.toString(),
		"depreciation" : this.state.depreciation.toString(),
		"appreciation" : this.state.appreciation.toString(),
		"income_tax_rate" : this.state.income_tax_rate.toString(),
		"cap_gains_tax_rate" : this.state.cap_gains_tax_rate.toString(),
		"term" : this.state.term.toString(),
		"condo_fee" : this.state.condo_fee.toString(),
		"renovation_add" : this.state.renovation_add.toString(),
		"distance" : this.state.distance.toString(),
	}
  	axios.get("/api/query_favorite_listings/", { 
		params : param_dict
  	}).then((response) => {
		const records = this.sortByKey(response.data.records, "year_nom_cf", "float", true)
    	this.setState({
        	records : records,
		loaded : true
        })
    })
}

removeFromDash = (prop_id, code) => {
        const param_dict = {
                "prop_id" : prop_id,
                "code" : code,
        }
  axios.post("/api/delete_favorite_listing/", {
          params : param_dict
  }).then(
        this.initialize()
  )
}


resetValues = () => {
	this.setState({
		down_pmt: '20',
		renovation: '10',
		close_cost: '2',
		cost_of_sale: '4.5',
		loan_apr: '5',
		mgmt_fee: '0',
		mnth_vacant: '1',
		rent_annual_inc: '5',
		depreciation: '27.5',
		appreciation: '5',
		income_tax_rate: '30',
		cap_gains_tax_rate: '15',
		term: '30',
		condo_fee: '300',
		renovation_add: '70',
	})
}

initialize = () => {
	axios.get("/api/get_user_vars/", { 
		params : ""
  	}).then((response) => {
		const vars = response.data["var"]
        this.setState({
			down_pmt: vars["down_pmt"],
			renovation: vars["renovation"],
			close_cost: vars["close_cost"],
			cost_of_sale: vars["cost_of_sale"],
			loan_apr: vars["loan_apr"],
			mgmt_fee: vars["mgmt_fee"],
			mnth_vacant: vars["mnth_vacant"],
			rent_annual_inc: vars["rent_annual_inc"],
			depreciation: vars["depreciation"],
			appreciation: vars["appreciation"],
			income_tax_rate: vars["income_tax_rate"],
			cap_gains_tax_rate: vars["cap_gains_tax_rate"],
			term: vars["term"],
			condo_fee: vars["condo_fee"],
			renovation_add: vars["renovation_add"],
        }, this.queryFavorites)
	})
}

componentDidMount() {
	this.initialize()
}

usdFormat = new Intl.NumberFormat('en-US', {
	style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

search_filter(listing) {
	if (!this.state.search_text == "") {
		const search_text = this.state.search_text
		for (let field in listing["listing"]) {
			if (typeof(listing[field]) == "string" && listing[field].includes(search_text)) {
				return true
			}
		}
		return false
	}
	return true
}

load_address_details(prop_id, code) {

        this.setState({
                show_address_details : true,
		prop_id: prop_id,
                active_state: code,
        })
}


return_function() {

	this.queryFavorites()
        this.setState({
                show_address_details : false,
                prop_id: "",
        })

}


render() {
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("state")
	const Checkbox = ({ label, value, onChange }) => {
  		return (
    		<label>
      		<input type="checkbox" checked={value} onChange={onChange} />
      		&nbsp;{label}
    		</label>
  		);
	};

	const userVarsInject = () => {
		return(
			<User_vars user_vars = {this.state}
				set_user_vars = {this.set_user_vars}
				submit_user_vars = {this.submit_user_vars}
				resetValues = {this.resetValues}
				hideUserVars = {this.hideUserVars}
				stateShowUserVars = {this.state.showUserVars}
			/>
		)
	}

	const distance_inject = () => {
		if (this.state.records.length > 0) {
			if ("distance_away" in this.state.records[0]) {
				return(<th>Distance Away</th>)
			} else {
				return
			}
		}
	}

	const list_addr = this.state.records.filter(listing => this.search_filter(listing))
	const paged_addr = list_addr.slice(this.state.index, this.state.index + this.state.records_shown).map(x => {
		let listing_status = x["status"]
		const distance_away = () => {
			if ("distance_away" in x) {
				return(<td>{Number(x["distance_away"].toFixed(2))}&nbsp;Miles</td>)
			} else {
				return
			}
		}
			let prop_id = x["prop_id"]
			let address = x["address"]
			let city = x["city"]
			let county = x["county"]
			let code = x["code"]
			const zipcode = x["zipcode"]
			const raw_year_nom_cf = x["year_nom_cf"]
			let year_nom_cf = this.usdFormat.format(x["year_nom_cf"])	
			const debt_cover_ratio = x["debt_cover_ratio"]
			let sale_list_price = this.usdFormat.format(x["sale_list_price"])
			const link_addr = "/address-details/?address=" + encodeURIComponent(address) + "&city=" + city + "&code=" + code
			const link_city = "/cities/citysearch/?state=" + code + "&city=" + city
			const link_county = "/counties/countysearch/?state=" + code + "&county=" + county
			if (!("status" in x)) {
				
				address = ""
				city = ""
				county = ""
				code = ""
				sale_list_price = ""
				year_nom_cf = "Listing Removed"

			}
		return(
	  		<tr>
				<td style={{color : listing_status == "Active" ? "green" : listing_status == "ComingSoon" ? "blue" : "black"}}>{listing_status}</td>
				<td style={{color : raw_year_nom_cf >= 0 ? "green" : "red" }}>{year_nom_cf}</td>
				<td>{debt_cover_ratio}</td>
				<td>{sale_list_price}</td>
				{distance_away()}

				<td><Button variant="link" onClick={() => this.load_address_details(prop_id, code)}>{address}</Button></td>
	  			<td><a href={link_city}>{city}</a></td>
	  			<td><a href={link_county}>{county}</a></td>
				<td><Button variant="dark" onClick={() => this.removeFromDash(x["prop_id"], x["code"])}>Remove</Button></td>
	  		</tr>
		)
	})
	
	const loadedLogic = () => {
		if (!this.state.loaded) {
			return(
				<tr>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				<td><SpinnerLoading /></td>
				</tr>
			)
		} else {
			return(paged_addr)
		}
	}

	const inputStyle = {
    	//"font-size" : "10px",
    	//"height" : "100px",
      	"width" : "300px"
	}
	
	if (this.state.show_address_details) {

                //const address = this.state.active_address["Address"]
                //const city = this.state.active_address["City"]
                const code = this.state.active_state
                const prop_id = this.state.prop_id
                //const zipcode = this.state.active_address["Zip Code"]
                return (
                        <>
                        <AddressDetails prop_id={prop_id}
                                        code={code}
                                        return_function={() => this.return_function()}
                                        />
                        </>
                )
        } else {

	return (
		<div className='App'>
			<div className="table-wrapper">
				<Card style={{width : "100%"}}>
				    <Container fluid >
					<Row md="auto">
						<Col md={2}>
							{list_addr.length}&nbsp;Listings Found<br/>
						</Col>
					</Row>
					<Row md="auto">
						<Col md={2}>
							<Form>
								<Form.Control
									type="search"
									placeholder="Search"
									className="me-2"
									aria-label="Search"
									value={this.state.search_text}
									onChange={this.set_search_text}
								/>
							</Form>
						</Col>
						<Col>
							<Button variant="primary" onClick={this.showUserVars}>Show Inputs</Button>
							{userVarsInject()}
						</Col>
					</Row>
				    </Container>
				</Card>
			</div>
		<div className="table-wrapper">
			<Card style={{width : "100%"}}>
				<>  
				</>

	  			<Table responsive striped bordered hover>
					<tbody>
          				<tr>
							<th>Status</th>
	  						<th>Yearly Cash Flow</th>
							<th>Debt Coverage Ratio</th>
	  						<th>Sale List Price</th>
							{distance_inject()}
	  						<th>Address</th>
	  						<th>City</th>
	  						<th>County</th>
							<th>Action</th>
	  					</tr>
	  					{loadedLogic()}
	  				</tbody>
	  			</Table>
		</Card>
		</div>
					<Button className='my-2' variant="dark" onClick= {() => {this.pageDown(list_addr.length)}}>Prev</Button>&nbsp;&nbsp;
					<Button className='my-2' variant="dark" onClick={() => {this.pageUp(list_addr.length)}}>Next</Button>&nbsp;&nbsp;
					<a>Page {this.state.current_page} of {Math.floor(list_addr.length / this.state.records_shown) + 1}</a>&nbsp;&nbsp;&nbsp;&nbsp;
					<Dropdown as={ButtonGroup} title="Dropdown-items-shown" onSelect={this.changeItemCount}>
							<Dropdown.Toggle className='my-2' variant="dark" id="dropdown-basic">
								{this.state.records_shown}&nbsp;&nbsp;Per Page
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item eventKey="20">20</Dropdown.Item>
								<Dropdown.Item eventKey="50">50</Dropdown.Item>
								<Dropdown.Item eventKey="100">100</Dropdown.Item>
							</Dropdown.Menu>
							</Dropdown>
		</div>
  	);
	}	
	}
}

export default Dashboard;
