import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import kirill from "../../assets/images/Kirill Headshot.jpg"
import ryan from "../../assets/images/Ryan Headshot.jpg"

class AboutUs extends Component {
  render() {
    return (
      <div>
        <div className="founders-journey">
          <h1>Who We Are</h1>
	    <Container>
	    <p><b>
	    CashFlowRealEstate.ai uses Big Data and AI to evaluate, currently, 200M+ property tax records

in 7 states and identifies 40%+ ROI/COC/CAGR investment properties at scale. The algorithm

basically ranks ordering properties that have a Debt Coverage Ratio (DCR) of 2+ vs 0.1; or 5

times higher than the average DCR while using real-time data. This is a Business Intelligence

insight for institutional and retail investors via a holistic computed column that competition does

not use.

Besides cutting-edge algorithms built based on real industry expertise, CashFlowRealEstate.ai

offers a vertically integrated model with brokerage, property management, bank, settlement/title,

and home warranty. This offers an end-to-end solution that can enable investors to run a

productive process while still choosing the best properties for investment in the market.

The model currently covers seven states including VA, MD, DC, NJ, DE, West VA, PA.
	    </b></p>
	    </Container>
        </div>
	   <br/>
        <div className="team-members">
	    <Container >
          <div className="team-member" >
            <img src={kirill} alt="Team member 1" width="30%"/><br/><br/>
	    <a href="https://www.linkedin.com/in/kirillgorbounov/">LinkedIn Profile</a>
	    <br/>
	    <br/>
	    <Container>
	    <p style={{ display: "inline-block", verticalAlign: "middle", padding: "3px 0", textAlign: "left" }}><b>
          I, Kirill Gorbounov, have been working on this algorithm for 12+ years and I am grateful to have
other team members who have many decades of experience in AI/ML; as well as other high-profile partners who are supportive and willing to invest in this opportunity.
<ul>
<br/>
<li style={{"list-style-type": "none"}}>Background:</li>
<br/>
<li style={{"list-style-type": "none"}}>• Born in a small village (Chuvash 600k ethnic group) and came to US at 14;
Freshman year ESOL; Junior 7 IB courses.</li>
<li style={{"list-style-type": "none"}}>• BS Psychology from GMU in 3 years w/ 3.95 GPA and MBA from Strayer at 21 while
working 40+ hours every week; Started work at 15.</li>
<li style={{"list-style-type": "none"}}>• Master's in Real Estate – graduated in 2014 w/ entire research department of NAR
(National Association of Realtors) that reports to Senate and Congress (1st person from
VA).</li>
<br/>
<li style={{"list-style-type": "none"}}>Experience:</li>
<br/>
<li style={{"list-style-type": "none"}}>• 1600+ transaction sides in residential real estate.</li>
<li style={{"list-style-type": "none"}}>• 300+ reviews from happy clients; Self-taught in Computer Science, Statistics and
Machine Learning.</li>
<li style={{"list-style-type": "none"}}>• 17 years in RE, 15 designations, associate broker, and also 3 years in mortgage loan
origination.</li>
<br/>
<li style={{"list-style-type": "none"}}>Other:</li>
<br/>
<li style={{"list-style-type": "none"}}>• Breakdancer, a lifetime learner in technology, blockchain, geopolitics, and economics.</li>
<li style={{"list-style-type": "none"}}>• Here are Financial Education songs I wrote: <a href="https://www.youtube.com/watch?v=ZclAw5yAeFY&list=PL9sM43183vZQ1-aCu-j-YWN0aR6jYReA1">Click For Songs</a></li>
</ul>
	<br/>
	------------<br/>
Kirill Gorbounov, MRE, MBA, Associate BROKER, DPP, CDPE, SFR, BPOR, ePRO, RSPS, CNE, MRP, QSC, GREEN, SRES, CIPS, PSA, SRS, AWHD.
	<br/>
Call / Text: (571) 276-0986; Vice-President of RE Investments at Falls Realty
<br/>
--
<br/>
30 yr hard money w/ no income; credit; social. Agency, non-agency, blanket bridge, fix and flip residential & commercial loans.
Ask me about 96.5% Financing for ITIN and Borrower’s with recent Bankruptcy/Foreclosures or other challenges as we make the dream of homeownership come true for All.
Combine Seller Financing and our Loan to offer 100% No Doc Financing for Investment property.
<br/>
<br/>
Senior Business Development Manager (1953703) for Fidelity Direct Mortgage (188829)
8133 Leesburg Pike, Suite 700, Vienna, VA 22182

	    </b></p>
	</Container>
	    </div>
	    </Container>
	    <br/>
	    <br/>
        </div>
      </div>
    );
  }
}

export default AboutUs;

