import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

function RemaxLandingPage() {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <h1 style={{ textAlign: "center", marginTop: "20px" }}>
              CFRE-AI for REMAX Agents
            </h1>
          </Col>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Col md={{ span: 4, offset: 4 }}>
            <div className="text-center">
              <img
                src="https://dummyimage.com/200x200/000/fff&text=REMAX+Logo"
                alt="REMAX Logo"
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Header>Final Elevator Pitch</Card.Header>
              <Card.Body>
                <Card.Title>Revolutionize Investment Property Analysis</Card.Title>
                <Card.Text>
                  CFRE-AI leverages advanced AI algorithms to provide a comprehensive
                  evaluation of potential ROI and cashflow, leading to better
                  investment decisions, improved client satisfaction, and a
                  competitive edge for REMAX agents.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Header>Running Sales Pitch</Card.Header>
              <Card.Body>
                <Card.Title>Transform the Way You Analyze Investment Properties</Card.Title>
                <Card.Text>
                  CFRE-AI is an innovative tool that can revolutionize the way you
                  analyze investment properties and provide a comprehensive
                  evaluation of their potential ROI. Our AI-powered tool can help you
                  make better investment decisions, mitigate risks, and ultimately
                  deliver superior results for your clients. By leveraging advanced
                  algorithms, CFRE-AI can analyze a wide range of factors such as
                  location, property type, market trends, and financial performance to
                  provide you with a detailed assessment of the potential ROI and
                  cashflow for a given investment property. With CFRE-AI, you can
                  streamline property analysis, make better investment decisions,
                  improve client satisfaction, and gain a competitive edge in the
                  market.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RemaxLandingPage;

