import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Axios from "axios";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.name || !formData.email || !formData.message) {
      return;
    }
    Axios.post("/api/user/submit_contact_form/", formData)
      .then((response) => {
        if (response.data.code === 0) {
          setShowSuccess(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
	<div className="card-wrapper">
          <Card>
            <Card.Body>
              <Card.Title>Contact Us</Card.Title>
              <Card.Text>
                If you have any questions, please fill out the form below and we
                will reach out to you as soon as possible.
              </Card.Text>
              {showSuccess && (
                <Card.Text className="text-success">
                  Your message was sent successfully!
                </Card.Text>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    onChange={handleInputChange}
                    value={formData.name}
                    maxLength={100}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    onChange={handleInputChange}
                    value={formData.email}
                    maxLength={500}
                  />
                </Form.Group>
                <Form.Group controlId="formMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Enter your message"
                    name="message"
                    onChange={handleInputChange}
                    value={formData.message}
                    maxLength={10000}
                  />
                </Form.Group>
                <br />
                <Button variant="primary" type="submit">
                  Submit
                </Button>
	  	<br/>
	  	Or reach out to support@cashflowrealestate.ai
              </Form>
            </Card.Body>
          </Card>
</div>
  );
}

export default ContactForm;

