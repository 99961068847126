import React, { Component } from 'react';
import '../../App.css';
import axios from 'axios';
import { ButtonGroup, Button, Container, Card, Row, Col, Table, Accordion, Modal, Form, Alert, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import User_vars from '../../components/user_vars'
import navbar from '../../components/navbar'
import Amortization from './Amortization'
import info_icon from '../../assets/images/information_24.png'

			//The data relating to real estate for sale on this website appears in part through the BRIGHT Internet Data Exchange program, a voluntary cooperative exchange of property listing data between licensed real estate brokerage firms, and is provided by BRIGHT through a licensing agreement. Listing information is from various brokers who participate in the Bright MLS IDX program and not all listings may be visible on the site. The property information being provided on or through the website is for the personal, non-commercial use of consumers and such information may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Some properties which appear for sale on the website may no longer be available because they are for instance, under contract, sold or are no longer being offered for sale. Property information displayed is deemed reliable but is not guaranteed. Copyright 2023 Bright MLS, Inc.<br/><br/>
class AddressDetails extends Component
{
		state = {
		
		showUserVars: false,
		down_pmt: '20',
                renovation: '0',
                close_cost: '2',
                cost_of_sale: '4.5',
                loan_apr: '5',
                mgmt_fee: '0',
                mnth_vacant: '1',
                rent_annual_inc: '5',
                depreciation: '27.5',
                appreciation: '5',
                income_tax_rate: '30',
                cap_gains_tax_rate: '15',
                term: '30',
                condo_fee: '300',
		renovation_add: '70',
		sortProfitChecked: false,
		records: [],
		active_state: "",
		active_address: "",
		favorite_listing: false,
		prop_id: "",
		show_amortization : false,

		report_listing_modal_open : false,
		report_listing_submission_success : false,
		report_listing_message : "",

		rent_price_override : "",
		sale_price_override : "",
		
		rent_override : false,
		sale_override : false,

		}

set_user_vars = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    this.setState({
      [nam]: val
  })
}

handleReportInputChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    this.setState({
      [nam]: val
  })
}

hide_report_listing_success_message = (event) => {
	this.setState({
		report_listing_submission_success : false,
	})
}


handleInputChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value
    this.setState({
      [nam]: val
  })
}


hideUserVars = () => {
        this.setState({
                showUserVars : false
        })
}
showUserVars = () => {
        this.setState({
                showUserVars : true
        })
}

submit_user_vars = () => {
        /*const param_dict = {
                "down_pmt" : this.state.down_pmt.toString(),
                "renovation" : this.state.renovation.toString(),
                "close_cost" : this.state.close_cost.toString(),
                "cost_of_sale" : this.state.cost_of_sale.toString(),
                "loan_apr" : this.state.loan_apr.toString(),
                "mgmt_fee" : this.state.mgmt_fee.toString(),
                "mnth_vacant" : this.state.mnth_vacant.toString(),
                "rent_annual_inc" : this.state.rent_annual_inc.toString(),
                "depreciation" : this.state.depreciation.toString(),
                "tax_rate" : this.state.tax_rate.toString(),
                "term" : this.state.term.toString(),
        }
  axios.post("/api/save_user_vars/", {
          params : param_dict
  }).then(() => {*/
        this.queryAddress()
  //})
}

addToDash = () => {
        const param_dict = {
        	"prop_id" : this.state.records[0]["prop_id"],
		"code" : this.state.active_state
	}
  axios.post("/api/add_favorite_listing/", {
          params : param_dict
  }).then(
  	this.initialize()
  )
}

removeFromDash = () => {
        const param_dict = {
        	"prop_id" : this.state.records[0]["prop_id"],
		"code" : this.state.active_state
	}
  axios.post("/api/delete_favorite_listing/", {
          params : param_dict
  }).then(
	this.initialize()
  )
}

openReportListingModal = () => {
	  this.setState({
		report_listing_modal_open : true
	  })
}

closeReportListingModal = () => {
	  this.setState({
		report_listing_modal_open : false
	  })
}

reportListing = () => {
	const param_dict = {
		"prop_id" : this.state.records[0]["prop_id"],
		"code" : this.state.active_state,
		"message" : this.state.report_listing_message,
	}
  axios.post("/api/user/report_listing/", {
          params : param_dict
  }).then(
	  this.setState({
		report_listing_submission_success : true,
		report_listing_modal_open : false
	  })
  )
}

setSortProfit = () => {
  this.setState({
	  "sortProfitChecked" : !this.state.sortProfitChecked
  })
}

componentDidUpdate(prevProps, prevState) {
	if (prevState.sortProfitChecked !== this.state.sortProfitChecked) {
		if (this.state.sortProfitChecked) {
		const records = this.state.records
		records.sort(function(a, b) {
  		return b[0] - a[0];
	});
		this.setState({
			"records" : records
		})
	}
	
	else {
		this.queryCategory()
	}
	}
}
	
queryAddress = () => {
	//const queryParams = new URLSearchParams(window.location.search);
	//const address = queryParams.get("address")
	//const city = queryParams.get("city")
	//const code = queryParams.get("code")
	const prop_id = this.props.prop_id
	//const address = this.props.address
	const code = this.props.code

	this.setState({ active_state : code,
			prop_id : prop_id })
	let param_dict = {
		"prop_id" : prop_id.toString(),
		"code" : code.toString(),
		"down_pmt" : this.state.down_pmt.toString(),
		"renovation" : this.state.renovation.toString(),
		"close_cost" : this.state.close_cost.toString(),
		"cost_of_sale" : this.state.cost_of_sale.toString(),
		"loan_apr" : this.state.loan_apr.toString(),
		"mgmt_fee" : this.state.mgmt_fee.toString(),
		"mnth_vacant" : this.state.mnth_vacant.toString(),
		"rent_annual_inc" : this.state.rent_annual_inc.toString(),
		"depreciation" : this.state.depreciation.toString(),
		"appreciation" : this.state.appreciation.toString(),
		"income_tax_rate" : this.state.income_tax_rate.toString(),
		"cap_gains_tax_rate" : this.state.cap_gains_tax_rate.toString(),
		"term" : this.state.term.toString(),
		"condo_fee" : this.state.condo_fee.toString(),
		"renovation_add" : this.state.renovation_add.toString(),
		"rent_price_override" : this.state.rent_price_override.toString(),
		"sale_price_override" : this.state.sale_price_override.toString(),
	}

  axios.get("/api/query_address/", { 
	  params : param_dict
  }
  )
      .then((response) => {
          this.setState({
              records : response.data.record,
	      active_state : response.data.record[0]["code"],
	      favorite_listing : response.data.favorite,
	      sale_override : response.data.sale_override,
	      rent_override : response.data.rent_override,
          })
      })
}

resetValues = () => {

		this.setState({
		down_pmt: '20',
                renovation: '10',
                close_cost: '2',
                cost_of_sale: '4.5',
                loan_apr: '5',
                mgmt_fee: '0',
                mnth_vacant: '1',
                rent_annual_inc: '5',
                depreciation: '27.5',
                appreciation: '5',
                income_tax_rate: '30',
                cap_gains_tax_rate: '15',
                term: '30',
		condo_fee: '300',
		renovation_add: '70',
		})

}

initialize = () => {

  axios.get("/api/get_user_vars/", {
          params : ""
  })
      .then((response) => {
              const vars = response.data["var"]
                this.setState({
                down_pmt: vars["down_pmt"],
                renovation: vars["renovation"],
                close_cost: vars["close_cost"],
                cost_of_sale: vars["cost_of_sale"],
                loan_apr: vars["loan_apr"],
                mgmt_fee: vars["mgmt_fee"],
                mnth_vacant: vars["mnth_vacant"],
                rent_annual_inc: vars["rent_annual_inc"],
                depreciation: vars["depreciation"],
                appreciation: vars["appreciation"],
                income_tax_rate: vars["income_tax_rate"],
                cap_gains_tax_rate: vars["cap_gains_tax_rate"],
                term: vars["term"],
                condo_fee: vars["condo_fee"],
		renovation_add : vars["renovation_add"],
          }, this.queryAddress)
      })
}


componentDidMount() {
	this.initialize()	

}

usdFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
})

load_amortization() {
        this.setState({
                show_amortization : true,
        })
}


return_function() {
        this.setState({
                show_amortization : false,
        })

}

resetSaleOverride = (event) => {
	
	this.setState({
		sale_price_override : ""
	}, this.queryAddress)
}

resetRentOverride = (event) => {
	
	this.setState({
		rent_price_override : ""
	}, this.queryAddress)
}

render() {

	const reportListingSuccessMessage = () => {
                if (this.state.report_listing_submission_success) {
                return (
                        <>
                        <Alert variant="success" onClose={() => this.hide_report_listing_success_message()} dismissible >
                              <a>Thank you for the report. We will investigate this listing.</a>
                        </Alert>
                        <br/>
                        </>
          );
                }
        };

	const halfTable = {
        //"font-size" : "10px",
        //"height" : "100px",
        "width" : "50%",
	"margin-left": "auto",
  	"margin-right": "auto",
        "border-collapse" : "separate",
	"border" : "solid black 2px",
    	"border-radius" : "3px"
	}
	const header = {
  	"font-size": "20px",
        }
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("code")
	const address = queryParams.get("address")
	const Checkbox = ({ label, value, onChange }) => {
  		return (
    		<label>
      		<input type="checkbox" checked={value} onChange={onChange} />
      		&nbsp;{label}
    		</label>
  		);
	};

	const get_rating_detail = (detail) => {

		return(
			<li>{detail}</li>
		)

	}

	const userVarsInject = () => {

			if (!this.state.favorite_listing) {
                        return(
				<>
				<Row>
				<Col>
	  			<Button className='my-2'
					variant="light"
					onClick={this.props.return_function}>
					{"<<"} Return To Search
				</Button>
				</Col>
				<Col>
                                <User_vars      user_vars = {this.state}
                                                set_user_vars = {this.set_user_vars}
                                                submit_user_vars = {this.submit_user_vars}
                                                resetValues = {this.resetValues}
                                                hideUserVars = {this.hideUserVars}
						stateShowUserVars = {this.state.showUserVars}
                                />
                                <Button className='my-2' variant="primary" onClick={this.showUserVars}>Show Inputs</Button>&nbsp;&nbsp;
				</Col>
				<Col>
				<Button className='my-2' variant="primary" onClick={this.addToDash}>Add To Favorites</Button>
				</Col>
				<Col>
				<Button className='my-2' variant="dark" onClick={this.openReportListingModal}>Report Listing</Button>
				</Col>
				</Row>
				</>
			)
			} else {
				return(
					<>
						<Row>
						<Col>
						<Button className='my-2'
							variant="light"
							onClick={this.props.return_function}>
					{"<<"} Return To Search
						</Button>
						</Col>
						<Col>
						<User_vars      user_vars = {this.state}
							set_user_vars = {this.set_user_vars}
							submit_user_vars = {this.submit_user_vars}
							resetValues = {this.resetValues}
							hideUserVars = {this.hideUserVars}
							stateShowUserVars = {this.state.showUserVars}
						/>
						<Button className='my-2' variant="primary" onClick={this.showUserVars}>Show Inputs</Button>&nbsp;&nbsp;
						</Col>
						<Col>
						<Button className='my-2' variant="dark" onClick={this.removeFromDash}>Remove From Favorites</Button>
						</Col>
						<Col>
						<Button className='my-2' variant="dark" onClick={this.openReportListingModal}>Report Listing</Button>
						</Col>
						</Row>
					</>
				)
			}
                }

	const report_listing_inject = () => {
		if (this.state.report_listing_modal_open) {
			return(
				<Modal show={this.state.report_listing_modal_open} onHide={this.closeReportListingModal}>
					<Modal.Header closeButton>
						<Modal.Title>Report Listing</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<Form.Group controlId="report_listing_message">
					  <Form.Label>Message</Form.Label>
					  <Form.Control
					    as="textarea"
					    rows="3"
					    placeholder="Enter your report reason"
					    name="report_listing_message"
					    onChange={this.handleReportInputChange}
					    value={this.state.report_listing_message}
					    maxLength={10000}
					  />
					</Form.Group>
	
					</Modal.Body>
					<Modal.Footer>

						<Button className='my-2' variant="secondary" onClick={this.closeReportListingModal}>Cancel</Button>
						<Button className='my-2' variant="success" onClick={this.reportListing}>Submit Report</Button>
					</Modal.Footer>
				</Modal>
			)
		}
	}


	const list_address_title = this.state.records.map(x => {
		
		let sale_status_date = new Date(parseFloat(x["sale_status_date"]) * 1000)
                //let sale_status_date = new Date(parseFloat(x["sale_status_date"]))
                sale_status_date = (sale_status_date.getMonth() + 1).toString() + "/" + sale_status_date.getDate().toString() + "/" + sale_status_date.getFullYear().toString()
		if (x["sale_status_date"] == "Inactive Account") {
			sale_status_date = "Inactive Account"

		}

		let listing_status = x["status"]
		if (listing_status == "ActiveUnderContract") {
                        listing_status = "Under Contract"
                }
		
		const address = x["address"]
		const subdivision = x["subdivision"]
		const city = x["city"]
		const zipcode = x["zipcode"]
		const county = x["county"]
		const state = x["code"]
		const bedrooms = x["bedrooms"]
		const bathrooms = x["bathrooms"]
		const interior_sqft = x["int_sqft"]
		//const link_addr = "/address-details/?address=" + encodeURIComponent(address) + "&city=" + city + "&code=" + this.state.active_state
		//const link_subdivision = "/subdivisions/subdivisionsearch/?state=" + this.state.active_state + "&subdivision=" + subdivision
		const link_city = "/cities/citysearch/?state=" + this.state.active_state + "&city=" + city
		const link_zipcode = "/zipcodes/zipcodesearch/?state=" + this.state.active_state + "&zipcode=" + zipcode
                const link_county = "/counties/countysearch/?state=" + this.state.active_state + "&county=" + county
                const link_state = "/states/statesearch/?state=" + this.state.active_state
		return(
			<>
			<Table responsive striped bordered hover>
				<thead>
					<tr>
						<th>Address</th>
						<th>Subdivision</th>
						<th>City</th>
						<th>Zipcode</th>
						<th>County</th>
						<th>State</th>
					</tr>
				</thead>
				<tbody>
			<tr>
				<td>{address}</td>
				<td>{subdivision}</td>
				<td><a href={link_city}>{city}</a></td>
				<td><a href={link_zipcode}>{zipcode}</a></td>
				<td><a href={link_county}>{county}</a></td>
				<td><a href={link_state}>{state}</a></td>
			</tr>
				</tbody>
				<thead>
					<tr>
						<th>Status</th>
						<th>Last Modified</th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
			<tr>
				<td>{listing_status}</td>
				<td>{sale_status_date}</td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
				</tbody>
			</Table>
			</>
		)
	})

	const list_geo_details = this.state.records.map(x => {
		const prop_lat = x["PropertyLatitude"]
		const prop_lon = x["PropertyLongitude"]
		const prop_coords_source = x["GeoCoordsSource"]
		const oppzone_lat = x["OppZoneLatitude"]
		const oppzone_lon = x["OppZoneLongitude"]
		let distance_to_oppzone = "N/A"
		console.log(x)
		if (x["DistanceToOppZone"] != "N/A") {
			distance_to_oppzone = x["DistanceToOppZone"] + " Miles"
		}
		
	  	const renderTooltip = (props) => (
		  <Tooltip id="button-tooltip/" {...props}>
			{props}
		</Tooltip>
		);
		return(
			<>
			<Table responsive striped bordered hover>
				<thead>
					<tr>	
						<th>Property Latitude</th>
						<th>Property Longitude</th>
						<th>Property Coordinates Source</th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>{prop_lat}</td>
				<td>{prop_lon}</td>
				<td>{prop_coords_source}</td>
	  		</tr>
			</tbody>
				<thead>
					<tr>	
						<th>Distance To Opportunity Zone <OverlayTrigger
				      placement="right"
				      delay={{ show: 250, hide: 400 }}
				      overlay={renderTooltip("Opportunity Zones are areas that have been designated for economic development.")}>
				      	<img src={info_icon} alt="info"/>
				    </OverlayTrigger></th>
						<th>Opportunity Zone Latitude</th>
						<th>Opportunity Zone Longitude</th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>{distance_to_oppzone}</td>
				<td>{oppzone_lat}</td>
				<td>{oppzone_lon}</td>
	  		</tr>
			</tbody>
			  </Table>
			</>
		)
	})
	const list_broker_details = this.state.records.map(x => {
		const mls_number = x["mls_number"]
		const listing_source = x["listing_source"]
		const list_agent_name = x["list_agent_name"]
		const list_office_name = x["list_office_name"]
		const list_office_phone = x["list_office_phone"]
		
		return(
			<>
			<Table responsive striped bordered hover>
				<thead>
					<tr>	
						<th>MLS Number</th>
						<th>Listing Source</th>
						<th>Listing Agent Name</th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>{mls_number}</td>
				<td>{listing_source}</td>
				<td>{list_agent_name}</td>
	  		</tr>
			</tbody>
				<thead>
					<tr>	
						<th>Listing Broker</th>
						<th>Listing Broker Phone</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>{list_office_name}</td>
				<td>{list_office_phone}</td>
				<td></td>
	  		</tr>
			</tbody>
			  </Table>
			</>
		)
	})
	const list_cf_details = this.state.records.map(x => {
		const rent_override_inject = () => {
			if (this.state.rent_override) {
				return (
					<>
					<br/>
					<a style={{ color : "red" }}><b>Active Override</b></a>
					</>
				)}
		}
		const avg_mnth_rent = this.usdFormat.format(x["avg_mnth_rent"])
		const raw_year_nom_cf = x["year_nom_cf"]
		const year_nom_cf = this.usdFormat.format(x["year_nom_cf"])
		const raw_mnth_nom_cf = x["mnth_nom_cf"]
		const mnth_nom_cf = this.usdFormat.format(x["mnth_nom_cf"])
		const mnth_nom_cf_neg = "(" + this.usdFormat.format(x["mnth_nom_cf"]) + ")"
		let total_outgoing = this.usdFormat.format(x["total_outgoing"])
		const debt_cover_ratio = x["debt_cover_ratio"]
		
		const rent = this.state.rent_override ? this.usdFormat.format(x["rent_price_override"]) : this.usdFormat.format(x["rent"])
		let mnth_pmt = "(" + this.usdFormat.format(x["mnth_pmt_amt"]) + ")"
		let year_pmt = "(" + this.usdFormat.format(x["mnth_pmt_amt"] * 12) + ")"
		let mnth_tax_pmt = "(" + this.usdFormat.format(x["mnth_tax_amt"]) + ")"
		let year_tax_pmt = "(" + this.usdFormat.format(x["mnth_tax_amt"] * 12) + ")"
		let mnth_mgmt_fee = "(" + this.usdFormat.format(x["mgmt_fee_amt"]) + ")"
		let mnth_hoa_fee = "(" + this.usdFormat.format(x["hoa_fee_amt"]) + ")"
		let year_mgmt_fee = "(" + this.usdFormat.format(x["mgmt_fee_amt"] * 12) + ")"
		let year_hoa_fee = "(" + this.usdFormat.format(x["hoa_fee_amt"] * 12) + ")"
		if (x["mnth_pmt_amt"] == "Inactive Account") {
		   total_outgoing = "Inactive Account"
		   mnth_pmt = "Inactive Account"
		   year_pmt = "Inactive Account"
		   mnth_tax_pmt = "Inactive Account"
		   year_tax_pmt = "Inactive Account"
		   mnth_mgmt_fee = "Inactive Account"
		   year_mgmt_fee = "Inactive Account"
		   mnth_hoa_fee = "Inactive Account"
		   year_hoa_fee = "Inactive Account"
		}
	
		const months_paid = 12 - this.state.mnth_vacant
		const year_rent = this.usdFormat.format((12 - this.state.mnth_vacant) * x["rent"] )

		const cf_st_pos_fmt = {
		  "list-style-type": "none",
		  "margin": "0",
		  "padding": "0",
		  "color": "green"
		}
		
		const cf_st_neg_fmt = {
		  "list-style-type": "none",
		  "margin": "0",
		  "padding": "0",
		  "color": "red"
		}
		const cf_st_head_fmt = {
		  "list-style-type": "none",
		  "margin": "0",
		  "padding": "0",
		}
		return(
			<>
			<Table responsive striped bordered hover>
				<thead>
					<tr>	
						<th>Monthly Rent</th>
						<th>Debt Coverage Ratio</th>
						<th>Yearly Nominal Cash Flow</th>
						<th>Monthly Nominal Cash Flow</th>
						<th>Total Outgoing Expenses</th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>{rent} {rent_override_inject()}</td>
				<td>{debt_cover_ratio}</td>
				<td>{year_nom_cf}</td>
				<td>{mnth_nom_cf}</td>
				<td>{total_outgoing}</td>
	  		</tr>
			</tbody>
			  </Table>
			<Accordion defaultActiveKey="0">
		                <Accordion.Item eventKey="0">
                        <Accordion.Header>
                                <b>Monthly Cash Flow Breakdown</b>
                        </Accordion.Header>
                        <Accordion.Body>

			  <Table responsive striped bordered hover>
				<thead>
					<tr>	
						<th>Monthly Cash Flow Breakdown</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
	  		<tr>
			<td>
				<ul>
					<li style={cf_st_head_fmt}>Rent Income (1 Month)</li>
					<li style={cf_st_head_fmt}>Loan Payment</li>
					<li style={cf_st_head_fmt}>Tax Payment</li>
					<li style={cf_st_head_fmt}>Management Fee</li>
					<li style={cf_st_head_fmt}>HOA/Condo Fee (Condo set by user)</li>

				</ul>
			</td>
	  		<td>

				<ul>
					<li style={cf_st_pos_fmt}>{rent}</li>
					<li style={cf_st_neg_fmt}>{mnth_pmt}</li>
					<li style={cf_st_neg_fmt}>{mnth_tax_pmt}</li>
					<li style={cf_st_neg_fmt}>{mnth_mgmt_fee}</li>
					<li style={cf_st_neg_fmt}>{mnth_hoa_fee}</li>

				</ul>
			</td>
			</tr>
			<tr>
				<td>
					<ul>
						<li style={cf_st_head_fmt}>Total</li>
					</ul>
				</td>
				<td>
					<ul>
						<li style={raw_mnth_nom_cf > 0 ? cf_st_pos_fmt : cf_st_neg_fmt}>{mnth_nom_cf}</li>
					</ul>
				</td>
			</tr>
			</tbody>
			  </Table>
			</Accordion.Body>
			</Accordion.Item>
			</Accordion>
			
			<Accordion defaultActiveKey="0">
		                <Accordion.Item eventKey="0">
                        <Accordion.Header>
                                <b>Yearly Cash Flow Breakdown</b>
                        </Accordion.Header>
                        <Accordion.Body>
			  <Table responsive striped bordered hover>
				<thead>
					<tr>	
						<th>Yearly Cash Flow Breakdown</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
	  		<tr>
			<td>
				<ul>
					<li style={cf_st_head_fmt}>Rent Income ({months_paid} Months)</li>
					<li style={cf_st_head_fmt}>Loan Payment</li>
					<li style={cf_st_head_fmt}>Tax Payment</li>
					<li style={cf_st_head_fmt}>Management Fee</li>
					<li style={cf_st_head_fmt}>HOA/Condo Fee (Condo set by user)</li>

				</ul>
			</td>
	  		<td>

				<ul>
					<li style={cf_st_pos_fmt}>{year_rent}</li>
					<li style={cf_st_neg_fmt}>{year_pmt}</li>
					<li style={cf_st_neg_fmt}>{year_tax_pmt}</li>
					<li style={cf_st_neg_fmt}>{year_mgmt_fee}</li>
					<li style={cf_st_neg_fmt}>{year_hoa_fee}</li>

				</ul>
			</td>
			</tr>
			<tr>
				<td>
					<ul>
						<li style={cf_st_head_fmt}>Total</li>
					</ul>
				</td>
				<td>
					<ul>
						<li style={raw_year_nom_cf > 0 ? cf_st_pos_fmt : cf_st_neg_fmt}>{year_nom_cf}</li>
					</ul>
				</td>
			</tr>
			</tbody>
			  </Table>
			</Accordion.Body>
			</Accordion.Item>
			</Accordion>
			</>
		)
	})

	const list_roi_details = this.state.records.map(x => {
		let roi_array = []
		for (let year in x.roi_10) {
			let y = x.roi_10[year]
			console.log(y)
			let calc_year_roi = y["calc_year_roi"]
			let calc_roi = y["calc_roi"]
			let mnth_rent = this.usdFormat.format(y["rent"])
			let year_rent = this.usdFormat.format(y["year_rent"])
			let total_rent = this.usdFormat.format(y["total_rent"])

			let total_capital_gains = this.usdFormat.format(y["total_capital_gains"])
			//const total_capital_gains_tax = this.usdFormat.format(y["total_capital_gains_tax"])
			//const total_income_tax = this.usdFormat.format(y["total_income_tax"])
		
			
			let total_property_tax = this.usdFormat.format(y["total_property_tax"])
			let total_aggr_income = this.usdFormat.format(y["total_aggr_income"])
			let total_cash_expense = this.usdFormat.format(y["total_cash_expense"])
			let total_interest = this.usdFormat.format(y["total_interest"])
			let total_mgmt_fee = this.usdFormat.format(y["total_mgmt_fee"])
			let total_hoa_fee = this.usdFormat.format(y["total_hoa_fee"])
			let total_principle = this.usdFormat.format(y["total_principle"])
			let total_profit = this.usdFormat.format(y["total_profit"])
			let raw_total_profit = parseFloat(y["total_profit"])
			let total_rent_income = this.usdFormat.format(y["total_rent_income"])
			let total_gross_income = this.usdFormat.format(y["total_gross_income"])
			let total_vacancy_loss = this.usdFormat.format(y["total_vacancy_loss"])
			
			let year_property_tax = this.usdFormat.format(y["year_property_tax"])
			let year_aggr_income = this.usdFormat.format(y["year_aggr_income"])
			
			let year_capital_gains = this.usdFormat.format(y["year_capital_gains"])
			//const year_capital_gains_tax = this.usdFormat.format(y["year_capital_gains_tax"])
			//const year_income_tax = this.usdFormat.format(y["year_income_tax"])
			
			let year_cash_expense = this.usdFormat.format(y["year_cash_expense"])
			let year_interest = this.usdFormat.format(y["year_interest"])
			let year_mgmt_fee = this.usdFormat.format(y["year_mgmt_fee"])
			let year_hoa_fee = this.usdFormat.format(y["year_hoa_fee"])
			let year_principle = this.usdFormat.format(y["year_principle"])
			let year_profit = this.usdFormat.format(y["year_profit"])
			let raw_year_profit = parseFloat(y["year_profit"])
			let year_rent_income = this.usdFormat.format(y["year_rent_income"])
			let year_gross_income = this.usdFormat.format(y["year_gross_income"])
			let year_vacancy_loss = this.usdFormat.format(y["year_vacancy_loss"])
			let down_pmt_amt = this.usdFormat.format(y["down_pmt_amt"])
			let equity_down_pmt_amt = this.usdFormat.format(y["equity_down_pmt_amt"])
			let renovation_add_amt = this.usdFormat.format(y["renovation_add_amt"])
			let renovations_amt = this.usdFormat.format(y["renovations_amt"])
			let close_cost_amt = this.usdFormat.format(y["close_cost_amt"])	
			if (y == "Inactive Account") {
				calc_year_roi = "0.00"
				calc_roi = "0.00"
				mnth_rent = this.usdFormat.format("0.00")
				year_rent = this.usdFormat.format("0.00")
				total_rent = this.usdFormat.format("0.00")
				total_capital_gains = this.usdFormat.format("0.00")
				total_property_tax = this.usdFormat.format("0.00")
				total_aggr_income = this.usdFormat.format("0.00")
				total_cash_expense = this.usdFormat.format("0.00")
				total_interest = this.usdFormat.format("0.00")
				total_mgmt_fee = this.usdFormat.format("0.00")
				total_hoa_fee = this.usdFormat.format("0.00")
				total_principle = this.usdFormat.format("0.00")
				total_profit = this.usdFormat.format("0.00")
				raw_total_profit = this.usdFormat.format("0.00")
				total_rent_income = this.usdFormat.format("0.00")
				total_gross_income = this.usdFormat.format("0.00")
				total_vacancy_loss = this.usdFormat.format("0.00")
				year_property_tax = this.usdFormat.format("0.00")
				year_mgmt_fee = this.usdFormat.format("0.00")
				year_hoa_fee = this.usdFormat.format("0.00")
				year_aggr_income = this.usdFormat.format("0.00")
				year_capital_gains = this.usdFormat.format("0.00")
				year_cash_expense = this.usdFormat.format("0.00")
				year_interest = this.usdFormat.format("0.00")
				year_principle = this.usdFormat.format("0.00")
				year_profit = this.usdFormat.format("0.00")
				raw_year_profit = this.usdFormat.format("0.00")
				year_rent_income = this.usdFormat.format("0.00")
				year_gross_income = this.usdFormat.format("0.00")
				year_vacancy_loss = this.usdFormat.format("0.00")
				down_pmt_amt = this.usdFormat.format("0.00")
				equity_down_pmt_amt = this.usdFormat.format("0.00")
				renovation_add_amt = this.usdFormat.format("0.00")
				renovations_amt = this.usdFormat.format("0.00")
				close_cost_amt = this.usdFormat.format("0.00")
			}
			
			const pos_fmt = {
			  "list-style-type": "none",
			  "margin": "0",
			  "padding": "0",
			  "color": "green"
			}
			
			const neg_fmt = {
			  "list-style-type": "none",
			  "margin": "0",
			  "padding": "0",
			  "color": "red"
			}
			const head_fmt = {
			  "list-style-type": "none",
			  "margin": "0",
			  "padding": "0",
			}
			
			const one_time_expense = (value) => {
			 
				const zero_usd = this.usdFormat.format(0)
			  if (year == "1") {
				if (value == "equity_down_pmt_amt_in") {
				  return(
					<li style={pos_fmt}>{equity_down_pmt_amt}</li>
				  )
				} else if (value == "equity_down_pmt_amt_out") {
				  return(
					<li style={neg_fmt}>{equity_down_pmt_amt}</li>
				  )
				} else if (value == "renovations_amt_in") {
					
				  return(
					<li style={pos_fmt}>{renovation_add_amt}</li>
				  )
				} else if (value == "renovations_amt_out") {
					
				  return(
					<li style={neg_fmt}>{renovations_amt}</li>
				  )
				} else if (value == "close_cost_amt") {
					
				  return(
					<li style={neg_fmt}>{close_cost_amt}</li>
				  )
				}
			  } else {
				if (value == "equity_down_pmt_amt_in") {
				  return(
					<li style={pos_fmt}>{zero_usd}</li>
				  )
				} else if (value == "equity_down_pmt_amt_out") {
				  return(
					<li style={neg_fmt}>{zero_usd}</li>
				  )
				} else if (value == "renovations_amt_in") {
					
				  return(
					<li style={pos_fmt}>{zero_usd}</li>
				  )
				} else if (value == "renovations_amt_out") {
					
				  return(
					<li style={neg_fmt}>{zero_usd}</li>
				  )
				} else if (value == "close_cost_amt") {
					
				  return(
					<li style={neg_fmt}>{zero_usd}</li>
				  )
				}
				  
				}


			}

			roi_array.push(
				<>
				<Accordion>
				<Accordion.Item eventKey="0">
				<Accordion.Header>
					<b>Year {year} :</b> &nbsp;{(parseFloat(calc_roi) * 100).toFixed(2)}%
				</Accordion.Header>
				<Accordion.Body>

				  <Table responsive striped bordered hover>
					<thead>
						<tr>	
							<th></th>
							<th>This Year</th>
							<th>Total To Date</th>
						</tr>
					</thead>
					<tbody>
				<tr>
				<td>
				<ul>
					<li style={head_fmt}>Rent Income</li>
					<li style={head_fmt}>Capital Gains</li>
					<li style={head_fmt}>Equity Down Payment</li>
					<li style={head_fmt}>Renovations Add Value</li>
					<li style={head_fmt}><b>Total Income</b></li>
					
						<br/>
					<li style={head_fmt}>Management Fee</li>
					<li style={head_fmt}>HOA/Condo Fee (Condo set by user)</li>
					<li style={head_fmt}>Property Tax</li>
					<li style={head_fmt}>Interest Expense</li>
					<li style={head_fmt}>Vacancy Loss</li>
					<li style={head_fmt}>Equity Down Payment</li>
					<li style={head_fmt}>Renovations Expense</li>
					<li style={head_fmt}>Closing Cost</li>
					<li style={head_fmt}><b>Total Expense</b></li>
					
						<br/>
					<li style={head_fmt}><b>Profit/Loss</b></li>
						<br/>
					<li style={head_fmt}><b>Cash Out at Closing</b></li>
					<li style={head_fmt}><b>(Down Payment + Renovations + Closing Cost)</b></li>
						<br/>
					<li style={head_fmt}><b>Return on Investment</b></li>
					

				</ul>
				</td>
				<td>

					<ul>
						<li style={pos_fmt}>{year_rent_income}</li>
						<li style={pos_fmt}>{year_capital_gains}</li>
						{one_time_expense("equity_down_pmt_amt_in")}
						{one_time_expense("renovations_amt_in")}
						<li style={pos_fmt}><b>{year_aggr_income}</b></li>
						<br/>
						<li style={neg_fmt}>{year_mgmt_fee}</li>
						<li style={neg_fmt}>{year_hoa_fee}</li>
						<li style={neg_fmt}>{year_property_tax}</li>
						<li style={neg_fmt}>{year_interest}</li>
						<li style={neg_fmt}>{year_vacancy_loss}</li>
						{one_time_expense("equity_down_pmt_amt_out")}
						{one_time_expense("renovations_amt_out")}
						{one_time_expense("close_cost_amt")}
						<li style={neg_fmt}><b>{year_cash_expense}</b></li>
						<br/>
						<li style={raw_year_profit >= 0 ? pos_fmt : neg_fmt}><b>{year_profit}</b></li>
						<br/>
						<br/>
						<li style={head_fmt}><b>{down_pmt_amt}</b></li>
						<br/>
						<li style={head_fmt}><b>{(parseFloat(calc_year_roi) * 100).toFixed(2)}%</b></li>

					</ul>
				</td>
				<td>

					<ul>
						<li style={pos_fmt}>{total_rent_income}</li>
						<li style={pos_fmt}>{total_capital_gains}</li>
						<li style={pos_fmt}>{equity_down_pmt_amt}</li>
						<li style={pos_fmt}>{renovation_add_amt}</li>
						<li style={pos_fmt}><b>{total_aggr_income}</b></li>
						<br/>
						<li style={neg_fmt}>{total_mgmt_fee}</li>
						<li style={neg_fmt}>{total_hoa_fee}</li>
						<li style={neg_fmt}>{total_property_tax}</li>
						<li style={neg_fmt}>{total_interest}</li>
						<li style={neg_fmt}>{total_vacancy_loss}</li>
						<li style={neg_fmt}>{equity_down_pmt_amt}</li>
						<li style={neg_fmt}>{renovations_amt}</li>
						<li style={neg_fmt}>{close_cost_amt}</li>
						<li style={neg_fmt}><b>{total_cash_expense}</b></li>
						<br/>
						<li style={raw_total_profit >= 0 ? pos_fmt : neg_fmt}><b>{total_profit}</b></li>
						<br/>
						<br/>
						<li style={head_fmt}><b>{down_pmt_amt}</b></li>
						<br/>
						<li style={head_fmt}><b>{(parseFloat(calc_roi) * 100).toFixed(2)}%</b></li>

					</ul>
				</td>
				</tr>
					</tbody>
				</Table>
				</Accordion.Body>
				</Accordion.Item>
				</Accordion>
				</>
			)
			}
		return roi_array
		})
	
	const list_structure_info = this.state.records.map(x => {
		

		const structure_type = x["structure_type"]
		const age = x["age"]
		const interior_sqft = x["int_sqft"]
		const above_grade_sqft = x["above_grade_sqft"]
		const acres = x["acres"] != "" ? x["acres"] : "N/A"
		
		const bedrooms = x["bedrooms"]
		const bathrooms = x["bathrooms"]
		const baths_full = x["baths_full"] != "" ? x["baths_full"] : "0"
		const baths_half = x["baths_half"] != "" ? x["baths_half"] : "0"
		const basement = x["basement"]
		return(	
			<>
			<Table responsive striped bordered hover>
				<thead>
					<tr>
						<th>Structure Type</th>
						<th>Age</th>
						<th>Interior SqFt</th>
						<th>Above Grade SqFt</th>
						<th>Acres</th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>{structure_type}</td>
				<td>{age}</td>
				<td>{interior_sqft}</td>
				<td>{above_grade_sqft}</td>
				<td>{acres}</td>
	  		</tr>
			</tbody>
				<thead>
					<tr>
						<th>Bedrooms</th>
						<th>Total Bathrooms</th>
						<th>Full Bathrooms</th>
						<th>Half Bathrooms</th>
						<th>Basement</th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>{bedrooms}</td>
				<td>{bathrooms}</td>
				<td>{baths_full}</td>
				<td>{baths_half}</td>
				<td>{basement}</td>
	  		</tr>
			</tbody>
			  </Table>
			</>
		)
	})

	const list_pricing_info = this.state.records.map(x => {
		
		const sale_override_inject = () => {
			if (this.state.sale_override) {
				return (
					<>
					<br/>
					<a style={{ color : "red" }}><b>Active Override</b></a>
					</>
				)}
		}
		const rent_override_inject = () => {
			if (this.state.rent_override) {
				return (
					<>
					<br/>
					<a style={{ color : "red" }}><b>Active Override</b></a>
					</>
				)}
		}

		let sale_list_price = this.state.sale_override ? this.usdFormat.format(x["sale_price_override"]) : this.usdFormat.format(x["sale_list_price"])
		let last_list_price = "Unknown"
		if (x["last_list_price"] != "0.00") {
		    last_list_price = this.usdFormat.format(x["last_list_price"])
		}
		const original_price = this.usdFormat.format(x["original_price"])
		//const sale_status_date = x["sale_status_date"]
		let sale_status_date = new Date(parseFloat(x["sale_status_date"]) * 1000)
		//let sale_status_date = new Date(parseFloat(x["sale_status_date"]))
		sale_status_date = (sale_status_date.getMonth() + 1).toString() + "/" + sale_status_date.getDate().toString() + "/" + sale_status_date.getFullYear().toString()
		const rent = this.state.rent_override ? this.usdFormat.format(x["rent_price_override"]) : this.usdFormat.format(x["rent"])
		const address = x["address"]
		const city = x["city"]
		const code = x["state"]
		const link_amortize = "/amortization/?address=" + encodeURIComponent(address) + "&city=" + city + "&code=" + this.state.active_state
		const bedrooms = x["bedrooms"]
		const bathrooms = x["bathrooms"]
		const interior_sqft = x["int_sqft"]
		const assessment = this.usdFormat.format(x["assessment"])
		const tax_year = x["tax_year"]
		const sale_price_override = this.state.sale_price_override
		return(	
			<>
			{console.log(this.state)}
			<Table responsive striped bordered hover>
				<thead>
					<tr>
						<th>Current Price</th>
						<th>Rent Price</th>
						<th>Original Price</th>
						<th>Tax Assessed Value</th>
						<th>Tax Year</th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>{sale_list_price} {sale_override_inject()}</td>
				<td>{rent} {rent_override_inject()}</td>
				<td>{original_price}</td>
				<td>{assessment}</td>
				<td>{tax_year}</td>
	  		</tr>
			</tbody>
				<thead>
					<tr>
						<th>Sale Price Override</th>
						<th>Rent Price Override</th>
						<th></th>
						<th>Amortization Schedule</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
	  		<tr>
				<td>
				<Form className="d-flex justify-content-center">
				      <div className="w-75 mr-2">
					<Form.Control type="text" name="sale_price_override" value={this.state.sale_price_override} onChange={this.handleInputChange} placeholder="Enter Sale Price Override" />
				      </div>
				    </Form>
					<br/>
					<Button variant="dark" onClick={() => this.resetSaleOverride()}>Reset</Button>&nbsp;&nbsp;
					<Button variant="primary" onClick={() => this.queryAddress()}>Override</Button>
				</td>

				<td>
				<Form className="d-flex justify-content-center">
				      <div className="w-75 mr-2">
					<Form.Control type="text" name="rent_price_override" value={this.state.rent_price_override} onChange={this.handleInputChange} placeholder="Enter Rent Price Override" />
				      </div>
				    </Form>
					<br/>
					<Button variant="dark" onClick={() => this.resetRentOverride()}>Reset</Button>&nbsp;&nbsp;
					<Button variant="primary" onClick={() => this.queryAddress()}>Override</Button>
				</td>
				<td></td>
				<td><br/><br/><Button variant="dark" onClick={() => this.load_amortization()}>Click To Amortize</Button></td>
				<td></td>
	  		</tr>
			</tbody>
			  </Table>
			</>
		)
	})
	
	/*const list_sale_details = this.state.records.map(x => {
		const sale_dict = x["sale_est_info"]	
		const sale = this.usdFormat.format(x["sale_est"])
		const rent_list_date = x["rent_list_date"]
		const confidence = sale_dict["Confidence"]
		let analysis_score = "No Analysis"
		let analysis_rating = "No Analysis"
		let rating_pos_details = ""
		let rating_neg_details = ""
		if (x["sale_analysis_accuracy_rating"] != "No Analysis") {
			analysis_score = x["sale_analysis_accuracy_rating"]["Score"]
			analysis_rating = x["sale_analysis_accuracy_rating"]["Rating"]
			rating_pos_details = x["sale_analysis_accuracy_rating"]["Pos"].map(get_rating_detail)
			rating_neg_details = x["sale_analysis_accuracy_rating"]["Neg"].map(get_rating_detail)
		}
		const int_sqft = x["int_sqft"]
		const bedrooms = x["bedrooms"]
		const props_analyzed = sale_dict["Properties Analyzed"]
		const avg_bed_sale = this.usdFormat.format(sale_dict["AvgPrice"])
		const price_per_bed = this.usdFormat.format(sale_dict["Price_Per_Bed"])
		const analyzed_price_per_bed = this.usdFormat.format(sale_dict["Analyzed PPB"])
		const avg_bed_sqft = Math.round(sale_dict["InteriorSqFt"])
		const avg_price_per_int_sqft = this.usdFormat.format(sale_dict["Price_Per_Int_SqFt"])
		const analyzed_price_per_sqft = this.usdFormat.format(sale_dict["Analyzed PPSF"])
		const comp_price_high = this.usdFormat.format(sale_dict["AvgPrice_high"])
		const comp_price_low = this.usdFormat.format(sale_dict["AvgPrice_low"])
		const comp_price_median = this.usdFormat.format(sale_dict["AvgPrice_median"])
		const sale_price_focus_ratio = x["sale_price_focus_ratio"]
		return(
			<>
	  	<Table striped bordered hover>
          	<thead>
			<tr>	
				<th>Analyzed Sale Price</th>
				<th>Comparable Analysis Level</th>
				<th>Properties Analyzed</th>
				<th>Analysis Rating</th>
				<th>Analysis Score</th>
			</tr>
		</thead>
		<tbody>

	  		<tr>
				<td>{sale}</td>
				<td>{confidence}</td>
				<td>{props_analyzed}</td>
				<td>{analysis_rating}</td>
				<td>{analysis_score}</td>
			</tr>
		</tbody>
		</Table>
		
		<Table striped bordered hover>
		<thead>
			<tr>
				<th>Positive Influencing Factors</th>
	  		</tr>
		</thead>
		<tbody>
			<tr>
				<td>{rating_pos_details}</td>
			</tr>
		</tbody>
		</Table>
		<Table striped bordered hover>
		<thead>
			<tr>
				<th>Negative Influencing Factors</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{rating_neg_details}</td>
	  		</tr>
		</tbody>
		</Table>
		<Table striped bordered hover>
		<thead>
			<tr>	
				<th>Bedrooms</th>
				<th>Interior SqFt</th>
				<th>Analyzed Price Per Bedroom</th>
				<th>Analyzed Price Per Interior SqFt</th>
				<th></th>
			</tr>
	  	</thead>
		<tbody>
			<tr>
				<td>{bedrooms}</td>
				<td>{int_sqft}</td>
				<td>{analyzed_price_per_bed}</td>
				<td>{analyzed_price_per_sqft}</td>
				<td></td>
	  		</tr>
			<tr>	
				<th>Avg Price for {bedrooms} Bedrooms</th>
				<th>Avg Interior SqFt for {bedrooms} Bedrooms</th>
				<th>Avg Price Per Bedroom</th>
				<th>Avg Price Per Interior SqFt</th>
				<th></th>
			</tr>
	  		<tr>
				<td>{avg_bed_sale}</td>
				<td>{avg_bed_sqft}</td>
				<td>{price_per_bed}</td>
				<td>{avg_price_per_int_sqft}</td>
				<td></td>
	  		</tr>
			<tr>	
				<th>Comp Price Low</th>
				<th>Comp Price High</th>
				<th>Comp Price Median</th>
				<th>Comp Price Focus Ratio</th>
			</tr>
	  		<tr>
				<td>{comp_price_low}</td>
				<td>{comp_price_high}</td>
				<td>{comp_price_median}</td>
				<td>{sale_price_focus_ratio}</td>
				<td></td>
	  		</tr>
		</tbody>
	  </Table>
			</>
		)
	})
	*/
	const list_benefit_details = this.state.records.map(x => {
		return(
				<>
				  <Table responsive striped bordered hover>
				<tbody>
				<tr style={{ textAlign: 'left'}}>
				<td>
				<b>Positive Cash Flow</b>
				</td>
				<td>Income that is generated by the rent of the property that exceeds merely covering the expenses. This is similar to the profit of a business.
				</td>
				</tr>
				<tr style={{ textAlign: 'left'}}>
				<td>
				<b>Increasing Rent</b>
				</td>
				<td>Property rents are typically increased over time, so after 10 years your rental income should be significantly higher than at first.
				</td>
				</tr>
				<tr style={{ textAlign: 'left'}}>
				<td>
				<b>Increasing Property Value</b>
				</td>
				<td>Property values typically increase over time, so after 10 years your property should be valued significatly higher than when it was purchased (assuming proper upkeep and maintenance). This means that additional value would be generated simply by holding ownership of property over time independent of payments made towards the mortgage.
				</td>
				</tr>
				<tr style={{ textAlign: 'left'}}>
				<td>
				<b>Depreciation</b>
				</td>
				<td>A property may be depreciated for tax purposes over time which could lower your taxable income, therefore lowering the taxes you have to pay.
				</td>
				</tr>
				<tr style={{ textAlign: 'left'}}>
				<td>
				<b>Principle Payment on Mortgage</b>
				</td>
				<td>The portion of your mortgage payment designated as "principle" goes towards paying down the remaining mortgage balance. Principle payments made increase your equity in the property (assuming the value of the property does not lower) The more payments that are made, the more the property becomes paid off.
				</td>
				</tr>
					</tbody>
				</Table>
				</>
		)
	})
	const list_rent_details = this.state.records.map(x => {
		const rent = this.usdFormat.format(x["rent"])
		const rent_list_date = x["rent_list_date"]
		const rent_dict = x["rent_est_info"]	
		const sale_dict = x["sale_est_info"]	
		let confidence = rent_dict["Confidence"]
		let analysis_score = "No Analysis"
		let analysis_rating = "No Analysis"
		let rating_pos_details = ""
		let rating_neg_details = ""
		if (x["rent_analysis_accuracy_rating"] != "No Analysis") {
			analysis_score = x["rent_analysis_accuracy_rating"]["Score"]
			analysis_rating = x["rent_analysis_accuracy_rating"]["Rating"]
			rating_pos_details = x["rent_analysis_accuracy_rating"]["Pos"].map(get_rating_detail)
			rating_neg_details = x["rent_analysis_accuracy_rating"]["Neg"].map(get_rating_detail)

		}
		
		const int_sqft = x["int_sqft"]
		const bedrooms = x["bedrooms"]
		let props_analyzed = rent_dict["Properties Analyzed"]
		let avg_bed_rent = this.usdFormat.format(rent_dict["AvgPrice"])
		let avg_price_per_bed = this.usdFormat.format(rent_dict["Price_Per_Bed"])
		let analyzed_price_per_bed = this.usdFormat.format(rent_dict["Analyzed PPB"])
		let avg_bed_sqft = Math.round(rent_dict["InteriorSqFt"])
		let avg_price_per_int_sqft = this.usdFormat.format(rent_dict["Price_Per_Int_SqFt"])
		let analyzed_price_per_sqft = this.usdFormat.format(rent_dict["Analyzed PPSF"])
		let comp_price_high = this.usdFormat.format(rent_dict["AvgPrice_high"])
		let comp_price_low = this.usdFormat.format(rent_dict["AvgPrice_low"])
		let comp_price_median = this.usdFormat.format(rent_dict["AvgPrice_median"])
		let rent_price_range_ratio = x["rent_price_range_ratio"]

		if (Object.keys(x["rent_est_info"]).length == 0) {
		
			rating_pos_details = "Inactive Account"
			rating_neg_details = "Inactive Account"
			confidence = "Inactive Account"
			props_analyzed = "Inactive Account"
			avg_price_per_bed = "Inactive Account"
			analyzed_price_per_bed = "Inactive Account"
			avg_bed_sqft = "Inactive Account"
			avg_price_per_int_sqft = "Inactive Account"
			analyzed_price_per_sqft = "Inactive Account"
			comp_price_low = "Inactive Account"
			comp_price_high = "Inactive Account"
			comp_price_median = "Inactive Account"
			rent_price_range_ratio = "Inactive Account"
			avg_bed_rent = "Inactive Account"

		}
		  const renderTooltip = (props) => (
    			<Tooltip id="button-tooltip/" {...props}>
      				{props}
    			</Tooltip>
  			);
		return(
			<>
	  	<Table responsive striped bordered hover>
          	<thead>
			<tr>	
				<th>CFRE-AI Score <OverlayTrigger
				      placement="right"
				      delay={{ show: 250, hide: 400 }}
				      overlay={renderTooltip("CFRE-AI Score provides a numeric confidence rating for the risk of a property, the higher the better. The various components of this rating are provided in this section.")}>
				      	<img src={info_icon} alt="info"/>
				    </OverlayTrigger></th>
				<th>Analysis Rating <OverlayTrigger
				      placement="right"
				      delay={{ show: 250, hide: 400 }}
				      overlay={renderTooltip("Analysis Rating provides a categorical confidence rating for the risk of the property. \"Very High\" is the best, \"Low\" is the worst. The various components of this rating are provided in this section.")}>
				      	<img src={info_icon} alt="info"/>
				    </OverlayTrigger></th>
				<th>Projected Rent</th>
				<th>Comparable Analysis Category <OverlayTrigger
				      placement="right"
				      delay={{ show: 250, hide: 400 }}
				      overlay={renderTooltip("Comparable Analysis Category is the category level that was used to produce the projected rent value. The categories in decending order of accuracy level are Subdivision, Zipcode, City, State.")}>
				      	<img src={info_icon} alt="info"/>
				    </OverlayTrigger></th>
				<th>Properties Analyzed</th>
			</tr>
		</thead>
		<tbody>

	  		<tr>
				<td>{analysis_score} / 10</td>
				<td>{analysis_rating}</td>
				<td>{rent}</td>
				<td>{confidence}</td>
				<td>{props_analyzed}</td>
	  		</tr>
		</tbody>
		</Table>
		
		<Table responsive striped bordered hover>
		<thead>
			<tr>	
				<th>Price Range Ratio <OverlayTrigger
				      placement="right"
				      delay={{ show: 250, hide: 400 }}
				      overlay={renderTooltip("Price Range Ratio is the spread of prices expressed as a ratio resulting from the highest price comparable divided by the lowest price comparable.")}>
				      	<img src={info_icon} alt="info"/>
				    </OverlayTrigger></th>
				<th>Lowest Rent</th>
				<th>Highest Rent</th>
				<th>Average Rent</th>
				<th>Median Rent</th>
			</tr>
		</thead>
		<tbody>
	  		<tr>
				<td>{rent_price_range_ratio}</td>
				<td>{comp_price_low}</td>
				<td>{comp_price_high}</td>
				<td>{avg_bed_rent}</td>
				<td>{comp_price_median}</td>
	  		</tr>
			<tr>	
				<th>Projected Cost Per Bedroom</th>
				<th>Average Cost Per Bedroom</th>
				<th>Projected Cost Per Interior SqFt</th>
				<th>Average Cost Per Interior SqFt</th>
				<th></th>
			</tr>
	  		<tr>
				<td>{analyzed_price_per_bed}</td>
				<td>{avg_price_per_bed}</td>
				<td>{analyzed_price_per_sqft}</td>
				<td>{avg_price_per_int_sqft}</td>
				<td></td>
	  		</tr>
		</tbody>
	  </Table>
		<Table responsive striped bordered hover>
		<thead>
			<tr>
				<th>Positive Influencing Factors</th>
	  		</tr>
		</thead>
		<tbody>
			<tr>
				<td>{rating_pos_details}</td>
			</tr>
		</tbody>
		</Table>
		<Table responsive striped bordered hover>
		<thead>
			<tr>
				<th>Negative Influencing Factors</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{rating_neg_details}</td>
	  		</tr>
		</tbody>
		</Table>
			</>
		)
	})

if (this.state.show_amortization) {

                //const address = this.state.active_address["Address"]
                const code = this.state.active_state
                const prop_id = this.state.prop_id
                return (
                        <>
                        <Amortization 	prop_id={prop_id}
                                        code={code}
                                        return_function={() => this.return_function()}
                                        />
                        </>
                )
        } else {

  const renderTooltip = (props) => (
  	<Tooltip id="button-tooltip/" {...props}>
		{props}
	</Tooltip>
  );
  return (
     	
	<div className='App'>
	  {reportListingSuccessMessage()}
	 <div className="control-wrapper">
	  <Card style={{width : "50%"}}>
	  {userVarsInject()}
	  {report_listing_inject()}
	  </Card>
	  </div>
	  
	  <div className="table-wrapper">
	<Card style={{width : "100%"}}>
	<div>
	  </div>
	  <div>
	<a href={"/counties/?state=" + this.state.active_state}>Search By County</a>&nbsp;&nbsp;
	<a href={"/cities/?state=" + this.state.active_state}>Search By City</a>&nbsp;&nbsp;
	<a href={"/zipcodes/?state=" + this.state.active_state}>Search By Zipcode</a>
	
	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Address Info</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_address_title}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>
	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Structure Details</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_structure_info}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>
	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Pricing Details</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_pricing_info}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>
	
	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Cash Flow</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_cf_details}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>
	
	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Benefits Explained</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_benefit_details}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>
	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Return On Investment (Cash + Equity)</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_roi_details}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>

	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Rent Analysis Details</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_rent_details}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>

	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Geo Info</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_geo_details}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>
	
	<Accordion defaultActiveKey="0">
		<Accordion.Item eventKey="0">	
			<Accordion.Header>
				<b>Source Info</b>
			</Accordion.Header>
			<Accordion.Body>
				{list_broker_details}
			</Accordion.Body>
		</Accordion.Item>
	</Accordion>
			<br/>
</div>
		</Card>
	</div>
    </div>
  );
	}
}
}

export default AddressDetails;
