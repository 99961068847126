import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner() {
const spinnerStyle = {
    display: 'flex',
    justifyContent : 'center',  
    alignItems: 'center',
  };  

return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default LoadingSpinner;
