import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const AssetCredits = () => {
  return (
	  <div className="table-wrapper">
	  <div style={{ textAlign: "left"}}>
          <Card style={{"width" : "100%"}}>
            <Card.Header className="text-center" as="h3">Terms and Conditions</Card.Header>
            <Card.Body>
              <ol>
	  	<li>
	  	<a href="https://www.flaticon.com/free-icons/information" title="information icons">Information icons created by Anggara - Flaticon</a>
	  	</li>
		</ol>
            </Card.Body>
          </Card>
	</div>
	</div>
  );
};

export default AssetCredits;
